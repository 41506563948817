import * as React from "react";
import EmployeesInfo from "../components/dashboard/EmployeesInfo";
import PermitInfo from "../components/dashboard/PermitInfo";
import { EmployeeDashboard } from "../components/dashboard/EmployeesDashboard";
import { Navigate } from "react-router-dom";
import {useAuth} from "../hooks/useAuth";


export const HomePage = () => {
  const { role } = useAuth();
  const employee = role && role === "toolpusher";

  if (role === "manager") {
      return <Navigate to="/dashboard/equipment" replace />;
    } else if (role === "customer") {
      return <Navigate to="/dashboard/tickets" replace />;
    } else if (!role) {
      return <></>
  }

  return (
    <>
    {employee ? <EmployeeDashboard /> :
      <>
        <EmployeesInfo />
        <PermitInfo />
      </>
      }
    </>
  );
};

