import React from "react";
import {
    List,
    ListItem,
    ListItemText,
    Typography,
    Card,
    CardContent,
    Box, CardHeader,
} from "@mui/material";
import { useState, useEffect } from "react";
import { ConstructionOutlined } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import { getExpiringPermitEquipment } from "../../services/equipment-service";
import { differenceInCalendarDays, differenceInDays, formatDistance, formatDistanceToNowStrict } from "date-fns";
import RigInfo from "./RigInfo";
import InvoiceInfo from "./InvoiceInfo";

const EquipmentCard = ({ equipment, color }) => {
    return (
        <Card
            variant="outlined"
            sx={{
                marginBottom: 3,
                border: `2px solid ${color}`,
                height: "auto",
                width: '400px',
            }}
        >
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    {equipment.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ fontSize: "17px" }}>
                    Category: {equipment.category}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ fontSize: "17px" }}>
                    Permit expiration date: {equipment.permitExpirationDate}
                </Typography>
                {equipment.expiresIn > 0 ? <Typography variant="body2" color="textSecondary" sx={{ fontSize: "17px" }}>
                    Permit expires in {equipment.expiresIn} {equipment.expiresIn == 1 ? "day" : "days"}.
                </Typography> 
                : equipment.expiresIn == 0 ? <Typography variant="body2" color="textSecondary" sx={{ fontSize: "17px" }}>
                    Permit expires today.
                </Typography> 
                : null}
            </CardContent>
        </Card>
    );
};

const cardColor = (expiresIn) => {
    switch (true) {
      case expiresIn <= 10:
        return "red";
      case expiresIn > 10 && expiresIn <= 20:
        return "orange";
      case expiresIn > 20:
        return "green";
      default:
        return "green";
    }
  };

const PermitInfo = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const [expiringPermitEquipment, setExpiringPermitEquipment] = useState([]);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        getExpiringEquipment();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const getExpiringEquipment = () => {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        getExpiringPermitEquipment().then((equipment) => {
            equipment.forEach(item => {
                item.permitExpirationDate = new Date(item.permitExpirationDate).toLocaleDateString('en-US', options);
                item.expiresIn = differenceInCalendarDays(new Date(item.permitExpirationDate), new Date())
            })
            equipment.sort((a,b) => {
                return a.expiresIn - b.expiresIn;
            })
            setExpiringPermitEquipment(equipment);
        })
    }

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap"
                }}
            >
                <Card
                    variant="outlined"
                    sx={{
                        height: 'auto',
                        minWidth: '300px',
                        maxHeight: '750px',
                        flex:
                        "1 1 30%",
                        marginBottom: 1,
                        marginRight: 1,
                        padding: 1,
                        cursor: "pointer",
                        "&:hover": {
                            boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.2)",
                        },
                    }}
                >
                    <CardHeader style={{ height: '80px', backgroundColor: '#3a34d2', color: 'white', fontWeight: 'bold' }} avatar={<Avatar sx={{ bgcolor: '#ff5622' }} aria-label="recipe">
                        <ConstructionOutlined /></Avatar>} title="Equipment permit expiring in the Next 30 Days:" />
                    <CardContent style={{
                        height: 'auto',
                        maxHeight: '750px',
                        overflowY: "auto",
                    }}>
                        {expiringPermitEquipment.length > 0 ? (
                            <List>
                                {expiringPermitEquipment.map((equipment) => (
                                    <ListItem key={equipment.id}>
                                        <EquipmentCard equipment={equipment} color={cardColor(equipment.expiresIn)} />
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <Box display="flex" justifyContent="center">
                                <Typography variant="body2" color="textSecondary">
                                    No equipment found with expiring permits in the next 30 days.
                                </Typography>
                            </Box>
                        )}
                    </CardContent>
                </Card>
                <RigInfo screenWidth={screenWidth} />
                <InvoiceInfo screenWidth={screenWidth} />
            </div>
        </>
    );
};

export default PermitInfo;