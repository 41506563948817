import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {postData} from "../../services/http-service";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import {Forms} from "../../shared/forms/forms";
import Container from "@mui/material/Container";
import LockResetIcon from "@mui/icons-material/LockReset";
import * as React from "react";
import {forgotPassword} from "../../services/auth-service";
import {LoadingComponent} from "../../services/loading-service";

export const ForgotPasswordRemote = () => {
    const [emailExists, setEmailExists] = useState('Please provide correct Username');
    const [submitted, setSubmitted] = useState(false);
    const [color, setColor] = useState('black');
    const navigate = useNavigate();
    const [mainLoading, setMainLoading] = useState(false);

    const addParams = {
        buttonName: 'Proceed',
    }

    const fields = [
        {
            label:'Username',
            type:'text',
            name:'email',
            required: true,
            size: 12
        },
    ]

    const handleEmailCheck = async (event) => {
        const email = event.email;
        const redirectUrl = `${location.origin}/reset-password`;
        const resetData = {email, redirectUrl};
        setMainLoading(true);
        forgotPassword(resetData).then(() => {
            setMainLoading(false);
            setSubmitted(true);
        }).catch(() => {
            setMainLoading(false);
            setSubmitted(true);
        });
    };

    const renderForm = submitted ? (
        <Box
            sx={{
                mt: 5,
                border: "1px solid lightblue",
                borderRadius: "20px",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                padding: "30px",
                textAlign: "center"
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: "primary.main"}} style={{margin:"auto"}}>
                <InfoIcon />
            </Avatar>
            <Typography component="h4" variant="h6" margin={3}>
                Check your Email for further instructions.
            </Typography>
            <Button variant="contained" color="primary" onClick={() => setSubmitted(false)}
                    style={{margin:"10px"}}>
                Try Again
            </Button>
            <Button variant="outlined" color="primary" onClick={() => navigate("/login")}
                    style={{margin:"10px"}}>
                Back to Login
            </Button>
        </Box>
    ) : (
        <Box sx={{ mt: 5 }}
             style={{
                 border: "1px solid lightblue",
                 borderRadius: "20px",
                 boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                 padding: "30px"
             }}>
            <FormHelperText
                style={{
                    textAlign: "center",
                    marginBottom: "20px",
                    fontSize: "15px",
                    color: color
                }}>
                {emailExists}
            </FormHelperText>
            <Forms
                fields={fields}
                addParams={addParams}
                onSubmit={handleEmailCheck}/>
            <Link to="/login" style={{textDecoration: "none"}}>
                <Typography style={{textAlign: "center", marginBottom: "20px", marginTop: "15px", fontSize: "14px"}}>
                    Back to login?
                </Typography>
            </Link>
        </Box>
    );
    return (
        <>
            <LoadingComponent loading={mainLoading} />
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ m: 5, bgcolor: "primary.main" }}>
                        <LockResetIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Forgot password? Follow the steps
                    </Typography>
                    {renderForm}
                </Box>
            </Container>
        </>
    );
}
