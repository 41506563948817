import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Forms } from "../shared/forms/forms";
import { postData } from "../services/http-service";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { showToastr } from "../utils/toastr";
import {LoadingComponent} from "../services/loading-service";

export const ResetPasswordPage = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [code, setCode] = useState('');
    const [username, setUsername] = useState('');
    const [mainLoading, setMainLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const currentCode = queryParameters.get("code");
        const currentUsername = queryParameters.get("username");

        if (!currentCode || !currentUsername) {
            navigate('/login', { replace: true });
        } else {
            setCode(decodeURIComponent(currentCode));
            setUsername(currentUsername);
        }

    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'password') {
          setPassword(value);
          setPasswordMismatch(false);
        } else if (name === 'confirmPassword') {
          setConfirmPassword(value);
          setPasswordMismatch(false);
        }
      };

    const addParams = {
        buttonName: 'Change password',
    }

    const fields = [
        {
            label:'New password',
            type:'password',
            name:'password',
            required: true,
            onChange: handleInputChange,
            size:12
        },
        {
            label:'Confirm password',
            type:'password',
            name:'confirmPassword',
            required: true,
            onChange: handleInputChange,
            size:12,
        },
    ]

    useEffect(() => {
        if (password === confirmPassword) {
          setPasswordMismatch(false);
        }
      }, [password, confirmPassword]);

    const handleResetPassword = async (event) => {
        const password = event.password;
        const confirmPassword = event.confirmPassword
        setMainLoading(true);

        if (password === confirmPassword) {
            const data = {username, code, password};
            postData({path: 'authentication/SetPasswordFromLink', data, toasterMessage: "Password updated!", tokenNeeded: false}).then(() => {
                setMainLoading(false);
                navigate('/login', { replace: true });
            }).catch(() => {
                setMainLoading(false);
                showToastr('Something went wrong', 'error');
            })
        }
        else {
            setMainLoading(false);
            setPasswordMismatch(true);
        }
    };

    const renderForm = (
        <Box sx={{ mt: 5 }}
            style={{
                border: "1px solid lightblue",
                borderRadius: "20px",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                padding: "30px 30px 0px 30px",
                height: "330px",
                }}>
            <Typography
                style={{
                    textAlign: "center",
                    marginBottom: "20px"
                    }}>
                Choose new password
            </Typography>
            <Forms
                fields={fields}
                addParams={addParams}
                onSubmit={handleResetPassword}
            />
            {passwordMismatch && (
                <Typography style={{   color: 'red',
                    height: "40px",
                    fontSize: "small",
                    textAlign: 'center',
                    margin: "-60px", }}>
                Passwords do not match
                </Typography>
                )}

        </Box>
    );
    return (
        <>
            <LoadingComponent loading={mainLoading} />
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <Avatar sx={{ m: 2, bgcolor: "primary.main" }}>
                        <LockOpenOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Reset your password
                    </Typography>
                    {renderForm}
                </Box>
            </Container>
        </>
    );
};
