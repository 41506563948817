// tickets-service.js

import { postData } from "./http-service";


export const getAllTickets = async (index,size,sorting,searchString) => {
  try {
    const response = await postData({
      method: "GET",
      path: `tickets/list?index=${index}&size=${size}&sorting=${sorting.id}&order=${sorting.desc ? 'desc' : 'asc'}&searchString=${searchString}`,
      showToaster: false,
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

// get ticket by id
export const getTicketById = async (id) => {
  try {
    const response = await postData({
      method: "GET",
      path: "tickets/",
      options: id,
      showToaster: false,
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

// create ticket
export const createTicket = async (ticket) => {
  try {
    const response = await postData({
      path: "tickets/create",
      data: ticket,
      api: "api",
      toasterMessage: "Ticket Successfully Created!",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

// update ticket details
export const updateTicket = async (ticketData) => {
  try {
    const response = await postData({
      path: "tickets/update-details",
      data: ticketData,
      toasterMessage: "Ticket Successfully Updated!",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

// delete ticket
export const deleteTicket = async (id) => {
  try {
    const response = await postData({
      path: "tickets/delete?ticketId=",
      options: id,
      toasterMessage: "Ticket Successfully Removed!",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};
export const deletePayroll = async (id) => {
  try {
    const response = await postData({
      path: "tickets/payroll/delete?payrollDataId=",
      options: id,
      toasterMessage: "Payroll Successfully Removed!",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

//get tickets by location name
export const getTicketsByLocationName = async (locationName) => {
  try {
    const response = await postData({
      method: "GET",
      path: "tickets/list/location=",
      options: locationName,
      showToaster: false,
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

//update ticket hours
export const updateTicketHours = async (ticketData) => {
  try {
    const response = await postData({
      path: "tickets/update-hours",
      data: ticketData,
      toasterMessage: "Ticket Successfully Updated!",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};
//update ticket specification
export const updateTicketSpecification = async (ticketData) => {
  try {
    const response = await postData({
      path: "tickets/update-specs",
      data: ticketData,
      toasterMessage: "Ticket Successfully Updated!",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};
//get tickets by date
export const getTicketsByDate = async (dateFrom, dateTo) => {
  try {
    const response = await postData({
      method: "GET",
      path: `tickets/list/startDate=${dateFrom}&endDate=${dateTo}`,
      showToaster: false,
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};
//CREATE INVOICE
export const createNewInvoice = async (invoiceData) => {
  try {
    const response = await postData({
      path: `invoices/create`,
      data: invoiceData,
      showToaster: "Invoice Successfully Created!",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

//GET ALL INVOICES
export const getAllInvoices = async (index,size,sorting,searchString) => {
  try {
    const response = await postData({
      method: "GET",
      path: `invoices/list?index=${index}&size=${size}&sorting=${sorting.id}&order=${sorting.desc ? 'asc' : 'desc'}&searchString=${searchString}`,
      showToaster: false,
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};
// get Invoice by id

export const getInvoiceById = async (id) => {
  try {
    const response = await postData({
      method: "GET",
      path: "invoices/",
      options: id,
      showToaster: false,
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

// get Invoice by DueDate
export const getInvoiceByDate = async () => {
  try {
    const response = await postData({
      method: "GET",
      path: "invoices/due-invoice",
      showToaster: false,
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

// update Invoice
export const updateInvoice = async (invoiceData) => {
  try {
    const response = await postData({
      path: "invoices/update",
      data: invoiceData,
      toasterMessage: "Invoice Successfully Updated!",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};
// Mark Invoice as Paid
export const markInvoiceAsPaid = async (invoiceId) => {
  try {
    const response = await postData({
      path: "invoices/mark-paid?invoiceId=",
      options: invoiceId,
      toasterMessage: "Invoice Marked as paid!",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};
// Mark Invoice as Paid
export const removeInvoiceFee = async (feeId) => {
  try {
    const response = await postData({
      path: "invoices/remove-invoice-fee?invoiceFeeId=",
      options: feeId,
      toasterMessage: "Invoice Fee Removed!",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};
// Invoice Send to Customer
export const sendInvoiceToCustomer = async (invoiceId) => {
  try {
    const response = await postData({
      path: "invoices/send?invoiceId=",
      options: invoiceId,
      toasterMessage: "Invoice Successfully Sent to customer!",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

//Download Invoice PDF
export const downloadInvoice = async (invoiceId) => {
  try {
    const response = await postData({
      method: "POST",
      path: `invoices/download?invoiceId=`,
      options: invoiceId,
      showToaster: false,
    });
    if (response.ok) {
      return response;
    }
  } catch (error) {
    return null;
  }
};

//get tickets by Invoice id
export const getTicketsByInvoiceId = async (invoiceId) => {
  try {
    const response = await postData({
      method: "GET",
      path: "invoices/",
      options: invoiceId,
      showToaster: false,
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

export const deleteInvoice = async (id) => {
  try {
    const response = await postData({
      path: "invoices/delete?invoiceId=",
      options: id,
      toasterMessage: "Invoice Successfully Removed!",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

export const getPayrollDetails = async (id) => {
  try {
    const response = await postData({
      method: "GET",
      path: "tickets/payroll/",
      options: id,
      showToaster: false,
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
}

//Add new Payroll
export const createNewPayroll = async (payrollData) => {
  try {
    const response = await postData({
      path: `tickets/payroll/add`,
      data: payrollData,
      showToaster: "Payroll Successfully Created!",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

//Update Payroll
export const updatePayroll = async (payrollData) => {
  try {
    const response = await postData({
      path: `tickets/payroll/update`,
      data: payrollData,
      showToaster: "Payroll Successfully Updated!",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

//Preview Ticket
export const getPreviewTicket = async (ticketId) => {
  try {
    const response = await postData({
      method: "GET",
      path: "tickets/preview?ticketId=",
      options: ticketId,
      showToaster: false,
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

//Sign Ticket Employee
export const ticketSign = async (ticketData) => {
  try {
    const response = await postData({
      path: `tickets/sign`,
      data: ticketData,
      showToaster: "Ticket Successfully Signed!",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

//Upload Ticket File
export const uploadTicketFile = async (ticketData) => {
  try {
    const response = await postData({
      path: `tickets/upload`,
      data: ticketData,
      showToaster: false,
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

//Sign Ticket Customer
export const customerTicketSign = async (ticketData) => {
  try {
    const response = await postData({
      path: `tickets/customer-sign`,
      data: ticketData,
      showToaster: "Ticket Successfully Signed!",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

//Reset ticket sign
export const resetTicketSign = async (ticketId) => {
  try {
    const response = await postData({
      path: `tickets/reset?ticketId=`,
      options: ticketId,
      showToaster: "Ticket Sign Successfully Reset!",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};

//Download Ticket PDF
export const downloadTicket = async (ticketId) => {
  try {
    const response = await postData({
      method: "GET",
      path: `tickets/download?ticketId=`,
      options: ticketId,
      showToaster: false,
    });
    if (response.ok) {
      return response;
    }
  } catch (error) {
    return null;
  }
};

//Download Ticket PDF
export const downloadTickets = async (ticketIds) => {
  try {
    const response = await postData({
      path: `tickets/download-multi`,
      data: ticketIds,
      showToaster: false,
    });
    if (response.ok) {
      return response;
    }
  } catch (error) {
    return null;
  }
};

//Sign Ticket Customer
export const sendToCustomer = async (ticketId) => {
  try {
    const response = await postData({
      path: `tickets/send-to-customer?ticketId=${ticketId}&redirectUrl=${location.origin}`,
      showToaster: "Sent to customer",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
};
