import { useState } from "react";
import { Tabs, Tab, Box } from '@mui/material';
import { styled } from '@mui/system';
import { FieldTicketsComponent } from "../components/tickets/ticketList";
import { InvoiceListComponent } from "../components/tickets/invoiceList";
import {useAuth} from "../hooks/useAuth";


const StyledTab = styled(Tab)(({ theme }) => ({
    fontSize: '16px',
    color: 'black',
    fontWeight: "600"
  }));

export const TicketsPage = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    const { role } = useAuth();

  const isEligible = role && (role === 'admin' || role === 'manager');

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
      };

    return (
        <Box>
        <Tabs key={'key'} value={selectedTab} onChange={handleTabChange}>
          <StyledTab label="Field Tickets" />
            {isEligible && <StyledTab label="Invoices" />}
        </Tabs>
        {selectedTab === 0 && <FieldTicketsComponent userRole={role} />}
        {selectedTab === 1 && isEligible && <InvoiceListComponent />}
      </Box>
    )
}
