import React, { useState, useEffect } from "react";
import { Button, Grid, Box, Tabs, Tab } from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomTable from "../shared/table/Table";
import { getPayroll, getHourlyReport } from "../services/payrolls-service";
import { Navigate } from "react-router-dom";
import { toCurrencyNumber } from "../utils/date-functions";
import { WeekPicker } from "../shared/week-picker/WeekPicker";
import { endOfWeek, startOfWeek } from "date-fns";
import { useAuth } from "../hooks/useAuth";
import { styled } from '@mui/system';
import dayjs from "dayjs";

const StyledTab = styled(Tab)(({ theme }) => ({
    fontSize: '16px',
    color: 'black',
    fontWeight: "600"
}));

export const PayrollsPage = () => {
    const { role } = useAuth();
    const [payrollData, setPayrollData] = useState([]);
    const [hourlyReportData, setHourlyReportData] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);

    const [week, setWeek] = useState({
        firstDay: startOfWeek(new Date(), { weekStartsOn: 0 }),
        lastDay: endOfWeek(new Date(), { weekStartsOn: 0 })
    });

    const [hourlyReportStart, setHourlyReportStart] = useState(new Date());
    const [hourlyReportEnd, setHourlyReportEnd] = useState(new Date());

    if (role && role !== "admin") {
        return <Navigate to="/dashboard/homepage" replace />;
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const payrollsColumns = [
        { accessorKey: "employee", size: 120, header: "Employee" },
        { accessorKey: "jobs", size: 80, header: "Jobs", muiTableHeadCellProps: { align: 'center' }, muiTableBodyCellProps: { align: 'center' } },
        { accessorKey: "totalJobs", size: 100, header: "Jobs $", muiTableHeadCellProps: { align: 'right' }, muiTableBodyCellProps: { align: 'right' }, Cell: props => <React.Fragment>$ {toCurrencyNumber(props.row.original.totalJobs)}</React.Fragment> },
        { accessorKey: "regularHours", size: 80, header: "Hours #", muiTableHeadCellProps: { align: 'center' }, muiTableBodyCellProps: { align: 'center' } },
        { accessorKey: "rigHours", size: 80, header: "Rig Hours #", muiTableHeadCellProps: { align: 'center' }, muiTableBodyCellProps: { align: 'center' } },
        { accessorKey: "travelHours", size: 80, header: "Travel Hours #", muiTableHeadCellProps: { align: 'center' }, muiTableBodyCellProps: { align: 'center' } },
        { accessorKey: "yardHours", size: 80, header: "Yard Hours #", muiTableHeadCellProps: { align: 'center' }, muiTableBodyCellProps: { align: 'center' } },
        { accessorKey: "roustaboutHours", size: 80, header: "Roustabout Hours #", muiTableHeadCellProps: { align: 'center' }, muiTableBodyCellProps: { align: 'center' } },
        { accessorKey: "overtimeHours", size: 80, header: "Overtime #", muiTableHeadCellProps: { align: 'center' }, muiTableBodyCellProps: { align: 'center' } },
        { accessorKey: "totalHours", size: 80, header: "Total #", muiTableHeadCellProps: { align: 'center' }, muiTableBodyCellProps: { align: 'center' } },
        { accessorKey: "mileage", size: 80, header: "Mileage #", muiTableHeadCellProps: { align: 'center' }, muiTableBodyCellProps: { align: 'center' } },
        { accessorKey: "totalMileage", size: 100, header: "Mileage $", muiTableHeadCellProps: { align: 'right' }, muiTableBodyCellProps: { align: 'right' }, Cell: props => <React.Fragment>$ {toCurrencyNumber(props.row.original.totalMileage)}</React.Fragment> },
        { accessorKey: "totalAmount", size: 100, header: "Total $", muiTableHeadCellProps: { align: 'right' }, muiTableBodyCellProps: { align: 'right' }, Cell: props => <React.Fragment>$ {toCurrencyNumber(props.row.original.totalAmount)}</React.Fragment> },
    ];

    const hourlyReportColumns = [
        { accessorKey: "employee", size: 120, header: "Employee" },
        { accessorKey: "jobs", size: 80, header: "Jobs", muiTableHeadCellProps: { align: 'center' }, muiTableBodyCellProps: { align: 'center' } },
        { accessorKey: "rigHours", size: 80, header: "Rig Hours #", muiTableHeadCellProps: { align: 'center' }, muiTableBodyCellProps: { align: 'center' } },
        { accessorKey: "travelHours", size: 80, header: "Travel Hours #", muiTableHeadCellProps: { align: 'center' }, muiTableBodyCellProps: { align: 'center' } },
        { accessorKey: "yardHours", size: 80, header: "Yard Hours #", muiTableHeadCellProps: { align: 'center' }, muiTableBodyCellProps: { align: 'center' } },
        { accessorKey: "roustaboutHours", size: 80, header: "Roustabout Hours #", muiTableHeadCellProps: { align: 'center' }, muiTableBodyCellProps: { align: 'center' } },
        { accessorKey: "totalHours", size: 80, header: "Total #", muiTableHeadCellProps: { align: 'center' }, muiTableBodyCellProps: { align: 'center' } },
        { accessorKey: "mileage", size: 80, header: "Mileage #", muiTableHeadCellProps: { align: 'center' }, muiTableBodyCellProps: { align: 'center' } },
    ];

    const getPayrollData = () => {
        const startDate = week.firstDay.toISOString().split('T')[0];;
        const endDate = week.lastDay.toISOString().split('T')[0];;
        getPayroll(startDate, endDate).then((response) => {
            setPayrollData(response);
        });
    };

    const getReportData = () => {
        const startDate = hourlyReportStart.toISOString().split('T')[0];
        const endDate = hourlyReportEnd.toISOString().split('T')[0];;
        getHourlyReport(startDate, endDate).then((response) => {
            setHourlyReportData(response);
        });
    };

    const onWeekChange = (week) => setWeek(week);

    useEffect(() => {
        getPayrollData();
    }, []);

    return (
        <>
        <Box>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    <StyledTab label = "Payrolls" />
                    <StyledTab label = "Reporting" />
                </Tabs>
                {selectedTab === 0 && (
                    <>
                    <div style={{ width: 450 }} >
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <WeekPicker onChange={onWeekChange} />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center' }}>
                                <Button variant="contained" color="primary" onClick={getPayrollData} style={{ width: 150 }}>
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <CustomTable
                            columns={payrollsColumns}
                            data={payrollData}
                            rowsPerPageOptions={[10]}
                            showDelete={false}
                            showEdit={false}
                            showCreateButton={false}
                            modalOpen={modalOpen}
                            setModalOpen={setModalOpen}
                            enableColumnResizing={false}
                            initialVisibility={{
                                rigHours: false,
                                travelHours: false,
                                yardHours: false,
                                roustaboutHours: false
                            }}
                            hasExport={true}
                            filename="payrolls"
                        />
                    </div>
                    </>
                )}
                {selectedTab === 1 && (
                    <>
                    <div style={{ width: 550, marginTop: 30 }} >
                        <Grid container spacing={2}>
                            <Grid item xs={4} >
                                <DatePicker
                                    fullWidth
                                    label={"Start Date"}
                                    value={hourlyReportStart}
                                    onChange={(newValue) => setHourlyReportStart(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <DatePicker
                                    fullWidth
                                    label={"End Date"}
                                    value={hourlyReportEnd}
                                    onChange={(newValue) => setHourlyReportEnd(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4} style={{ display: 'flex', justifyContent: 'left', justifyItems: 'left' }}>
                                <Button variant="contained" color="primary" 
                                    onClick={getReportData} style={{ width: 150 }}
                                    disabled={!hourlyReportStart || !hourlyReportEnd || hourlyReportStart > hourlyReportEnd}>
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <CustomTable
                            columns={hourlyReportColumns}
                            data={hourlyReportData}
                            rowsPerPageOptions={[10]}
                            showDelete={false}
                            showEdit={false}
                            showCreateButton={false}
                            modalOpen={modalOpen}
                            setModalOpen={setModalOpen}
                            enableColumnResizing={false}

                            hasExport={true}
                            filename="HourlyData"
                        />
                    </div>
                    </>
                )}
            </Box>
        </>
    );
};
