import { postData } from "./http-service";

export const getAllCharges = async () => {
    try {
        const response = await postData({method: "GET", path: "charges/list", showToaster: false});
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}

export const updateCharge = async (chargeData) => {
    try {
        const response = await postData({
            path: 'charges/update',
            data: chargeData,
            toasterMessage: "Charge updated successfully"
        });
        if(response.ok){
            return await response.json();
        }
    }catch(error)
    {
        return null;
    }
}