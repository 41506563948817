import React, { useEffect } from "react";
import {
  List,
  ListItem,
  Typography,
  Card,
  CardContent,
  Box,
  CardHeader,
} from "@mui/material";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import { ConstructionOutlined } from "@mui/icons-material";
import { MapsComponent } from "../maps/maps";
import ModalComponent from "../../shared/Modal";
import { getAllRigJobs } from "../../services/scheduling-service";
import { formatDate } from "../../utils/date-functions";
import { Button } from "@material-ui/core";
import { getCustomers } from "../../services/customer-service";
import { getEquipmentByCategory } from "../../services/equipment-service";
import { CreateTicketModal } from "../tickets/createTicketModal";

const ScheduledJob = ({ item, openCreateModal, customers, equipment }) => {
  const [openMapModal, setOpenMapModal] = useState(false);
  const [mapLocation, setMapLocation] = useState(null);
  const [createTicketModalOpened, setCreateTicketModalOpened] = useState(false);

  const actions = [
    {
      onClick: () => setOpenMapModal(false),
      color: "primary",
      label: "Close",
      variant: "contained",
    },
  ];

  useEffect(() => {
    if (item.lat != 0 || item.lng != 0){
      setMapLocation({ lat: item.lat, lng: item.lng });
    }
  }, []);

  return (
    <Card
      variant="outlined"
      sx={{
        marginBottom: 3,
        border: `2px solid lightgrey`,
        minHeight: "100px",
        width: "100%",
        justifyContent: "center",
        backgroundColor: "#f8f8f8",
        transition: "box-shadow 0.3s",
        "&:hover": {
          boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontSize: "14px", fontWeight: "bold" }}
        >
          Customer name: {item.customerName}
        </Typography>
        <Typography variant="h6" gutterBottom sx={{ fontSize: "14px" }}>
          Equipment name: {item.equipmentName}
        </Typography>
        <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: "bold" }}>
          From: {item.startDate}, To: {item.endDate}
        </Typography>
        <Typography variant="h6" sx={{ fontSize: "14px" }}>
          Description: {item.description}
        </Typography>
        <Typography variant="h6" sx={{ fontSize: "14px" }}>
          Customer contact details: {item.customerContactDetails.length > 1 ? item.customerContactDetails : "N/A"}
        </Typography>
        Location:&nbsp;
        {mapLocation && (
          <Typography
            variant="span"
            sx={{
              fontSize: "14px",
              marginBottom: 1,
              cursor: "pointer",
              color: "blue",
            }}
            onClick={() => setOpenMapModal(true)}
          >
            {item.location}
          </Typography>
          )}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => setCreateTicketModalOpened(true)}
            color="primary"
            variant="contained"
          >
            Create Ticket
          </Button>
        </div>
        <ModalComponent
          open={openMapModal}
          onClose={() => setOpenMapModal(false)}
          title={"Job Location"}
          actions={actions}
          closeOnOutsideClick={false}
        >
          <MapsComponent
              initialLocation={mapLocation}
              showSearch={false}
              enableMapClick={false}
              onMapClick={() => { return; }}
            />
        </ModalComponent>
        <CreateTicketModal 
          isOpen={createTicketModalOpened}
          setIsOpen={setCreateTicketModalOpened}
          customers={customers}
          equipment={equipment}
          initialData={item}
        />
      </CardContent>
    </Card>
  );
};

export const EmployeeDashboard = () => {
  const [scheduledJobs, setScheduledJobs] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [equipments, setEquipments] = useState([]);

  useEffect(() => {
    getCustomers().then((resData) => setCustomers(resData));
    getEquipmentByCategory(1).then((resData) => setEquipments(resData));
  }, []);

  useEffect(() => {
    getAllRigJobs().then((res) => {
      const data = res.sort((a, b) => {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.startTime) - new Date(b.startTime);
      });
      const scheduledJobs = data.map((job) => ({
        equipmentName: job.equipmentName,
        customerId: job.customerId,
        customerContactDetails: job.customerContactName + " " + job.customerContactPhone,
        customerLocationId: job.customerLocationId,
        equipmentId: job.equipmentId,
        customerName: job.customerName,
        description: job.description,
        startDate: formatDate(job.startTime),
        endDate: formatDate(job.endTime),
        location: job.customerLocationName,
        id: job.id,
        lat: job.customerLocation?.lattitude,
        lng: job.customerLocation?.longitude,
      }));
      setScheduledJobs(scheduledJobs);
    });
  }, []);

  return (
    <Card
      variant="outlined"
      sx={{
        height: "auto",
        maxHeight: "calc(100vh - 120px)",
        minWidth: "300px",
        overflowY: "scroll",
        marginBottom: 1,
        marginRight: 1,
        padding: 1,
      }}
    >
      <CardHeader
        style={{
          height: "80px",
          backgroundColor: "#3a34d2",
          color: "white",
          fontWeight: "bold",
        }}
        avatar={
          <Avatar sx={{ bgcolor: "#ff5622" }} aria-label="recipe">
            <ConstructionOutlined />
          </Avatar>
        }
        title="Next Scheduled Jobs:"
      />
      <CardContent
        style={{
          height: "auto",
        }}
      >
        {scheduledJobs.length ? (
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {scheduledJobs.map((job) => (
              <ListItem key={job.id}>
                <ScheduledJob item={job} customers={customers} equipment={equipments} />
              </ListItem>
            ))}
          </List>
        ) : (
          <Box display="flex" justifyContent="center">
            <Typography variant="body2" color="textSecondary">
              No Scheduled jobs found.
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
