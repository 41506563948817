import { Box, Tabs, Tab } from "@mui/material";
import {useEffect, useState} from "react";
import { DefaultChargesPage } from "./default-charges-page";
import { SettingsInfo } from "./settings-info";
import { styled } from '@mui/system';
import { ProfilePage } from "./profile";
import {useAuth} from "../hooks/useAuth";

const StyledTab = styled(Tab)(({ theme }) => ({
    fontSize: '16px',
    color: 'black',
    fontWeight: "600"
}));

export const SettingsPage = () => {

    const { role } = useAuth();
    const [selectedTab, setSelectedTab] = useState(0);

    const isAllowed = role === 'admin' || role === 'manager';

    const onlyProfileView = selectedTab === 0 && !isAllowed;
    const regularProfileView = selectedTab === 2 && isAllowed;

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (<>
            {role && <Box>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    {isAllowed && <StyledTab label = "Settings" />}
                    {isAllowed && <StyledTab label = "Default Charges" />}
                    <StyledTab label = "Profile" />
                </Tabs>
                {selectedTab === 0 && isAllowed && <SettingsInfo />}
                {selectedTab === 1 && isAllowed && <DefaultChargesPage />}
                {(onlyProfileView || regularProfileView) && <ProfilePage />}
            </Box>}
        </>
    )
};
