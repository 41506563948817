// employee-service.js

import { postData } from "./http-service";

// get employees
export const getEmployees = async (active = true) => {
    try {
        const response = await postData({ method: "GET", path: "employees/list/", options: `?status=${active ? 'Active' : 'Inactive'}`, showToaster: false });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getAllEmployees = async () => {
    try {
        const response = await postData({ method: "GET", path: "employees/list/", showToaster: false });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

// get employee by id
export const getEmployeeById = async (id) => {
    try {
        const response = await postData({ method: "GET", path: "employees/", options: id, showToaster: false });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

// add employee login
export const addEmployeeLogin = async (employee) => {
    try {
        const response = await postData({ path: "employees/add-login", data: employee, toasterMessage: 'Employee login added successfully!' });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

// remove employee login
export const removeEmployeeLogin = async (id) => {
    try {
        const response = await postData({ path: "employees/delete-login", options: `?employeeId=${id}`, toasterMessage: 'Employee login removed successfully!' });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

// create new employee
export const createEmployee = async (employee) => {
    try {
        const response = await postData({ path: 'employees/create', data: employee, toasterMessage: 'Employee added successfully!' });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

// update employee
export const updateEmployee = async (employee) => {
    try {
        const response = await postData({ path: "employees/update", data: employee, toasterMessage: 'Employee updated successfully!' });
        if (response.ok) {
            return response;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
};

// delete employee
export const deleteEmployee = async (id) => {
    try {
        const response = await postData({ path: 'employees/delete', data: {}, options: `?employeeId=${id}`, toasterMessage: 'Employee deleted successfully' });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

// reset employee password
export const resetEmployeePassword = async (employee) => {
    try {
        const response = await postData({ path: "employees/reset-password", data: employee, toasterMessage: "Employee password updated successfully" });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getUpcomingBirthdayEmployees = async () => {
    try {
        const response = await postData({ method: "GET", path: "employees/birthdays", showToaster: false });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getUpcomingAnniversariesEmployees = async () => {
    try {
        const response = await postData({ method: "GET", path: "employees/work-anniversairies", showToaster: false });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}