// equipment-service.js

import { postData } from "./http-service";

//get all equipments
export const getEquipment = async () => {
    try {
        const response = await postData({ method: "GET", path: "equipment/list", showToaster: false });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}


// get equipments by category
export const getEquipmentByCategory = async (equipmentCategory) => {
    try {
        const response = await postData({ method: "GET", path: "equipment/list/", options: equipmentCategory, showToaster: false });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}

// get currently assigned equipment
export const getAssignedEquipment= async () => {
    try {
        const response = await postData({ method: "GET", path: "equipment/list-assigned", showToaster: false });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}

// get category by id
export const getEquipmentById = async (id) => {
    try {
        const response = await postData({ method: "GET", path: "equipment/", options: id, showToaster: false });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}

// create equipment
export const createEquipment = async (equipment) => {
    try {
        const response = await postData({
            path: 'equipment/create',
            data: equipment,
            api: 'api',
            toasterMessage: "Equipment Successfully Created!",
        });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}

// update equipment
export const updateEquipment = async (equipmentData) => {
    try {
        const response = await postData({
            path: 'equipment/update',
            data: equipmentData,
            toasterMessage: "Equipment Successfully Updated!",
        });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
};

// delete equipment
export const deleteEquipment = async (id) => {
    try {
        const response = await postData({ path: "equipment/delete?equipmentId=", options: id, toasterMessage: "Equipment Successfully Removed!" });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}

// create charge
export const createEquipmentCharge = async (chargeData) => {
    try {
        const response = await postData({
            path: 'equipment/create-equipment-charge',
            data: chargeData,
            toasterMessage: "Charge Successfully Created!",
        });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
};

// get all equipment charges for related equipmnet
export const getAllEquipmentChargesById = async (id) => {
    try {
        const response = await postData({ method: "GET", path: `equipment/${id}/charges/list`, showToaster: false });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}

// update Charge
export const updateChargeByEquipmentId = async (equipmentId, chargeData) => {
    try {
        const response = await postData({
            path: `equipment/${equipmentId}/charges/update`,
            data: chargeData,
            toasterMessage: "Rig Rate Successfully Updated!",
        });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
};

export const getExpiringPermitEquipment = async () => {
    try {
        const response = await postData({ method: "GET", path: "equipment/permits-expiration", showToaster: false });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}

export const getRigsNotCurrentlyWorking = async () => {
    const today = new Date().toDateString();
    try {
        const response = await postData({ method: "GET", path: `equipment/rigs-not-working?today=${today}`, showToaster: false });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}

export const getEquipmentFiles = async (id) => {
    try {
        const response = await postData({ method: "GET", path: "equipment/files?equipmentId=", options: id, showToaster: false });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}

export const downloadEquipmentFile = async (id) => {
    try {
        const response = await postData({
            method: "GET",
            path: `equipment/files/download?fileId=`,
            options: id,
            showToaster: false,
        });
        if (response.ok) {
            return response;
        }
    } catch (error) {
        return null;
    }
};

export const deleteEquipmentFile = async (id) => {
    try {
        const response = await postData({ path: "equipment/files/delete?fileId=", options: id, toasterMessage: "Document Successfully Removed!" });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}
