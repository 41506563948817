import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import PasswordIcon from '@mui/icons-material/Password';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Button } from "@material-ui/core";
import {Box, Typography} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {CSVLink, CSVDownload} from 'react-csv';

const CustomTable = ({ 
    tableName,
    columns,
    data,
    enableColumnResizing = false,
    rowsPerPageOptions,
    onDocuments,
    showDocuments = false,
    onCharges,
    showCharges = false,
    showEdit = true,
    showDelete = true,
    showResetPassword = false,
    onAdd,
    additionalBtn,
    onEdit,
    onDelete,
    onHandlePassword,
    showCreateButton = true,
    showAdditionalButton = false,
    additionalBtnDisabled,
    buttonText = "CREATE",
    initialVisibility = null,
    visibility = null,
    showView,
    onView,
    hasExport = false,
    filename = 'export_csv',
    manualPagination = false,
    manualSorting = false,
    manualFiltering = false,
    onGlobalFilterChange,
    onPaginationChange,
    onSortingChange,
    state,
    rowCount,
    enableColumnFilters = true,
    rowActionRendererer = null }) => {
    const handleAdd = (onAdd) => {
        if (onAdd) {
            onAdd();
        }
    };

    const handleEdit = (row) => {
        if (onEdit) {
            onEdit({ data: row.original });
        }
    };

    const handleView = (row) => {
        if (onView) {
            onView({ data: row.original });
        }
    }

    const handleDelete = (row) => {
        if (onDelete) {
            onDelete({ data: row.original });
        }
    };

    const handleAdditionalBtn = (additionalBtn) => {
        if (additionalBtn) {
            additionalBtn();
        }
    }

    const handleCharges = (row) => {
        if (onCharges) {
            onCharges({ data: row.original });
        }
    };

    const handleDocuments = (row) => {
        if (onDocuments) {
            onDocuments( {data: row.original });
        }
    }

    const handleResetPassword = (row) => {
        if (onHandlePassword) {
            onHandlePassword({ data: row.original });
        }
    }

    const standardActionRenderer = (row, index, cell) => {
        return (
            <>
            {showEdit ? <Tooltip title="Edit" arrow>
                <EditIcon fontSize="small" onClick={() => handleEdit(row, index)} style={{ cursor: 'pointer' }} />
            </Tooltip> : null}
            {showDelete ? <Tooltip title="Delete" arrow>
                <DeleteIcon fontSize="small" onClick={() => handleDelete(row, index)} style={{ marginLeft: '10px', cursor: 'pointer' }} />
            </Tooltip> : null}
            {showCharges ? <Tooltip title="Change price" arrow>
                <PriceChangeIcon fontSize="small" onClick={() => handleCharges(row, index)} style={{ marginLeft: '10px', cursor: 'pointer' }} />
            </Tooltip> : null}
            {showResetPassword ? <Tooltip title="Change password" arrow>
                <PasswordIcon fontSize="small" onClick={() => handleResetPassword(row, index)} style={{ marginLeft: '10px', cursor: 'pointer' }} />
            </Tooltip> : null}
            {showView ? <Tooltip title="View invoice" arrow>
                <VisibilityIcon fontSize="small" onClick={() => handleView(row, index)} style={{ marginLeft: '10px', cursor: 'pointer' }} />
            </Tooltip> : null}
            {showDocuments ? <Tooltip title="Manage documents" arrow>
                <NoteAddIcon fontSize="small" onClick={() => handleDocuments(row, index)} style={{ marginLeft: '10px', cursor: 'pointer' }} />
            </Tooltip> : null}  
            </>
        );
    };

    const materialSet = {
        enableColumnResizing: enableColumnResizing,
        columns,
        data,
        displayColumnDefOptions: {
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                align: "center",
              },
              size: 120
            },
          },
        initialState: initialVisibility ? { columnVisibility: initialVisibility } : null,
        state: state ? state : (visibility ? { columnVisibility: visibility } : null),
        enableRowActions: showEdit || showDelete || showCharges || showResetPassword || showView || showDocuments,
        positionActionsColumn: 'last',
        rowCount: rowCount,
        manualFiltering: manualFiltering,
        manualPagination: manualPagination,
        manualSorting: manualSorting,
        enableColumnFilters: enableColumnFilters,
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
            >
                {hasExport && <CSVLink filename={filename} data={data} >Export data</CSVLink>}
            </Box>
        ),
        renderRowActions: ({ row, cell }, index) => [
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} key={index + '-actions'}>
                {
                    rowActionRendererer ? rowActionRendererer(row, index, cell) : standardActionRenderer(row, index, cell)
                }
            </div>
        ]
      };
    
    if (manualPagination){
        materialSet.onPaginationChange = onPaginationChange;
    }

    if (manualSorting){
        materialSet.onSortingChange = onSortingChange;
    }

    if (manualFiltering){
        materialSet.onGlobalFilterChange = onGlobalFilterChange;
    }

    const tableSet = useMaterialReactTable(materialSet); 
      
    return (
        <>
            <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', marginTop: '60px', marginBottom: '20px' }}>
                <Typography variant={'h5'}>{tableName}</Typography>
                <div>
                    {showAdditionalButton && <Button
                        variant="contained"
                        size="large"
                        style={{ fontWeight: 'bold', marginRight: "20px" }}
                        onClick={() => handleAdditionalBtn(additionalBtn)}
                        disabled={additionalBtnDisabled}
                    >
                        {buttonText}
                    </Button>}
                    {showCreateButton && <Button
                        variant="contained"
                        size="large"
                        style={{ fontWeight: 'bold' }}
                        startIcon={<AddIcon fontSize="small" style={{ fontWeight: 'bold' }} />}
                        onClick={() => handleAdd(onAdd)}
                    >
                        ADD
                    </Button>}
                </div>
            </div>
            <MaterialReactTable
                table={tableSet}
            />
        </>
    );
};

export default CustomTable;
