import React from "react";
import {
    Card,
    CardContent,
    Typography,
    List,
    ListItem,
    ListItemText,
    Box, CardHeader,
} from "@mui/material";
import { ReceiptOutlined } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import { useState, useEffect } from "react";
import { getInvoiceByDate } from "../../services/tickets-service";
import { differenceInCalendarDays } from "date-fns";

const InvoiceCard = ({ invoice, color }) => {
    return (
        <Card
            variant="outlined"
            sx={{
                marginBottom: 3,
                border: `2px solid ${color}`,
                height: "auto",
                width: "400px",
            }}
        >
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Invoice {invoice.invoiceId}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ fontSize: "17px" }}>
                    Customer: {invoice.customer}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ fontSize: "17px" }}>
                    Due date: {invoice.dueDate}
                </Typography>
                {invoice.daysUntilDueDate > 0 ? <Typography variant="body2" color="textSecondary" sx={{ fontSize: "17px" }}>
                    Due in {invoice.daysUntilDueDate} {invoice.daysUntilDueDate == 1 ? "day" : "days"}.
                </Typography> 
                : invoice.daysUntilDueDate < 0 ? <Typography variant="body2" color="textSecondary" sx={{ fontSize: "17px" }}>
                    Overdue {invoice.daysUntilDueDate * -1} {invoice.daysUntilDueDate * -1 == 1 ? "day" : "days"}.
                </Typography> 
                : invoice.daysUntilDueDate == 0 ? <Typography variant="body2" color="textSecondary" sx={{ fontSize: "17px" }}>
                    Due today.
                </Typography> 
                : null}
            </CardContent>
        </Card>
    );
};

const cardColor = (daysUntilDueDate) => {
    switch (true) {
        case daysUntilDueDate < 10:
            return "red";
        case daysUntilDueDate > 10 && daysUntilDueDate <= 20:
            return "orange";
        case daysUntilDueDate > 20:
            return "green";
        default:
            return "green";
    }
};

const InvoiceInfo = ({ screenWidth }) => {
    const [invoiceList, setInvoiceList] = useState([]);

    useEffect(() => {
        getInvoices();
    }, []);

    const getInvoices = () => {
        getInvoiceByDate().then((invoices) => {
            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            invoices.forEach(invoice => {
                invoice.dueDate = new Date(invoice.dueDate).toLocaleDateString('en-US', options);
                invoice.daysUntilDueDate = differenceInCalendarDays(new Date(invoice.dueDate), new Date());
            })

            setInvoiceList(invoices);
        })
    }

    return (
        <Card
            variant="outlined"
            sx={{
                height: "auto",
                maxHeight: '750px',
                minWidth: '300px',
                flex: "1 1 30%",
                marginBottom: 1,
                marginRight: 1,
                padding: 1,
                cursor: "pointer",
                "&:hover": {
                    boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.2)",
                },
            }}
        >
            <CardHeader style={{ height: '80px', backgroundColor: '#3a34d2', color: 'white', fontWeight: 'bold' }} avatar={<Avatar sx={{ bgcolor: '#ff5622' }} aria-label="recipe">
                <ReceiptOutlined /></Avatar>} title="Invoices:" />
            <CardContent style={{
                height: 'auto',
                maxHeight: '750px',
                overflowY: "auto",
            }}>
                {invoiceList.length > 0 ? (
                    <List
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {invoiceList.map((invoice) => (
                            <ListItem key={invoice.id}>
                                <InvoiceCard invoice={invoice} color={cardColor(invoice.daysUntilDueDate)} />
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Box display="flex" justifyContent="center">
                        <Typography variant="body2" color="textSecondary">
                            No invoices found.
                        </Typography>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default InvoiceInfo;