import React from "react";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box, CardHeader,
} from "@mui/material";
import { LocalParkingOutlined } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import { useState, useEffect } from "react";
import { getRigsNotCurrentlyWorking } from "../../services/equipment-service";



const RigCard = ({ item, color }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        marginBottom: 3,
        border: `2px solid ${color}`,
        height: "auto",
        width: "400px",
        justifyContent: 'center'
      }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {item.rig.name} {item.daysSinceLastJob === -1 ? "parked"
        : (item.daysSinceLastJob === 1 && item.daysUntilNextJob ===0) ? "was parked yesterday"
        : (item.daysSinceLastJob > 1 && item.daysUntilNextJob ===0) ? `was parked for ${item.daysSinceLastJob} days`
        : (item.daysSinceLastJob === 0 || item.daysUntilNextJob ===0) ? ""
        : item.daysSinceLastJob === 1 ? "parked today"
        : `parked for ${item.daysSinceLastJob} days`}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ fontSize: "17px" }}>
        {(item.daysUntilNextJob === 0 || item.daysSinceLastJob === 0) ? "Out today"
        : item.daysUntilNextJob === -1 ? "No scheduled jobs"
        : item.daysUntilNextJob === 1 ? "Next job tomorrow"
        : `Next job in ${item.daysUntilNextJob} days.`}
        </Typography>
      </CardContent>
    </Card>
  );
};

const cardColor = (parkedFor) => {
  switch (true) {
    case parkedFor === 0:
      return "green";
    case parkedFor > 0 && parkedFor < 10:
      return "blue";
    case parkedFor > 10 && parkedFor <= 20:
      return "orange";
    case parkedFor > 20:
      return "red";
    default:
      return "green";
  }
};

const RigInfo = ({ screenWidth }) => {
  const [rigList, setRigList] = useState([]);

  useEffect(() => {
    getRigs();
  }, []);

  const getRigs = () => {
    getRigsNotCurrentlyWorking().then((rigs) => {
      setRigList(rigs);
    })
  }

  return (
    <Card
      variant="outlined"
      sx={{
        height: "auto",
        maxHeight: '750px',
        minWidth: '300px',
        flex: "1 1 30%",
        marginBottom: 1,
        marginRight: 1,
        padding: 1,
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <CardHeader style={{ height: '80px', backgroundColor: '#3a34d2', color: 'white', fontWeight: 'bold' }} avatar={<Avatar sx={{ bgcolor: '#ff5622' }} aria-label="recipe">
        <LocalParkingOutlined /></Avatar>} title="Rig Information:" />
      <CardContent style={{
        height: 'auto',
        maxHeight: '750px',
        overflowY: "auto",
      }}>
        {rigList.length > 0 ? (
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {rigList.map((rig) => (
              <ListItem key={rig.id}>
                <RigCard item={rig} color={cardColor(rig.daysUntilNextJob + rig.daysSinceLastJob)} />
              </ListItem>
            ))}
          </List>
        ) : (
          <Box display="flex" justifyContent="center">
            <Typography variant="body2" color="textSecondary">
              No parked rigs found.
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default RigInfo;