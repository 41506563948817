import {postData} from "./http-service";

// Get all jobs
export const getJobs = async () => {
  const response = await postData({
    method: "GET",
    path: "scheduling/list",
    data: {},
    showToaster: false,
  });
  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch data");
  }
};

// Get all jobs
export const getAllRigJobs = async () => {
  const response = await postData({
    method: "GET",
    path: "scheduling/rigList",
    data: {},
    showToaster: false,
  });
  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch data");
  }
};

// Create a job
export const createJob = async (jobData) => {
  const response = await postData({
    path: "scheduling/create",
    data: jobData,
    toasterMessage: "Job successfully created.",
  });

  return response.ok;
};

//Update Job
export const updateJob = async (jobData) => {
  const response = await postData({
    path: "scheduling/update",
    data: jobData,
    toasterMessage: "Job updated.",
  });
  if (response.ok) {
    return response;
  }
};

// Delete a job
export const deleteJob = async (jobId) => {
  const response = await postData({
    path: "scheduling/delete?schedulingId=",
    options: jobId,
    toasterMessage: "Job successfully removed.",
  });
  return response.ok;
};

//Get job By Id
export const getJobById = async (id) => {
  try {
    const response = await postData({
      method: "GET",
      path: "scheduling/",
      data: id,
      options: id,
      showToaster: false,
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};
