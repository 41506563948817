import React from "react";
import CustomTable from "../../shared/table/Table";
import ModalComponent from "../../shared/Modal";
import { Forms } from "../../shared/forms/forms";
import { useRef, useEffect, useState } from "react";
import {
  createEquipment,
  deleteEquipment,
  getEquipmentByCategory,
  getEquipmentById,
  updateEquipment,
  getAllEquipmentChargesById,
  updateChargeByEquipmentId,
  getEquipmentFiles,
  deleteEquipmentFile,
  downloadEquipmentFile,
} from "../../services/equipment-service";
import Box from "@mui/material/Box";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Typography } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Input,
  InputLabel,
  List,
  ListItem,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Avatar
} from "@mui/material";
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import { formatDate } from "../../utils/date-functions.js";
import { Button } from "@material-ui/core";
import { uploadFile } from "../../services/http-service";
import {useAuth} from "../../hooks/useAuth";
import { downloadDoc } from "../../utils/download-file";

const columns = [
  { accessorKey: "name", header: "Name" },

  { accessorKey: "description", header: "Description" },
  { accessorKey: "lastMaintenance", header: "Last Maintenance" },
  { accessorKey: "permitExpirationDate", header: "Permit Exp Date" },
];

const chargesColumn = [
  { accessorKey: "name", header: "Name" },
  { accessorKey: "rate", header: "Rate" },
  { accessorKey: "uoM", header: "uoM" },
];

const categoryId = {
  Rig: "1",
  Vehicles: "2",
  Trailers: "3",
  Pumps: "4",
  Tanks: "5",
  Ancillary: "6",
};

const categoryName = {
  1: "Rig",
  2: "Vehicles",
  3: "Trailers",
  4: "Pumps",
  5: "Tanks",
  6: "Ancillary",
};

const EquipmentOperator = ({ selectedTab }) => {
  const { role } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentEquipmentIndex, setCurrentEquipmentIndex] = useState(undefined);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [fields, setFields] = useState(defaultEquipment);
  const [data, setData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [chargesModal, setChargesModal] = useState(false);
  const [rigName, setRigName] = useState(false);

  const [chargeFields, setChargeFields] = useState([]);
  const [chargesData, setChargesData] = useState([]);
  const [rigData, setRigData] = useState([]);
  const categoryNum = (selectedTab + 1).toString();

  const [manageDocumentsModal, setManageDocumentsModal] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [equipmentId, setEquipmentId] = useState('');

  const [uploadDocumentModal, setUploadDocumentModal] = useState(false);
  const [documentName, setDocumentName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [pdfBlob, setPdfBlob] = useState(null);

  const isAdmin = role && role === "admin";
  const isManager = role && role === "manager";
  const isToolPusher = role && role === "toolpusher";

  const ref = useRef();

  const defaultEquipment = [
    {
      label: "Equipment Name",
      type: "text",
      name: "name",
      required: true,
      value: "",
      size: 6,
    },
    {
      label: "Description",
      type: "text",
      name: "description",
      required: false,
      value: "",
      size: 6,
    },
    {
      label: "Permit Expiration Date",
      type: "datetime",
      name: "permitExpirationDate",
      required: false,
      value: "",
      size: 6,
    },
    {
      label: "Last Maintenance",
      type: "datetime",
      name: "lastMaintenance",
      required: false,
      value: "",
      size: 6,
    },
    {
      label: "Permit Number",
      type: "text",
      name: "permitNumber",
      required: false,
      value: "",
      size: 6,
    },
    {
      label: "Vin Number",
      type: "text",
      name: "vinNumber",
      required: false,
      value: "",
      size: 6,
    },
    {
      label: "Year",
      type: "number",
      name: "year",
      required: false,
      value: "",
      size: categoryNum === "1" ? 6 : 12,
    },
  ];

  categoryNum === "1" &&
    defaultEquipment.push({
      label: "Fuel Consumption",
      type: "number",
      name: "fuelConsumption",
      required: true,
      value: "",
      size: 6,
    });

  const handleRateChange = (event, id) => {
    const updatedData = chargesData.map((item) =>
      item.id === id ? { ...item, rate: +event.target.value } : item
    );
    setChargesData(updatedData);
  };
  const getEquipmentCategory = (equipmentCategory) => {
    getEquipmentByCategory(equipmentCategory).then((response) =>
      setData(response)
    );
  };

  const removeEquipment = (equipmentId) => {
    deleteEquipment(equipmentId).then(() => getEquipmentCategory(categoryNum));
  };

  const getChargesById = (equipmentId) => {
    getAllEquipmentChargesById(equipmentId).then((resData) =>
      setChargesData(resData)
    );
  };

  useEffect(() => {
    getEquipmentCategory(categoryNum);
  }, [selectedTab]);

  const handleOpenModal = (edit) => {
    setIsEditMode(edit);
    if (!edit) {
      setFields(defaultEquipment);
    }
    setModalOpen(true);
  };

  const handleAddAction = () => {
    setIsEditMode(false);
    handleOpenModal(false);
    setModalOpen(true);
    setFields(defaultEquipment);
  };

  const handleEditAction = ({ data }) => {
    getEquipmentById(data.id)
      .then((equipment) => {
        setIsEditMode(true);
        setEquipment(equipment);
        setModalOpen(true);
        setCurrentEquipmentIndex(equipment.id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCloseChargesModal = () => {
    setChargesModal(false);
  };

  const handleDeleteAction = ({ data }) => {
    const id = data.id;
    setCurrentEquipmentIndex(id);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirmAction = () => {
    removeEquipment(currentEquipmentIndex);
    setDeleteModalOpen(false);
  };

  const handleConfirmAction = () => {
    ref.current.submit();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleDeleteCloseModal = () => {
    setDeleteModalOpen(false);
  };

  const handleAddCharges = ({ data }) => {
    setCurrentEquipmentIndex(data.id);
    setRigName(data.name);
    getChargesById(data.id);
    setChargesModal(true);
  };

  const handleDocuments = ({ data }) => {
    setEquipmentId(data.id);
    getDocumentList(data.id);
    setManageDocumentsModal(true);
  }

  const getDocumentList = (id) => {
    getEquipmentFiles(id).then((documents) => {
      setDocumentList(documents);
    })
  }

  const handleCloseManageDocumentsModal = () => {
    setManageDocumentsModal(false);
  };

  const manageDocumentsActions = [
    {
      onClick: handleCloseManageDocumentsModal,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];

  const handleDownloadDocument = (doc) => {
    downloadEquipmentFile(doc.id).then(async (resData) => {
      downloadDoc(resData);
    }
    );
  };

  const handleDeleteDocument = (document) => {
    deleteEquipmentFile(document.id).then(() => {
      getDocumentList(equipmentId);
    });
  };

  const uploadDocument = () => {
    if (selectedFile == null || documentName == '') return;
    uploadFile(selectedFile, `equipment/files/upload?equipmentId=${equipmentId}&fileName=${documentName}`, "fileBytes").then(() => {
      getDocumentList(equipmentId);
    });
    handleCloseUploadDocumentModal();
  };

  const handleCloseUploadDocumentModal = () => {
    setUploadDocumentModal(false);
    setSelectedFile(null);
    setDocumentName('');
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const uploadActions = [
    {
      onClick: uploadDocument,
      color: "primary",
      label: "Upload Document",
      variant: "contained",
    },
    {
      onClick: handleCloseUploadDocumentModal,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];

  const handleSaveChanges = () => {
    updateChargeByEquipmentId(currentEquipmentIndex, chargesData).then(() =>
      getChargesById(currentEquipmentIndex)
    );
    setChargesModal(false);
  };

  const onFormSubmit = (data) => {
    const originalDateMaintenance = new Date(data.lastMaintenance);
    const originalDateExpiration = new Date(data.permitExpirationDate);

    const dateMaintenance = originalDateMaintenance.toISOString();
    const dateExpiration = originalDateExpiration.toISOString();

    const requestBody = {
      year: +data.year,
      vinNumber: data.vinNumber,
      permitNumber: data.permitNumber,
      permitExpirationDate: dateExpiration,
      lastMaintenance: dateMaintenance,
      category: data.category || JSON.stringify(selectedTab + 1),
      name: data.name,
      description: data.description,
    };
    if (requestBody.category === "1") {
      requestBody.fuelConsumption = data.fuelConsumption;
    }

    if (isEditMode) {
      requestBody.id = currentEquipmentIndex;
      updateEquipment(requestBody).then(() =>
        getEquipmentCategory(categoryNum)
      );
    } else {
      createEquipment(requestBody).then(() =>
        getEquipmentCategory(categoryNum)
      );
    }
    handleCloseModal();
  };

  const actions = [
    {
      onClick: handleConfirmAction,
      color: "primary",
      label: "Confirm",
      variant: "contained",
    },
    {
      onClick: handleCloseModal,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];

  const deleteActions = [
    {
      onClick: handleDeleteConfirmAction,
      color: "primary",
      label: "Confirm",
      variant: "contained",
    },
    {
      onClick: handleDeleteCloseModal,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];
  const chargeActions = [
    {
      onClick: handleSaveChanges,
      color: "primary",
      label: "Save",
      variant: "contained",
    },
    {
      onClick: handleCloseChargesModal,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];

  const setEquipment = (equipment) => {
    const equipmentData = [
      {
        label: "Equipment Name",
        type: "text",
        name: "name",
        required: true,
        email: false,
        value: equipment.name,
        size: 6,
      },
      {
        label: "Category",
        type: "select",
        name: "category",
        required: true,
        value: categoryId[equipment.category],
        selectOptions: [
          {
            label: "Rig",
            value: "1",
          },
          {
            label: "Vehicles",
            value: "2",
          },
          {
            label: "Trailers",
            value: "3",
          },
          {
            label: "Pumps",
            value: "4",
          },
          {
            label: "Tanks",
            value: "5",
          },
          {
            label: "Ancillary",
            value: "6",
          },
        ],
        size: 6,
      },
      {
        label: "Description",
        type: "text",
        name: "description",
        required: false,
        email: false,
        value: equipment.description,
        size: 6,
      },
      {
        label: "Permit Number",
        type: "text",
        name: "permitNumber",
        required: false,
        email: false,
        value: equipment.permitNumber,
        size: 6,
      },
      {
        label: "Permit Expiration Date",
        type: "datetime",
        name: "permitExpirationDate",
        required: false,
        email: false,
        value: equipment.permitExpirationDate,
        size: 6,
      },
      {
        label: "Last Maintenance",
        type: "datetime",
        name: "lastMaintenance",
        required: false,
        email: false,
        value: equipment.lastMaintenance,
        size: 6,
      },
      {
        label: "Vin Number",
        type: "text",
        name: "vinNumber",
        required: false,
        email: false,
        value: equipment.vinNumber,
        size: equipment.category === "Rig" ? 4 : 6,
      },
      {
        label: "Year",
        type: "number",
        name: "year",
        required: false,
        email: false,
        value: equipment.year,
        size: equipment.category === "Rig" ? 4 : 6,
      },
    ];

    if (equipment.category === "Rig") {
      equipmentData.push({
        label: "Fuel Consumption",
        type: "number",
        name: "fuelConsumption",
        required: true,
        email: false,
        value: equipment.fuelConsumption,
        size: 4,
      });
    }

    setFields(equipmentData);
  };

  const formattedData = data.map(item => ({
    ...item,
    'permitExpirationDate': formatDate(item['permitExpirationDate']),
    'lastMaintenance': formatDate(item['lastMaintenance']),
  }));

  return (
    <div>
      <CustomTable
        tableName={categoryName[categoryNum]}
        columns={columns}
        data={formattedData}
        rowsPerPageOptions={[10]}
        showDelete={isAdmin || isManager}
        showEdit={isAdmin || isManager}
        showCreateButton={isAdmin || isManager}
        showDocuments={true}
        showCharges={categoryNum === "1" && (isAdmin || isManager)}
        onAdd={handleAddAction}
        onEdit={handleEditAction}
        onDelete={handleDeleteAction}
        onCharges={handleAddCharges}
        onDocuments={handleDocuments}
      />
      <ModalComponent
        open={modalOpen}
        onClose={handleCloseModal}
        title={isEditMode ? "Edit Equipment" : "Add Equipment"}
        actions={actions}
        closeOnOutsideClick={false}
      >
        <Forms
          ref={ref}
          fields={fields}
          onSubmit={(data) => onFormSubmit(data)}
        />
      </ModalComponent>
      <ModalComponent
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title="Delete Equipment"
        actions={deleteActions}
        closeOnOutsideClick={false}
      >
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <HighlightOffIcon
            style={{ margin: "auto", fontSize: "58px", color: "red" }}
          />
          <Typography
            style={{ fontSize: "24px", textAlign: "center", margin: "15px" }}
          >
            Are you sure?
          </Typography>
          <Typography
            style={{ fontSize: "14px", textAlign: "center", color: "grey" }}
          >
            Are you sure you want to delete this Equipment? <br />
            The process cannot be undone.
          </Typography>
        </Box>
      </ModalComponent>
      <ModalComponent
        open={chargesModal}
        onClose={handleCloseChargesModal}
        title={`Select New Charge for ${rigName}`}
        actions={chargeActions}
        closeOnOutsideClick={false}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {chargesColumn.map((column) => (
                  <TableCell key={column.header}>{column.header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {chargesData.map((item) => (
                <TableRow key={item.id}>
                  {chargesColumn.map((column) => (
                    <TableCell key={column.accessorKey}>
                      {column.accessorKey === "rate" ? (
                        <TextField
                          type="number"
                          value={item[column.accessorKey]}
                          onChange={(event) => handleRateChange(event, item.id)}
                        />
                      ) : (
                        item[column.accessorKey]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ModalComponent>
      <ModalComponent
        open={manageDocumentsModal}
        onClose={handleCloseManageDocumentsModal}
        title={"Manage Documents"}
        actions={manageDocumentsActions}
        closeOnOutsideClick={false}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {((documentList.length === 0 || documentList === null)
            ? (<div sx={{ textAlign: "center", alignItems: "center" }}>No documents found</div>)
            : <List>
              {documentList.map((item) => (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteDocument(item)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                  sx={{ width: 350 }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <DescriptionIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.displayName}
                    secondary="Click to view"
                    onClick={() => handleDownloadDocument(item)}
                  />
                </ListItem>
              ))}
            </List>)}
          {(isAdmin || isManager) ? <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: 10, width: 350 }}
            onClick={() => setUploadDocumentModal(true)}
          >
            Upload Documents
          </Button> : null}
        </div>
      </ModalComponent>
      <ModalComponent
        open={uploadDocumentModal}
        onClose={handleCloseUploadDocumentModal}
        title={"Upload Documents"}
        actions={uploadActions}
        closeOnOutsideClick={false}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputLabel
            htmlFor="file-upload"
            sx={{ color: "black", cursor: "pointer", borderStyle: "ridge", marginRight: 1, width: 150, textAlign: "center" }}
          >
            Choose File
          </InputLabel>
          <Input
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            required
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {selectedFile ? <div>{selectedFile.name}</div> : <div>No file selected</div>}
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
          <InputLabel
            sx={{ color: "black", width: 150, textAlign: "center" }}
          >
            Document name:
          </InputLabel>
          <TextField id="document-name" variant="outlined" size="small" sx={{ marginLeft: 1 }} onChange={(e) => setDocumentName(e.target.value)} required />
        </div>
      </ModalComponent >
    </div >
  );
};

export default EquipmentOperator;
