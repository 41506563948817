import * as React from "react";
import { useEffect, useRef, useState } from "react";
import CustomTable from "../shared/table/Table";
import ModalComponent from "../shared/Modal";
import { Forms } from "../shared/forms/forms";
import { Switch, Typography } from "@mui/material";
import { Button } from "@material-ui/core";
import Box from "@mui/material/Box";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Navigate } from "react-router-dom";
import {
  addEmployeeLogin,
  createEmployee,
  deleteEmployee,
  getEmployeeById,
  getEmployees,
  removeEmployeeLogin,
  resetEmployeePassword,
  updateEmployee,
} from "../services/employee-service";
import { getEquipmentByCategory } from "../services/equipment-service";
import { formatDate } from "../utils/date-functions.js";
import { useAuth } from "../hooks/useAuth";

const resetPasswordFields = [
  {
    label: "Set Password",
    type: "password",
    name: "password",
    required: true,
    value: "",
    size: 12,
  },
  {
    label: "ID",
    type: "hidden",
    name: "id",
    required: true,
    size: 12,
  },
];

const loginFields = [
  {
    label: "Username",
    type: "text",
    name: "username",
    required: true,
    value: "",
    size: 12,
  },
  {
    label: "Set Password",
    type: "password",
    name: "password",
    required: true,
    value: "",
    size: 12,
  },
  {
    label: "Ticket Identifier",
    type: "text",
    name: "ticketIdentifier",
    required: true,
    size: 12,
  },
];

const columnData = [
  { accessorKey: "firstName", header: "First Name" },
  { accessorKey: "lastName", header: "Last Name" },
  { accessorKey: "jobTitleDisplay", header: "Job Title" },
  { accessorKey: "hireDate", header: "Hire Date" },
  { accessorKey: "terminationDate", header: "Termination Date" },
  { accessorKey: "doB", header: "DoB" },
];

export const EmployeesPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [activeEmployees, setActiveEmployees] = useState(true);
  const [fields, setFields] = useState(defaultEmployee);
  const [currentEmployeeIndex, setCurrentEmployeeIndex] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [currentEmployee, setCurrentEmployee] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [updatedEmployeeData, setUpdatedEmployeeData] = useState([]);
  const [removeLoginModalOpen, setRemoveLoginModalOpen] = useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [addLoginModalOpen, setAddLoginModalOpen] = useState(false);
  const [rigList, setRigList] = useState([]);

  const ref = useRef();

  const { role } = useAuth();

  if (role && role !== "admin") {
    return <Navigate to="/dashboard/homepage" replace />;
  }

  const getRigList = (equipmentId) => {
    getEquipmentByCategory(equipmentId).then((resData) => setRigList(resData));
  };
  useEffect(() => {
    getAllEmployees();
    getRigList(1);
  }, []);

  const selectRig = rigList.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  selectRig.unshift({
    value: null,
    label: "NO RIG",
  });

  const handleOpenModal = (edit) => {
    setEditMode(edit);
    setModalTitle(edit ? "Edit Employee" : "Create Employee");
    if (!edit) {
      setFields(defaultEmployee);
    }
    setModalOpen(true);
  };

  const getEmployeeFields = (employee) => {
    return defaultEmployee
      .filter((field) => field.name !== "id")
      .map((field) => ({
        ...field,
        value: employee[field.name],
      }));
  };

  const handleConfirmAction = () => {
    ref.current.submit();
  };

  const handleCloseModal = () => {
    setEditMode(false);
    setModalOpen(false);
    setModalTitle("");
    setSelectedEmployee(null);
  };

  const handleDeleteConfirmAction = () => {
    removeEmployee(currentEmployeeIndex);
  };

  const handleDeleteCloseModal = () => {
    setDeleteModalOpen(false);
  };

  const defaultEmployee = [
    {
      label: "First Name",
      type: "text",
      name: "firstName",
      required: true,
      value: "",
      size: 6,
    },
    {
      label: "Last Name",
      type: "text",
      name: "lastName",
      value: "",
      required: true,
      size: 6,
    },
    {
      label: "Email",
      type: "text",
      name: "email",
      required: false,
      email: true,
      value: "",
      size: 6,
    },
    {
      label: "Phone",
      type: "text",
      name: "phone",
      required: false,
      email: false,
      mask: true,
      masked: "(999) 999-9999",
      value: "",
      size: 6,
    },
    {
      label: "Pay Rate",
      type: "text",
      name: "payRate",
      required: true,
      value: "",
      size: 6,
      numbersOnly : true
    },
    {
      label: "Job Title",
      type: "select",
      name: "jobTitle",
      required: true,
      selectOptions: [
        { label: "Tool Pusher", value: "ToolPusher" },
        { label: "Crew Chief", value: "CrewChief" },
        { label: "Derrick Man", value: "DerrickMan" },
        { label: "Floor Hand", value: "FloorHand" },
      ],
      value: "",
      size: 6,
    },
    {
      label: "Date of Birth",
      type: "datetime",
      name: "doB",
      required: false,
      value: null,
      size: 6,
    },
    {
      label: "Assigned Rig",
      type: "select",
      name: "assignedRigId",
      required: false,
      selectOptions: selectRig,
      value: null,
      size: 6,
    },
    {
      label: "Employee Number",
      type: "text",
      name: "employeeNumber",
      required: true,
      value: "",
      size: 6,
    },
    {
      label: "SSN",
      type: "text",
      name: "ssn",
      required: false,
      value: "",
      mask: true,
      masked: "999-99-9999",
      size: 6,
    },
    {
      label: "Address",
      type: "text",
      name: "address",
      required: false,
      value: "",
      size: 6,
    },
    {
      label: "City",
      type: "text",
      name: "city",
      required: false,
      value: "",
      size: 6,
    },
    {
      label: "State",
      type: "text",
      name: "state",
      required: false,
      value: "",
      size: 6,
    },
    {
      label: "Zip",
      type: "number",
      name: "zip",
      required: false,
      value: "",
      size: 6,
    },
    {
      label: "Hire Date",
      type: "datetime",
      name: "hireDate",
      required: true,
      value: new Date(),
      size: 6,
    },
    {
      label: "Termination Date",
      type: "datetime",
      name: "terminationDate",
      required: false,
      value: "",
      size: 6,
    },
  ];

  const actions = [
    {
      onClick: handleConfirmAction,
      color: "primary",
      label: "Confirm",
      variant: "contained",
    },
    {
      onClick: handleCloseModal,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];

  const deleteActions = [
    {
      onClick: handleDeleteConfirmAction,
      color: "primary",
      label: "Confirm",
      variant: "contained",
    },
    {
      onClick: handleDeleteCloseModal,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];


  const handleAddAction = () => {
    handleOpenModal(false);
  };

  const handleEditAction = ({ data }) => {
    setCurrentEmployeeIndex(data.id);
    getEmployeeById(data.id).then((employee) => {
      setEditMode(true);
      setModalTitle("Edit Employee");
      setCurrentEmployeeIndex(employee.id);
      setCurrentEmployee(employee);
      setFields(getEmployeeFields(employee));
      setModalOpen(true);
    });
  };

  const handleDeleteAction = ({ data }) => {
    const id = data.id;
    setCurrentEmployeeIndex(id);
    setDeleteModalOpen(true);
  };

  const handleResetPassword = () => {
    setResetPasswordModalOpen(true);
  };

  const handleAddLogin = () => {
    setSelectedEmployee(employeeData[currentEmployeeIndex]);
    setAddLoginModalOpen(true);
  };

  const handleRemoveLogin = () => {
    setRemoveLoginModalOpen(true);
  };

  const handleCloseLoginModal = () => {
    setAddLoginModalOpen(false);
  };

  const handleCloseRemoveLoginModal = () => {
    setRemoveLoginModalOpen(false);
  };

  const handleCancelRemoveLogin = () => {
    handleCloseRemoveLoginModal(false);
  };
  const handleCloseResetPasswordModal = () => {
    setResetPasswordModalOpen(false);
  };

  const handleConfirmResetPassword = (data) => {
    const employeeData = {
      password: data.password,
    };
    employeeData.id = currentEmployeeIndex;
    resetEmployeePassword(employeeData).then(() => {
      handleCloseResetPasswordModal();
      handleCloseModal();
    });
  };

  const handleConfirmRemoveLogin = async () => {
    removeLoginEmployee(currentEmployeeIndex);
    setDeleteModalOpen(false);
  };

  const handleLoginAction = (data) => {
    data.id = currentEmployeeIndex;
    addLoginEmployee(data);
    handleCloseLoginModal();
    handleCloseModal();
  };

  const onFormSubmit = (data) => {

    if (editMode) {
      data.id = currentEmployeeIndex;
      editEmployee(data);
    } else {
      createNewEmployee(data);
    }
  };

  const addLoginEmployee = (employee) => {
    addEmployeeLogin(employee).then(() => {
      handleCloseLoginModal();
      handleCloseModal();
    });
  };

  const removeLoginEmployee = (employeeId) => {
    removeEmployeeLogin(employeeId).then(() => {
      handleCloseRemoveLoginModal(false);
      handleCloseModal();
    });
  };

  const removeEmployee = (employeeId) => {
    deleteEmployee(employeeId).then(() => {
      setDeleteModalOpen(false);
      getAllEmployees();
    });
  };

  const editEmployee = (employee) => {
    if (!employee.assignedRigId) {
      employee.assignedRigId = null;
    }
    updateEmployee(employee).then(() => {
      handleCloseModal();
      getAllEmployees();
    });
  };

  const createNewEmployee = (employee) => {
    if (!employee.assignedRigId) {
      employee.assignedRigId = null;
    }
    createEmployee(employee).then(() => {
      handleCloseModal();
      getAllEmployees();
    });
  };

  const getAllEmployees = () => {
    getEmployees(activeEmployees).then((employees) => {
      setUpdatedEmployeeData(employees);
    });
  };

  const setEmployeesFilter = () => {
    if (activeEmployees) {
      showInactiveEmployees();
    } else {
      showActiveEmployees();
    }
    setActiveEmployees(!activeEmployees);
  };

  const handleOnRowClick = () => { };

  const showActiveEmployees = () => {
    getEmployees().then((employees) => {
      setEmployeeData(employees);
      setUpdatedEmployeeData(employees);
    });
  };

  const showInactiveEmployees = () => {
    getEmployees(false).then((employees) => {
      setEmployeeData(employees);
      setUpdatedEmployeeData(employees);
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "10px",
          position: "absolute",
          top: "100px",
        }}
      >
        <Switch
          value={activeEmployees}
          checked={activeEmployees}
          onChange={setEmployeesFilter}
        />
        <h5>Show Active Employees</h5>
      </div>
      <CustomTable
        tableName={activeEmployees ? "Active Employees" : "Inactive Employees"}
        columns={columnData}
        data={updatedEmployeeData.map((employee) => ({
          firstName: employee.firstName,
          lastName: employee.lastName,
          jobTitleDisplay: employee.jobTitleDisplay,
          hireDate: formatDate(employee.hireDate),
          terminationDate: formatDate(employee.terminationDate),
          doB: formatDate(employee.doB),
          id: employee.id,
        }))}
        visibility={{
          terminationDate: activeEmployees ? false : true
        }}
        rowsPerPageOptions={[20]}
        showDelete={true}
        showEdit={true}
        onAdd={handleAddAction}
        onEdit={handleEditAction}
        onDelete={handleDeleteAction}
        onRowClick={handleOnRowClick}
      />

      <ModalComponent
        open={modalOpen}
        onClose={handleCloseModal}
        title={modalTitle}
        actions={actions}
        closeOnOutsideClick={false}
      >
        <Forms
          ref={ref}
          fields={fields}
          onSubmit={(data) => onFormSubmit(data)}
        />

        {editMode && !currentEmployee.hasLogin && (
          <Button onClick={handleAddLogin} color="primary" variant="contained">
            Add Login
          </Button>
        )}
        {editMode && currentEmployee.hasLogin && (
          <Button
            onClick={handleRemoveLogin}
            color="primary"
            variant="contained"
            style={{ marginRight: "15px" }}
          >
            Remove Login
          </Button>
        )}
        {editMode && currentEmployee.hasLogin && (
          <Button
            onClick={handleResetPassword}
            color="primary"
            variant="contained"
          >
            Reset password
          </Button>
        )}
        <ModalComponent
          open={resetPasswordModalOpen}
          onClose={handleCloseResetPasswordModal}
          title="Reset Password"
          actions={[
            {
              onClick: handleConfirmResetPassword,
              color: "primary",
              label: "Reset Password",
              variant: "contained",
            },
            {
              onClick: handleCloseResetPasswordModal,
              color: "default",
              label: "Cancel",
              variant: "outlined",
            },
          ]}
          closeOnOutsideClick={false}
        >
          <Forms
            fields={resetPasswordFields}
            onSubmit={handleConfirmResetPassword}
          />
        </ModalComponent>

        <ModalComponent
          open={addLoginModalOpen}
          onClose={handleCloseLoginModal}
          title="Add Login"
          actions={[
            {
              onClick: () => ref.current.submit(),
              color: "primary",
              label: "Confirm",
              variant: "contained",
            },
            {
              onClick: handleCloseLoginModal,
              color: "default",
              label: "Cancel",
              variant: "outlined",
            },
          ]}
          closeOnOutsideClick={false}
        >
          <Forms ref={ref} fields={loginFields} onSubmit={handleLoginAction} />
        </ModalComponent>

        <ModalComponent
          open={removeLoginModalOpen}
          onClose={handleCloseRemoveLoginModal}
          title="Remove Login"
          actions={[
            {
              onClick: handleConfirmRemoveLogin,
              color: "primary",
              label: "Confirm",
              variant: "contained",
            },
            {
              onClick: handleCancelRemoveLogin,
              color: "default",
              label: "Cancel",
              variant: "outlined",
            },
          ]}
          closeOnOutsideClick={false}
        >
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <HighlightOffIcon
              style={{ margin: "auto", fontSize: "58px", color: "red" }}
            />
            <Typography
              style={{ fontSize: "24px", textAlign: "center", margin: "15px" }}
            >
              Are you sure?
            </Typography>
            <Typography
              style={{ fontSize: "14px", textAlign: "center", color: "grey" }}
            >
              Do you really want to delete this employee login? <br />
              The process cannot be undone.
            </Typography>
          </Box>
        </ModalComponent>
      </ModalComponent>

      <ModalComponent
        open={deleteModalOpen}
        onClose={handleDeleteCloseModal}
        title="Delete Employee"
        actions={deleteActions}
        closeOnOutsideClick={false}
      >
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <HighlightOffIcon
            style={{ margin: "auto", fontSize: "58px", color: "red" }}
          />
          <Typography
            style={{ fontSize: "24px", textAlign: "center", margin: "15px" }}
          >
            Are you sure?
          </Typography>
          <Typography
            style={{ fontSize: "14px", textAlign: "center", color: "grey" }}
          >
            Do you really want to delete this employee? <br />
            The process cannot be undone.
          </Typography>
        </Box>
      </ModalComponent>
    </>
  );
};
