import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  styled,
  Typography,
  IconButton,
  Backdrop,
  ClickAwayListener,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const ModalComponent = ({
  open,
  onClose,
  title,
  children,
  actions = [],
  closeOnOutsideClick,
  haveExitButton = true,
  isSmall = false,
  isMedium = false
}) => {
  const StyledDialogTitle = styled(DialogTitle)({
    borderBottom: '1px solid grey',
    fontSize: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  });

  const handleClose = () => {
    if (closeOnOutsideClick) {
      onClose();
    }
  };
  let minWidth = '600px';
  let maxWidth = isSmall ? '600px' : '1200px';
  if (isMedium){
    minWidth = '900px';
    maxWidth = '900px';
  }
  return (
    <Dialog
      open={open}
      BackdropComponent={Backdrop}
      disableEnforceFocus
      PaperProps={{
        style: {
          minWidth: minWidth,
          maxWidth: maxWidth,
        },
      }}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <StyledDialogTitle disableTypography>
            <Typography variant="h6">{title}</Typography>
            {haveExitButton ? (
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </StyledDialogTitle>

          <div>
            <DialogContent
              dividers={true}
              style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                padding: '40px 20px',
                maxHeight: '70vh',
              }}
            >
              {children}
            </DialogContent>
          </div>

          <DialogActions style={{ paddingTop: '15px', paddingBottom: '15px' }}>
            {actions.map((action, index) => (
              <Button
                key={index}
                onClick={action.onClick}
                color={action.color}
                variant={action.variant}
                disabled={action.disabled}
              >
                {action.label}
              </Button>
            ))}
          </DialogActions>
        </div>
      </ClickAwayListener>
    </Dialog>
  );
};

export default ModalComponent;
