import { Navigate} from "react-router-dom";
import {useAuth} from "../hooks/useAuth";
export const InvoicesPage = () => {
    const { role } = useAuth();

    if (role !== 'admin' && role !== 'manager') {
        return <Navigate to="/dashboard/homepage" replace/>;
    }

    return (
        <p style={{textAlign: "center"}}>Invoices page</p>
    )
}
