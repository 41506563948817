import React, { useRef, useState, useEffect, useCallback } from "react";
import SignaturePad from "react-signature-canvas";
import ModalComponent from "../Modal";
import "../../styles.css";
import { Button, TextField } from '@mui/material';
import { updateProfile } from "../../services/profile-service";

export const Signature = ({ signature, setSignature }) => {
    const [imageUrl, setImageUrl] = useState(signature);
    const [signatureOpen, setSignatureOpen] = useState(false);

    useEffect(() => {
        setImageUrl(signature);
    }, [signature]);

    const saveSignatureAction = (signature) => {
        setSignature(signature);
        setSignatureOpen(false);
        return updateProfile({signature});
    }

    return <div>
        <>
        <SignatureModal saveAction={saveSignatureAction}
                closeAction={() => setSignatureOpen(false)}
                isOpen={signatureOpen}
                modalTitle={!imageUrl ? "Add signature" : "Modify Signature"}
            />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '400px', marginBottom: '5px', marginTop: '20px' }}>
                <Button onClick={() => { 
                    setSignatureOpen(true);
                }} color="primary" variant="contained" style={{
                    width: '400px'
                }} >
                    {!imageUrl ? "Add signature" : "Modify Signature"}
                </Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '400px', marginTop: '10px' }}>
                {imageUrl ? <img src={imageUrl} alt="Signature" style={{
                    border: '1px solid', width: '400px'
                }} /> : null
                }
            </div>
        </>
        </div >;
};

export const SignatureModal = ({saveAction, closeAction, isOpen, modalTitle, showPrintedName = false}) => {
    const [printedName, setPrintedName] = useState(null);
    const [signaturePad, setSignaturePad] = useState(null);
    const [showSignaturePlaceholder, setShowSignaturePlaceHolder] = useState(true);
  
    const signaturePadRef = useCallback((signaturePad) => {
        setSignaturePad(signaturePad);
    }, []);

    React.useEffect(() => {
        if (signaturePad) {
            signaturePad.getSignaturePad().onBegin = () => {
                onStrokeBegin();
            };
        }
    }, [signaturePad]);

    const clear = () => {
        signaturePad.clear();
        setShowSignaturePlaceHolder(true);
    }

    const initiateSaveAction = () => {
        if (signaturePad.isEmpty()){
            return;
        }
        const image = signaturePad.toDataURL("image/png");
        saveAction(image, printedName);
    };

    const onStrokeBegin = () => {
        setShowSignaturePlaceHolder(false);
    }

    const closeActionInternal = () => {
        setPrintedName(null);
        setShowSignaturePlaceHolder(true);
        closeAction();
    };

    const actions = [
        {
            onClick: initiateSaveAction,
            color: "primary",
            label: "Save",
            variant: "contained",
            disabled: showPrintedName ? (!printedName || showSignaturePlaceholder) : showSignaturePlaceholder
        },
        {
            onClick: clear,
            color: "default",
            label: "Clear",
            variant: "outlined"
        },
        {
            onClick: closeActionInternal,
            color: "default",
            label: "Cancel",
            variant: "outlined"
        }
    ];
    const savePrintedName = (event) => {
        setPrintedName(event.target.value);
    };

    return <ModalComponent
            title={modalTitle}
            open={isOpen}
            actions={actions}
            onClose={closeAction}
            closeOnOutsideClick={false}
            isSmall={true}
            >
            <div >
            {showPrintedName && (
                <TextField
                    label="Printed Name"
                    variant="outlined"
                    onChange={savePrintedName}
                    required
                    sx={{ width: "100%" }}
                />
            )}
            <div class="signature-form">
            <SignaturePad
                ref={signaturePadRef}
                canvasProps={{
                    className: "signatureCanvas"
                }}
                penColor="blue"
            >
            </SignaturePad>
            {showSignaturePlaceholder && (
                <div class="signature-placeholder">Sign Here</div>
            )}
            </div>
            </div>
        </ModalComponent>;
};
