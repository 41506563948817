import React from 'react';
import { Tabs, Tab, Box, Select, MenuItem, useMediaQuery, useTheme, Typography } from '@mui/material';
import { Navigate } from "react-router-dom";
import EquipmentOperator from '../components/equipment/equipment-operator';
import { styled } from '@mui/system';
import {useAuth} from "../hooks/useAuth";

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: '16px',
  color: 'black',
  fontWeight: "600"
}));

export const EquipmentPage = () => {
  const { role } = useAuth();

  if (role === 'customer') {
    return <Navigate to="/dashboard/tickets" replace />;
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedTab, setSelectedTab] = React.useState(+localStorage.getItem("selectedTab") || 0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    localStorage.setItem("selectedTab", newValue.toString());
  };

  const handleMobileTabChange = (event) => {
    setSelectedTab(event.target.value);
  };

  return (
    <Box >
      {/* Render Tabs for larger screens */}
      {!isMobile && (
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <StyledTab label="Rigs" />
          <StyledTab label="Vehicles" />
          <StyledTab label="Trailers" />
          <StyledTab label="Pumps" />
          <StyledTab label="Tanks" />
          <StyledTab label="Ancillary" />
        </Tabs>
      )}

      {/* Render Select for mobile resolutions */}
      {isMobile && (
         <div style={{ margin: '20px' }}>
         <Typography variant="h6" sx={{ marginBottom: 2,  }}>
           Choose equipment table:
         </Typography>

           <Select
             value={selectedTab}
             onChange={handleMobileTabChange}
             variant="outlined"
             fullWidth
           >
             <MenuItem value={0}>Rigs</MenuItem>
             <MenuItem value={1}>Vehicles</MenuItem>
             <MenuItem value={2}>Trailers</MenuItem>
             <MenuItem value={3}>Pumps</MenuItem>
             <MenuItem value={4}>Tanks</MenuItem>
             <MenuItem value={5}>Ancillary</MenuItem>
           </Select>
       </div>
      )}

      <div style={{ margin: "20px" }}>
        <EquipmentOperator selectedTab={selectedTab} />
      </div>
    </Box>
  );
}
