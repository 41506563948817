import { postData } from "./http-service";

export const getAllSettings = async () => {
    const response = await postData({
      method: "GET",
      path: "settings",
      showToaster: false,
    });
    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    } else {
      return null;
    }
};

export const updateSettings = async (settingsBody) => {
    try {
        const response = await postData({
            path: 'settings/update',
            data: settingsBody,
            toasterMessage: "Settings Successfully Updated!",
        });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}