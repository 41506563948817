import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const BaseLayout = () => {
    const { user } = useAuth();
    const outlet = useOutlet();


    if (user) {
        const queryParameters = new URLSearchParams(window.location.search)
        const redirectUrl = queryParameters.get("redirectTo");
        const navigateTo = redirectUrl && redirectUrl !== "" ? `/${redirectUrl}` : "/dashboard/homepage";
        return <Navigate to={navigateTo} replace />;
    }

    return (
        <>
            <div className="login-layout">
                {outlet}
            </div>
            <footer style={{color: "gray", position: "fixed", bottom: 0, left: 0, right: 0}}>
                <p style={{textAlign: "center", color: "darkgray", fontFamily: "Arial, sans-serif" }}>Powered by <a style={{textDecoration: "none", color: "black" }} href="https://www.readydev.tech" target="_blank">ReadyDev Technologies</a></p>
            </footer>
        </>
    );
};
