import CustomTable from "../shared/table/Table"
import Box from "@mui/material/Box";
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import ModalComponent from "../shared/Modal";

import { Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";

import { getAllUsers, createUser, deleteUser, resetPassword, updateUser } from "../services/user-service";

import { Forms } from "../shared/forms/forms";
import { Navigate } from "react-router-dom";
import * as React from "react";
import { useAuth } from "../hooks/useAuth";

export const UsersPage = () => {
    const { role } = useAuth();
    const [userList, setUserList] = useState([]);
    const [modalOpenCreate, setModalOpenCreate] = useState(false);
    const [currentUserId, setCurrentUserId] = useState(undefined);
    const [currentUserRole, setCurrentUserRole] = useState(undefined);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateUserFields, setUpdateUserFields] = useState([]);

    if (role && role !== "admin") {
        return <Navigate to="/dashboard/homepage" replace />;
    }

    const ref = useRef();
    const passwordRef = useRef();
    const editUserRef = useRef();

    const passwordResetField = [
        {
            label: 'Password Reset',
            type: 'password',
            name: 'password',
            required: true,
            email: false,
            value: '',
            size: 12
        }
    ]

    const createUserFields = [
        {
            label: 'First Name',
            type: 'text',
            name: 'firstName',
            required: true,
            email: false,
            value: '',
            size: 6,
        },
        {
            label: 'Last Name',
            type: 'text',
            name: 'lastName',
            required: true,
            email: false,
            value: '',
            size: 6
        },
        {
            label: 'Username',
            type: 'text',
            name: 'userName',
            required: true,
            email: false,
            value: '',
            size: 6
        },
        {
            label: 'Password',
            type: 'password',
            name: 'password',
            required: true,
            email: false,
            value: '',
            size: 6
        },
        {
            label: 'Email',
            type: 'text',
            name: 'email',
            required: true,
            email: true,
            value: '',
            size: 6
        },
        {
            label: 'Role',
            type: 'select',
            name: 'role',
            required: true,
            email: false,
            selectOptions: [
                {
                    label: 'Admin',
                    value: 'Admin'
                },
                {
                    label: 'Manager',
                    value: 'Manager'
                }
            ],
            size: 6
        },
        {
            label: 'Ticket Identifer',
            type: 'text',
            name: 'ticketIdentifier',
            required: true,
            email: false,
            value: '',
            size: 12
        }
    ]

    const setUser = (user) => {
        const userData = user.data;
        setCurrentUserId(userData.id);
        setCurrentUserRole(userData.role);

        const updateUserFields = [
            {
                label: 'First Name',
                type: 'text',
                name: 'firstName',
                required: true,
                email: false,
                value: userData.firstName,
                size: 6,
            },
            {
                label: 'Last Name',
                type: 'text',
                name: 'lastName',
                required: true,
                email: false,
                value: userData.lastName,
                size: 6
            },
            {
                label: 'Email',
                type: 'text',
                name: 'email',
                required: true,
                email: true,
                value: userData.email,
                size: 6
            },
            {
                label: 'Ticket Identifer',
                type: 'text',
                name: 'ticketIdentifier',
                required: true,
                email: false,
                value: userData.ticketIdentifier,
                size: 6
            },
            {
                label: 'Username',
                type: 'text',
                name: 'userName',
                required: false,
                email: false,
                disabled: true,
                value: userData.username,
                size: 6,
            },
            {
                label: 'Role',
                type: 'select',
                name: 'role',
                required: true,
                email: false,
                selectOptions: (userData.role === 'ToolPusher' || userData.role === 'Customer') ?
                    [
                        {
                            label: 'Toolpusher',
                            value: 'ToolPusher'
                        },
                        {
                            label: 'Customer',
                            value: 'Customer'
                        }
                    ] :
                    [
                        {
                            label: 'Admin',
                            value: 'Admin'
                        },
                        {
                            label: 'Manager',
                            value: 'Manager'
                        }
                    ],
                value: userData.role,
                readOnly: userData.role === 'ToolPusher' || userData.role === 'Customer',
                size: 6
            },
        ];
        setUpdateUserFields(updateUserFields);
    }

    const getUsers = () => {
        getAllUsers().then((resData) => {
            setUserList(resData);
        });
    }

    useEffect(() => {
        getUsers();
    }, []);

    const columns = [
        { accessorKey: 'firstName', header: 'First Name' },
        { accessorKey: 'lastName', header: 'Last Name' },
        { accessorKey: 'username', header: 'Username' },
        { accessorKey: 'email', header: 'Email' },
        { accessorKey: 'role', header: 'Role' }
    ]

    // Update modal actions

    const handleEdit = (data) => {
        setUser(data);
        setUpdateModalOpen(true);
    }

    const handleEditModalClose = () => {
        setUpdateModalOpen(false);
    }

    const handleConfirmEdit = () => {
        editUserRef.current.submit();
    }

    // End

    // Delete modal actions

    const handleDelete = (data) => {
        setCurrentUserId(data.data.id);
        setDeleteModalOpen(true);
    }

    const handleDeleteModalClose = () => {
        setDeleteModalOpen(false);
    }

    const handleConfirmDelete = () => {
        deleteUser(currentUserId).then(() => {
            setDeleteModalOpen(false);
            getUsers();
        });
    }

    // End

    // Create modal actions

    const handleCreate = () => {
        setModalOpenCreate(true);
    }

    const handleConfirmAction = () => {
        ref.current.submit();
    }

    const handleCloseModal = () => {
        setModalOpenCreate(false);
    }

    // End

    // Reset password modal actions

    const handleResetPassword = (data) => {
        setCurrentUserId(data.data.id)
        setResetPasswordModalOpen(true);
    }

    const handleResetPasswordModalClose = () => {
        setResetPasswordModalOpen(false);
    }

    const handleConfirmResetPassword = () => {
        passwordRef.current.submit();
    }

    // End

    // Form handle functions

    const onFormSubmitCreate = (data) => {
        const requestBody = {
            firstName: data.firstName,
            lastName: data.lastName,
            username: data.userName,
            email: data.email,
            password: data.password,
            role: data.role,
            ticketIdentifier: data.ticketIdentifier
        }

        createUser(requestBody).then(() => {
            setModalOpenCreate(false);
            getUsers();
        });
    }

    const onSubmitPasswordReset = (data) => {
        const requestBody = {
            userId: currentUserId,
            password: data.password
        }

        resetPassword(requestBody).then(() => {
            setResetPasswordModalOpen(false);
            getUsers();
        });
    }

    const onSubmitUpdateUser = (data) => {
        const requestBody = {
            id: currentUserId,
            role: data.role,
            firstName: data.firstName,
            lastName: data.lastName,
            username: data.userName,
            email: data.email,
            ticketIdentifier: data.ticketIdentifier
        }

        updateUser(requestBody).then(() => {
            setUpdateModalOpen(false);
            getUsers();
        });
    }

    // End

    // Create modal actions

    const actions = [
        {
            onClick: handleConfirmAction,
            color: "primary",
            label: "Confirm",
            variant: "contained",
        },
        {
            onClick: handleCloseModal,
            color: "default",
            label: "Cancel",
            variant: "outlined",
        },
    ];

    // End

    // Delete modal actions

    const deleteActions = [
        {
            onClick: handleConfirmDelete,
            color: "primary",
            label: "Confirm",
            variant: "contained",
        },
        {
            onClick: handleDeleteModalClose,
            color: "default",
            label: "Cancel",
            variant: "outlined",
        },
    ];

    // End

    // Reset Password modal actions

    const resetPasswordActions = [
        {
            onClick: handleConfirmResetPassword,
            color: "primary",
            label: "Confirm",
            variant: "contained",
        },
        {
            onClick: handleResetPasswordModalClose,
            color: "default",
            label: "Cancel",
            variant: "outlined",
        },
    ];

    // End

    // Update user modal actions

    const updateUserModalActions = [
        {
            onClick: handleConfirmEdit,
            color: "primary",
            label: "Confirm",
            variant: "contained",
        },
        {
            onClick: handleEditModalClose,
            color: "default",
            label: "Cancel",
            variant: "outlined",
        },
    ];

    // End

    return (
        <>
            <CustomTable
                tableName="Users"
                columns={columns}
                data={userList}
                rowsPerPageOptions={10}
                showDelete={true}
                showEdit={true}
                showResetPassword={true}
                showCreateButton={true}
                onEdit={handleEdit}
                onDelete={handleDelete}
                onAdd={handleCreate}
                onHandlePassword={handleResetPassword}
            />
            <ModalComponent
                open={modalOpenCreate}
                onClose={handleCloseModal}
                title={"Create User"}
                actions={actions}
                closeOnOutsideClick={false}
            >
                <Forms
                    ref={ref}
                    fields={createUserFields}
                    onSubmit={(data) => onFormSubmitCreate(data)}
                />
            </ModalComponent>

            <ModalComponent
                open={deleteModalOpen}
                onClose={handleDeleteModalClose}
                title={"Delete User"}
                actions={deleteActions}
                closeOnOutsideClick={false}
            >
                <Box style={{ display: "flex", flexDirection: "column" }}>
                    <HighlightOffIcon
                        style={{ margin: "auto", fontSize: "58px", color: "red" }}
                    />
                    <Typography
                        style={{ fontSize: "24px", textAlign: "center", margin: "15px" }}
                    >
                        Are you sure?
                    </Typography>
                    <Typography
                        style={{ fontSize: "14px", textAlign: "center", color: "grey" }}
                    >
                        Are you sure you want to delete this User? <br />
                        This process cannot be undone.
                    </Typography>
                </Box>
            </ModalComponent>

            <ModalComponent
                open={resetPasswordModalOpen}
                onClose={handleResetPasswordModalClose}
                title={"Reset User Password"}
                actions={resetPasswordActions}
                closeOnOutsideClick={false}
            >
                <Forms
                    ref={passwordRef}
                    fields={passwordResetField}
                    onSubmit={(data) => onSubmitPasswordReset(data)}
                />
            </ModalComponent>

            <ModalComponent
                open={updateModalOpen}
                onClose={handleEditModalClose}
                title={"Update User Data"}
                actions={updateUserModalActions}
                closeOnOutsideClick={false}
            >
                <Forms
                    ref={editUserRef}
                    fields={updateUserFields}
                    onSubmit={(data) => onSubmitUpdateUser(data)}
                />
            </ModalComponent>
        </>
    )
}
