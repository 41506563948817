import { useState, useRef, useEffect, useCallback } from "react";
import ModalComponent from "../../shared/Modal";
import { getCustomerById } from "../../services/customer-service";
import { useNavigate } from "react-router-dom";
import { Forms } from "../../shared/forms/forms";
import {
    createTicket
  } from "../../services/tickets-service";

export const CreateTicketModal = ({isOpen, setIsOpen, customers, assignedEquipment, equipment, initialData}) => {
    const [locations, setLocations] = useState([]);
    const [isYardService, setIsYardService] = useState(false);
    const [fields, setFields] = useState(defaultTicket);
    const [createDisabled, setCreateDisabled] = useState(false);

    const ref = useRef();

    const navigate = useNavigate();

    useEffect(() => {
        setFields(defaultTicket);
      }, [isYardService]);

    useEffect(() => {
        if (initialData){
            setFields(defaultTicket);
            if (initialData.customerId){
                getCustomerById(initialData.customerId).then((res) => {
                    setLocations(res.locations);
                  });
            }
        }
    }, [initialData]);

    useEffect(() => {
      if (assignedEquipment){
          setFields(defaultTicket);
      }
  }, [assignedEquipment]);

    useEffect(() => {
        setFields(defaultTicket);
    }, [locations, equipment, customers]);

    const selectCustomer = customers.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    const selectEquipment = equipment.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    const selectLocation = locations.map((item) => ({
      value: item.id,
      label: item.displayName,
    }));

    const defaultTicket = [
    {
      label: "Service Type",
      type: "multiSelect",
      name: "serviceTypes",
      required: true,
      email: false,
      selectOptions: [
        {
          label: "Rods and Tubing",
          value: "RodsAndTubing",
        },
        {
          label: "Workover",
          value: "Workover",
        },
        {
          label: "P&A",
          value: "PAndA",
        },
        {
          label: "Completion",
          value: "Completion",
        },
        {
          label: "Stand By",
          value: "StandBy",
        },
        {
          label: "Yard",
          value: "Yard",
        },
        {
          label: "Roustabout",
          value: "Roustabout",
        },
      ],
      onChange: (e) => {
        setIsYardService(e.includes("Yard"));
      },
      size: isYardService ? 4 : 6,
    },
    {
      label: "Equipment Name",
      type: "select",
      name: "equipmentId",
      required: true,
      value: initialData?.equipmentId ?? assignedEquipment?.id,
      selectOptions: selectEquipment,
      size: isYardService ? 4 : 6,
    },
    {
      label: "Execution Date",
      type: "datetime",
      name: "executionDate",
      required: true,
      email: false,
      value: new Date(),
      size: 4,
    },

    !(isYardService)
      ? {
          label: "Customer Name",
          type: "autocomplete",
          name: "customerId",
          required: false,
          email: false,
          value: initialData?.customerId,
          selectOptions: selectCustomer,
          onChange: (option) => {
            if (!option){
              setLocations([]);
              return;
            }
            getCustomerById(option).then((res) => {
              setLocations(res.locations);
            });
          },
          size: 4
        }
      : "",

    !(isYardService)
      ? {
          label: "Customer Location",
          type: "select",
          name: "customerLocationId",
          required: false,
          email: false,
          value: initialData?.customerLocationId,
          selectOptions: selectLocation,
          size: 4,
        }
      : "",
  ];

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleConfirmAction = () => {
    ref.current.submit();
  };

  const onTicketCreatedFormSubmitted = (data) => {
    setCreateDisabled(true);
    data.executionDate = data.executionDate.toISOString().split('T')[0];
    createTicket(data).then((response) => {
      setCreateDisabled(false);
      handleCloseModal();
      navigate(`/dashboard/tickets/edit/${response}`);
    }).catch(er => {
      setCreateDisabled(false);
    });
  };

  const actions = [
    {
      onClick: handleConfirmAction,
      color: "primary",
      label: "Confirm",
      variant: "contained",
      disabled: createDisabled
    },
    {
      onClick: handleCloseModal,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];

  return (
    <>
      <ModalComponent
        open={isOpen}
        onClose={handleCloseModal}
        title={"Create Ticket"}
        actions={actions}
        closeOnOutsideClick={false}
        isMedium={true}
      >
        <Forms
          ref={ref}
          fields={fields}
          onSubmit={(data) => onTicketCreatedFormSubmitted(data)}
        />
      </ModalComponent>
    </>
  );
};