import { Container } from "@mui/system";
import React from "react";
import { useParams } from "react-router-dom";
import { useState, useRef, useEffect, useReducer } from "react";
import { Forms } from "../../shared/forms/forms";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
  Tabs,
  Tab,
  Box,
  Button,
  MenuItem,
  IconButton,
  Input,
  InputLabel,
  Tooltip
} from "@mui/material";
import { styled } from "@mui/system";
import {
  getTicketById,
  updateTicket,
  updateTicketHours,
  updateTicketSpecification,
  createNewPayroll,
  deletePayroll,
  updatePayroll,
  ticketSign,
  resetTicketSign,
  downloadTicket,
  getPreviewTicket,
  customerTicketSign,
  getPayrollDetails,
  sendToCustomer
} from "../../services/tickets-service";
import { getEquipmentByCategory } from "../../services/equipment-service";
import { getCustomers, getCustomerById } from "../../services/customer-service";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
} from "@mui/material";
import { getAllEmployees } from "../../services/employee-service";
import { SignatureModal } from "../../shared/signature/signature";
import { getProfile } from "../../services/profile-service";
import Divider from "@mui/material/Divider";
import ModalComponent from "../../shared/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { uploadFile } from "../../services/http-service";
import { formatDateTime, toCurrencyNumber } from "../../utils/date-functions";
import { LoadingComponent } from "../../services/loading-service";
import dayjs from "dayjs";
import {useAuth} from "../../hooks/useAuth";
import { downloadDoc } from "../../utils/download-file";
let utc = require('dayjs/plugin/utc')
dayjs.extend(utc);

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: "16px",
  color: "black",
  fontWeight: "600",
}));

export const EditTicket = () => {
  const { id } = useParams();
  const { role } = useAuth();

  const [selectedTab, setSelectedTab] = useState(0);
  const [ticketBasicInfoFields, setTicketBasicInfoFields] = useState([]);
  const [ticketHourlyFields, setTicketHourlyFields] = useState([]);
  const [chargesDataLeft, setChargesDataLeft] = useState([]);
  const [chargesDataRight, setChargesDataRight] = useState([]);
  const [payrollData, setPayrollData] = useState([]);
  const [hasUserSignature, setHasUserSignature] = useState(false);
  const [hasCustomerSignature, setHasCustomerSignature] = useState(false);
  const [showTimeErrorMessage, setShowTimeErrorMessage] = useState(false);
  const [chargeToUpdate, setChargeToUpdate] = useState(null);
  const [ticketData, setTicketData] = useState(null);
  const [canUpdateTicket, setCanUpdateTicket] = useState(null);

  const [ticketTotal, setTicketTotal] = useState(0);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [isYard, setIsYard] = useState(false);
  const [isRoustabout, setIsRoustabout] = useState(false);

  const [customerId, setCustomerId] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [locations, setLocations] = useState([]);

  const [allEmployees, setAllEmployees] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const [createPayrollModalOpened, setCreatePayrollModalOpened] = useState(false);
  const [showPayrollEntryName, setShowPayrollEntryName] = useState(false);
  const [payrollEntryName, setPayrollEntryName] = useState("");
  const [deletePayrollModalOpened, setDeletePayrollModalOpened] = useState(false);
  const [currentPayrollIndex, setCurrentPayrollIndex] = useState("");
  const [currentPayrollName, setCurrentPayrolllName] = useState("");
  const [isEditingPayroll, setIsEditingPayroll] = useState(false);
  const [editPayrollModalOpened, setEditPayrollModalOpened] = useState(false);
  const [payrollEditFields, setPayrollEditFields] = useState([]);
  const [employeeId, setEmployeeId] = useState("");
  const [payrollId, setPayrollId] = useState("");

  const [htmlPreviewModalOpened, sethtmlPreviewModalOpened] = useState("");
  const [previewTicketHtml, setPreviewTicketHtml] = useState("");
  const [signatureModalOpened, setSignatureModalOpened] = useState(false);
  const [isEmployeeSignature, setIsEmployeeSignature] = useState(true);
  const [resetSignaturesModalOpened, setResetSignaturesModalOpened] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);

  const [uploadTicketModal, setUploadTicketModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const isAdmin = role && role === "admin";
  const isCustomer = role && role === "customer";
  const primaryColor = "#192cd2";

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const navigate = useNavigate();
  const ref = useRef();

  // Region - Data Requests

  // Getting all ticket data and setting the form
  const getTicketsData = () => {
    setTicketData(null);
    setCanUpdateTicket(null);
    getTicketById(id).then((data) => {
      setTicketData(data);
      setCanUpdateTicket(!data.hasEmployeeSignature && !isCustomer);
      setMainLoading(false);
    });
  };

  const setTicketInfo = () => {
    setTicketBasicInfo(ticketData);
    setTicketHourlyData(ticketData);
    setChargesDataLeft(ticketData.ticketSpecificationsLeft);
    setChargesDataRight(ticketData.ticketSpecificationsRight);
    setPayrollData(ticketData.payrollData);
    setHasUserSignature(ticketData.hasEmployeeSignature);
    setHasCustomerSignature(ticketData.hasCustomerSignature);
    setCustomerId(ticketData.customerId);
    setServiceTypes(ticketData.serviceTypes);
    setTicketTotal(ticketData.total);
  };

  // Get list of locations for customer
  const getLocationsList = (customerId) => {
    if (!customerId) {
      return;
    }
    setMainLoading(true);
    getCustomerById(customerId).then((res) => {
      const currentLocations = res.locations.map((item) => ({
        value: item.id,
        label: item.displayName,
      }));
      setLocations(currentLocations || []);
      setTicketBasicInfo(ticketData, currentLocations);
      setMainLoading(false);
    });
  };

  // EndRegion - Data Requests

  // Region - UseEffects

  // Initial get requests
  useEffect(() => {
    // Getting all rigs
    setMainLoading(true);
    getEquipmentByCategory(1).then((resData) => {
      setEquipment(resData);
      // Getting all customers
      getCustomers().then((resData) => {
        setCustomers(resData);
        // Getting all employees
        getAllEmployees().then((resData) => {
          setAllEmployees(resData);
          getTicketsData();
        });
      });
    });
  }, []);

  useEffect(() => {
    if (ticketData == null) {
      return;
    }
    setIsYard(ticketData.serviceTypes.includes("Yard"));
    setIsRoustabout(ticketData.serviceTypes.includes("Roustabout"));
  }, [ticketData]);

  // Get locations for customer on customer id change
  useEffect(() => {
    getLocationsList(customerId);
  }, [customerId]);

  useEffect(() => {
    if (ticketData == null) {
      return;
    }
    setTicketInfo();
  }, [canUpdateTicket, isYard]);


  // EndRegion - UseEffects

  // Region - UI Helpers & Definitions

  // Equipment select items
  const selectEquipment = equipment.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  // Customer select items
  const selectCustomer = customers.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  // Employee select items
  const selectEmployee = employeeList.map((item) => ({
    value: item.id,
    label: item.firstName + " " + item.lastName,
  }));

  // Ticket update button
  const ticketUpdateButton = {
    buttonName: "Update"
  };

  // Columns for charges section
  const chargesColumn = [
    { accessorKey: "charge", header: "Name" },
    { accessorKey: "uoM", header: "uoM" },
    { accessorKey: "rate", header: "Rate" },
    { accessorKey: "quantity", header: "Quantity" },
    { accessorKey: "total", header: "Total", isTotal: true },
  ];

  // EndRegion - UI Helpers & Definitions

  // Region - Fields

  // Base ticket info fields
  const setTicketBasicInfo = (ticketData, currentLocations = null) => {
    const basicTicketFields = [
      {
        label: "Ticket ID",
        type: "text",
        name: "ticketId",
        readOnly: !isAdmin,
        disabled: !canUpdateTicket,
        required: true,
        value: ticketData.ticketId,
        size: 3,
      }, 
      {
        label: "Customer",
        type: "autocomplete",
        name: "customerId",
        required: false,
        value: ticketData.customerId,
        disabled: !canUpdateTicket || isYard,
        selectOptions: isYard ? [''] : selectCustomer,
        onChange: (customerId) => {
          setCustomerId(customerId);
        },
        size: 3,
      },
      {
        label: "Customer Location",
        type: "select",
        name: "locationId",
        disabled: !canUpdateTicket || isYard,
        required: false,
        value: ticketData.locationId,
        selectOptions: isYard ? [''] : currentLocations || locations,
        size: 3,
      },
      {
        label: "Email ticket to",
        type: "text",
        name: "sendEmailTo",
        disabled: !canUpdateTicket || isYard,
        required: false,
        value: ticketData.sendEmailTo,
        size: 3,
      },
      {
        label: "Service Type",
        type: "multiSelect",
        name: "serviceTypes",
        disabled: !canUpdateTicket,
        required: true,
        value: ticketData.serviceTypes,
        selectOptions: [
          {
            label: "Rods and Tubing",
            value: "RodsAndTubing",
            disabled: false
          },
          {
            label: "Workover",
            value: "Workover",
            disabled: false
          },
          {
            label: "P&A",
            value: "PAndA",
            disabled: false
          },
          {
            label: "Completion",
            value: "Completion",
            disabled: false
          },
          {
            label: "Stand By",
            value: "StandBy",
            disabled: false
          },
          {
            label: "Yard",
            value: "Yard",
            disabled: false
          },
          {
            label: "Roustabout",
            value: "Roustabout",
            disabled: false
          },
        ],
        size: 4,
        onChange: (event) => {
          setIsYard(event.includes("Yard"));
          setIsRoustabout(event.includes("Roustabout"));
        }
      },
      {
        label: "Equipment Name",
        type: "select",
        name: "equipmentId",
        disabled: !canUpdateTicket,
        required: true,
        value: ticketData.equipmentId,
        selectOptions: selectEquipment,
        size: 4,
      },
      {
        label: "Execution Date",
        type: "datetime",
        name: "executionDate",
        disabled: !canUpdateTicket,
        required: true,
        value: ticketData.executionDate,
        size: 4,
      }
    ];

    setTicketBasicInfoFields(basicTicketFields);
  };

  // Region - UI Handlers

  // Handle Tab change
  const onTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Handle basic ticket info edited
  const onBasicTicketInfoEdited = (data) => {
    data.id = id;
    data.customerLocationId = data.locationId;
    if (isYard) {
      data.customerId = null;
      data.customerLocationId = null;
    } else if (data.customerId === "" || data.customerLocationId === "" || data.customerId === null || data.customerLocationId === null) {
      return;
    }
    setMainLoading(true);
    updateTicket(data).then(() => {
      getTicketsData();
    });
  };

  // Handle update for ticket hours
  const onTicketHoursEdited = (data) => {
    data.ticketId = id;

    if (data.endTime <= data.startTime) {
      setShowTimeErrorMessage(true);
      return;
    }
    setMainLoading(true);
    updateTicketHours(data).then(() => {
      getTicketsData()
      getPayrollDetails(ticketData.id).then((payrollDetails) => {
        setPayrollData(payrollDetails);
      })
    });
  };

  // Handle Charge Updates
  const onChargeUpdate = (index, event, prop, isFloat, side) => {
    var updatedValue = event.target.value;
    if (isFloat) {
      if (!updatedValue.endsWith('.')) {
        updatedValue = parseFloat(updatedValue);
      }
      if (isNaN(updatedValue)) {
        updatedValue = 0;
      }
    }
    if (side === 'left') {
      chargesDataLeft[index][prop] = updatedValue;
    } else {
      chargesDataRight[index][prop] = updatedValue;
    }
    forceUpdate();
  }

  // Copy current row on focus in
  const onChargeRowFocusIn = (index, side) => {
    if (!canUpdateTicket) {
      return;
    }
    const charge = side === 'left' ? chargesDataLeft[index] : chargesDataRight[index];
    const chargeToUpdate = {
      rate: charge.rate,
      quantity: charge.quantity,
      uom: charge.uoM
    };
    setChargeToUpdate(chargeToUpdate);
  };

  // Handle Charges update on focus out
  const onChargeRowFocusOut = (index, side) => {
    if (!canUpdateTicket) {
      return;
    }

    const chargeToCheck = side === 'left' ? chargesDataLeft[index] : chargesDataRight[index];
    if (chargeToCheck.rate !== chargeToUpdate.rate || chargeToCheck.quantity !== chargeToUpdate.quantity || chargeToCheck.uoM !== chargeToUpdate.uom) {
      updateTicketSpecification(chargeToCheck).then((resData) => {
        setTicketTotal(resData.ticketTotal);
        getPayrollDetails(ticketData.id).then((payrollDetails) => {
          setPayrollData(payrollDetails);
        })
      });
    }
    setChargeToUpdate(null);
  };

  // EndRegion - UI Handlers

  // Region - Form Setters

  // Setting hourly part of the ticket form
  const setTicketHourlyData = (data) => {
    const hourlyFields = [
      {
        label: "Start time",
        type: "timepicker",
        name: "startTime",
        disabled: !canUpdateTicket,
        required: true,
        value: data.startTime,
        size: 3,
        checkDateTime: () => {
          setShowTimeErrorMessage(false);
        },
      },
      {
        label: "End time",
        type: "timepicker",
        name: "endTime",
        disabled: !canUpdateTicket,
        required: true,
        value: data.endTime,
        checkDateTime: () => {
          setShowTimeErrorMessage(false);
        },
        size: 3,
      }
    ];

    if (!isCustomer) {
      hourlyFields.push({
        label: "Company Hours",
        type: "number",
        name: "companyHours",
        disabled: !canUpdateTicket,
        required: true,
        value: data.companyHours,
        size: 3,
      });
      hourlyFields.push({
        label: "Mileage",
        type: "number",
        name: "mileage",
        required: true,
        disabled: !canUpdateTicket,
        value: data.mileage,
        size: 3,
      });
    }

    hourlyFields.push({
      label: "Description",
      type: "textarea",
      name: "description",
      required: false,
      value: data.description,
      disabled: !canUpdateTicket,
      size: 12,
    });

    setTicketHourlyFields(hourlyFields);
  };

  // EndRegion - Form Setters


  // Region - Signatures

  // Setting ticket preview and opening signature modal
  const getSignature = (isEmployee) => {
    setIsEmployeeSignature(isEmployee);
    getPreviewTicket(id).then((resData) => {
      setPreviewTicketHtml(resData);
      sethtmlPreviewModalOpened(true);
    });
  };

  const signTicketAction = () => {
    if (!isEmployeeSignature) {
      setSignatureModalOpened(true);
      return;
    }

    getProfile().then((result) => {
      if (result.signature) {
        userSignature(result.signature);
        return;
      }

      setSignatureModalOpened(true);
    });
  };

  // Handle user signature action
  const userSignature = (userSignature) => {
    const data = {
      ticketId: id,
      signature: userSignature,
    };
    setMainLoading(true);
    ticketSign(data).then((resData) => getTicketsData());
    setSignatureModalOpened(false);
    sethtmlPreviewModalOpened(false);
  };

  const sendTicketToCustomer = () => {
    setMainLoading(true);
    sendToCustomer(id).then((res) => getTicketsData());
  };

  // Customer signature
  const addCustomerSignature = (customerSignature, customerPrintedName) => {
    const data = {
      ticketId: id,
      printedName: customerPrintedName,
      signature: customerSignature,
    };
    setMainLoading(true);
    customerTicketSign(data).then((resData) => getTicketsData());
    setSignatureModalOpened(false);
    sethtmlPreviewModalOpened(false);
  };

  // Handle signature save action
  const onSignatureSaved = (signature, printedName) => {
    if (isEmployeeSignature) {
      userSignature(signature);
    } else {
      addCustomerSignature(signature, printedName);
    }
  }

  // Reset all ticket signatures
  const resetTicketSignatures = () => {
    setMainLoading(true);
    resetTicketSign(id).then((resData) => {
      getTicketsData();
      setResetSignaturesModalOpened(false);
    });
  };

  // User Signature modal Actions
  const signatureActions = [
    {
      onClick: signTicketAction,
      color: "primary",
      label: "Sign Ticket",
      variant: "contained"
    },
    {
      onClick: () => sethtmlPreviewModalOpened(false),
      color: "default",
      label: "Cancel",
      variant: "outlined",
    }
  ];

  // Reset signature actions
  const resetSignatureActions = [
    {
      onClick: resetTicketSignatures,
      color: "primary",
      label: "Yes",
      variant: "contained",
    },
    {
      onClick: () => setResetSignaturesModalOpened(false),
      color: "default",
      label: "No",
      variant: "outlined",
    },
  ];

  // EndRegion - Signatures

  const handleDownloadTicket = () => {
    setMainLoading(true);
    downloadTicket(id).then(async (resData) => {
      downloadDoc(resData);
      setMainLoading(false);
    });
  };


  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUploadTicket = () => {
    setUploadTicketModal(true);
  };

  const uploadTicket = () => {
    setMainLoading(true);
    uploadFile(selectedFile, `tickets/upload?ticketId=${id}`, "ticketPdf").then((res) => {
      setUploadTicketModal(false);
      getTicketsData();
    });
  };

  const handleCloseUploadTicketModal = () => {
    setUploadTicketModal(false);
  };

  const uploadActions = [
    {
      onClick: uploadTicket,
      color: "primary",
      label: "Upload Ticket",
      variant: "contained",
    },
    {
      onClick: handleCloseUploadTicketModal,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];

  // Region - Payrolls

  // Add Payroll Form fields
  const addPayrollFields = [
    {
      label: "Job Title",
      type: "select",
      name: "jobTitle",
      required: true,
      selectOptions: [
        { label: "Tool Pusher", value: "ToolPusher" },
        { label: "Crew Chief", value: "CrewChief" },
        { label: "Derrick Man", value: "DerrickMan" },
        { label: "Floor Hand", value: "FloorHand" },
        { label: "Other", value: "other" },
      ],
      onChange: (event) => {
        setPayrollEntryName("");
        setEmployeeList(
          allEmployees.filter(
            (employee) =>
              employee.jobTitleDisplay === event.target.innerText &&
              !payrollData.some(
                (payrollData) => payrollData.employeeId === employee.id
              )
          )
        );

        if (event.target.textContent === "Other") {
          setShowPayrollEntryName(true);
        } else {
          setShowPayrollEntryName(false);
        }
      },
      value: "",
      size: 12,
    },
    !showPayrollEntryName
      ? {
        label: "Select Employee",
        type: "select",
        name: "employeeId",
        required: false,
        value: "",
        selectOptions: selectEmployee,
        size: 12,
      }
      : "",
    showPayrollEntryName
      ? {
        label: "Name",
        type: "text",
        name: "name",
        required: false,
        value: payrollEntryName,
        size: 12,
      }
      : "",
  ];

  // Payroll Edit hours fields
  const createPayrollEditFields = (data) => {
    let payrollEditFields;
    if (isRoustabout) {
      payrollEditFields = [
        {
          label: "Roustabout Hours",
          type: "number",
          name: "roustaboutHours",
          required: true,
          value: data.roustaboutHours,
          size: 12,
        },
      ];
    } else if (isYard) {
      payrollEditFields = [
        {
          label: "Yard Hours",
          type: "number",
          name: "yardHours",
          required: true,
          value: data.yardHours,
          size: 12,
        },
      ];
    } else {
      payrollEditFields = [
        {
          label: "Rig Hours",
          type: "number",
          name: "rigHours",
          required: true,
          value: data.rigHours,
          size: 12,
        },
        {
          label: "Travel Hours",
          type: "number",
          name: "travelHours",
          required: true,
          value: data.travelHours,
          size: 12,
        },
      ];
    }

    setPayrollEditFields(payrollEditFields);
  };

  // Handle closing create payroll modal
  const onPayrollCreateModalClosed = () => {
    setCreatePayrollModalOpened(false);
    setPayrollEntryName("");
  };

  // Handle closing edit payroll moodal
  const onPayrollEditModalClosed = () => {
    setEditPayrollModalOpened(false);
  };

  // Handle submitting form for payroll modals
  const onPayrollModalActionConfirm = () => {
    ref.current.submit();
  };

  // Handle update payroll action
  const onPayrollUpdateAction = (updatedRow) => {
    createPayrollEditFields(updatedRow);
    setEmployeeId(updatedRow.employeeId);
    setPayrollId(updatedRow.id);
    setIsEditingPayroll(true);
    setEditPayrollModalOpened(true);
  };

  // Payroll edit form submitted
  const onPayrollEditFormSubmitted = (data) => {
    let reqBody;
    if (isEditingPayroll) {
      data.fieldTicketId = id;
      data.employeeId = employeeId;
      data.id = payrollId;
      updatePayroll(data).then(() => getTicketsData());
      onPayrollEditModalClosed(false);
      return;
    }

    if (data.jobTitle === "other") {
      reqBody = {
        fieldTicketId: id,
        name: data.name,
        jobTitle: data.jobTitle,
      };
    } else {
      reqBody = {
        fieldTicketId: id,
        employeeId: data.employeeId,
        jobTitle: data.jobTitle,
        name: "",
      };
    }
    createNewPayroll(reqBody).then(() => getTicketsData());
    onPayrollCreateModalClosed();
  };

  // Open delete payroll modal
  const onPayrollDeleteAction = (id,name) => {
    setCurrentPayrollIndex(id);
    setCurrentPayrolllName(name);
    setDeletePayrollModalOpened(true);
  };

  // Confirm payroll delete action
  const onPayrollDeleteActionConfirmed = () => {
    deletePayroll(currentPayrollIndex).then(() => getTicketsData());
    setDeletePayrollModalOpened(false);
  };

  // Cancel payroll delete action
  const onPayrollDeleteModalCancel = () => {
    setDeletePayrollModalOpened(false);
  };

  // Payroll create modal actions
  const payrollCreateActions = [
    {
      onClick: onPayrollModalActionConfirm,
      color: "primary",
      label: "Add",
      variant: "contained",
    },
    {
      onClick: onPayrollCreateModalClosed,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];

  // Payroll edit modal  actions
  const payrollEditActions = [
    {
      onClick: onPayrollModalActionConfirm,
      color: "primary",
      label: "Save",
      variant: "contained",
    },
    {
      onClick: onPayrollEditModalClosed,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];

  // Payroll delete modal actions
  const payrollDeleteActions = [
    {
      onClick: onPayrollDeleteActionConfirmed,
      color: "primary",
      label: "Yes",
      variant: "contained",
    },
    {
      onClick: onPayrollDeleteModalCancel,
      color: "default",
      label: "No",
      variant: "outlined",
    },
  ];

  // EndRegion - Payrolls

  return (
    <Container maxWidth={false}>
      <LoadingComponent loading={mainLoading} />
      <Paper
        elevation={3}
        sx={{ p: 4, mb: 4, backgroundColor: "white", borderRadius: "8px" }}
      >
        <Typography
          variant="h5"
          align="center"
          marginBottom={3}
          color={primaryColor}
          gutterBottom
        >
          Ticket Details
        </Typography>
        <Forms
          fields={ticketBasicInfoFields}
          addParams={canUpdateTicket ? ticketUpdateButton : null}
          ref={ref}
          onSubmit={onBasicTicketInfoEdited}
        />
        <Divider sx={{ marginTop: 2 }} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <div>
            <Button
              type="submit"
              variant="contained"
              color="error"
              style={{ marginRight: "5px" }}
              onClick={() => navigate(`/dashboard/tickets`)}
            >
              Close
            </Button>
            {!isCustomer && !hasUserSignature && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginRight: "5px" }}
                onClick={() => getSignature(true)}
              >
                Sign Ticket
              </Button>
            )}

            {hasUserSignature && !hasCustomerSignature && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginRight: "5px" }}
                onClick={() => getSignature(false)}
              >
                Customer Signature
              </Button>
            )}
            {isAdmin && hasUserSignature && !hasCustomerSignature && (
              <Tooltip title={ticketData && ticketData.isSentToCustomer ? `Last sent on ${formatDateTime(ticketData.sentToCustomerOn)}` : "Send ticket to customer"} arrow placement="top">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginRight: "5px" }}
                onClick={() => sendTicketToCustomer()}
              >
                Send to Customer
              </Button>
            </Tooltip>
            )}
            <Divider sx={{ marginTop: 2 }} />
            <div style={{ marginTop: "10px" }}>
              {hasUserSignature && (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginRight: "5px" }}
                  onClick={() => handleDownloadTicket()}
                >
                  Download Ticket
                </Button>
              )}
              {(hasUserSignature && (!hasCustomerSignature || isAdmin)) && (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginRight: "5px" }}
                  onClick={() => handleUploadTicket()}
                >
                  Upload Ticket
                </Button>
              )}
              {hasUserSignature && isAdmin && (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => setResetSignaturesModalOpened(true)}
                >
                  Reset Signatures
                </Button>
              )}
            </div>
          </div>

          <Box
            component="span"
            sx={{ display: "flex", alignItems: "center", fontWeight: "bold" }}
          >
            Total: $ {toCurrencyNumber(ticketTotal)}
          </Box>
        </div>
        <ModalComponent
          open={htmlPreviewModalOpened}
          onClose={() => sethtmlPreviewModalOpened(false)}
          title="Sign ticket"
          actions={signatureActions}
          closeOnOutsideClick={false}
        >
          <div dangerouslySetInnerHTML={{ __html: previewTicketHtml }} />
        </ModalComponent>
        <SignatureModal
          saveAction={onSignatureSaved}
          closeAction={() => setSignatureModalOpened(false)}
          isOpen={signatureModalOpened}
          modalTitle="Set signature"
          showPrintedName={!isEmployeeSignature}
        />
        <ModalComponent
          open={uploadTicketModal}
          onClose={handleCloseUploadTicketModal}
          title={"Upload Signed Ticket"}
          actions={uploadActions}
          closeOnOutsideClick={false}
        >
          <InputLabel
            htmlFor="file-upload"
            sx={{ color: "black", cursor: "pointer" }}
          >
            Choose File
          </InputLabel>
          <Input
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          {selectedFile && <p>Selected File: {selectedFile.name}</p>}
        </ModalComponent>
        <ModalComponent
          open={resetSignaturesModalOpened}
          onClose={() => setResetSignaturesModalOpened(false)}
          title="Reset Ticket Signatures"
          actions={resetSignatureActions}
          closeOnOutsideClick={false}
        >
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <HighlightOffIcon
              style={{ margin: "auto", fontSize: "58px", color: "red" }}
            />
            <Typography
              style={{
                fontSize: "24px",
                textAlign: "center",
                margin: "15px",
              }}
            >
              Are you sure?
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                textAlign: "center",
                color: "grey",
              }}
            >
              Are you sure you want to reset all signatures for the ticket? <br />
              The process cannot be undone.
            </Typography>
          </Box>
        </ModalComponent>
      </Paper>
      <Paper
        elevation={3}
        sx={{ p: 4, mb: 4, backgroundColor: "white", borderRadius: "8px" }}
      >
        <Typography
          variant="h5"
          align="center"
          marginBottom={3}
          color={primaryColor}
          gutterBottom
        >
          Time Specification
        </Typography>
        <Forms
          fields={ticketHourlyFields}
          addParams={canUpdateTicket ? ticketUpdateButton : null}
          ref={ref}
          onSubmit={onTicketHoursEdited}
        />
        {showTimeErrorMessage && (
          <Typography
            variant="body1"
            align="center"
            sx={{ color: "red", mt: 2, fontWeight: "bold" }}
            gutterBottom
          >
            End time should be after Start time
          </Typography>
        )}
      </Paper>
      <Paper
        elevation={3}
        sx={{ p: 4, mb: 4, backgroundColor: "white", borderRadius: "8px" }}
      >
        <Tabs value={selectedTab} onChange={onTabChange}>
          {!isYard && !isCustomer && <StyledTab label="Charges" />}
          <StyledTab label="Payroll" />
        </Tabs>
        {selectedTab === 0 && !isYard && !isCustomer && (
          <div className="tab-no-title ticket-tables">
            <div className="ticket-table-split ticket-table-split-left">
              <Table>
                <TableHead>
                  <TableRow>
                    {chargesColumn.map((column) => (
                      <TableCell
                        key={column.header}
                        align={column.isTotal ? "right" : ""}
                      >
                        {column.header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chargesDataLeft.map((row, index) => (
                    <TableRow key={row.id} onBlur={() => onChargeRowFocusOut(index, 'left')} onFocus={() => onChargeRowFocusIn(index, 'left')} >
                      <TableCell>{row.charge}</TableCell>
                      <TableCell>
                        <Select
                          value={row.uoM}
                          onChange={(event) => onChargeUpdate(index, event, "uoM", false, 'left')}
                          disabled={!row.allowUoMChange || !canUpdateTicket}
                          style={{ minWidth: "100px" }}
                        >
                          <MenuItem value="EA">EA</MenuItem>
                          <MenuItem value="Hourly">Hourly</MenuItem>
                          <MenuItem value="Daily">Daily</MenuItem>
                          <MenuItem value="Gallon">Gallon</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <TextField
                          inputProps={{
                            inputMode: "text",
                            pattern: "[0-9]+(.[0-9])",
                          }}
                          value={row.rate}
                          disabled={!row.allowRateAdjustment || !canUpdateTicket}
                          onChange={(event) => onChargeUpdate(index, event, "rate", true, 'left')}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]+(.[0-9])",
                          }}
                          value={row.quantity}
                          disabled={row.isReadOnly || !canUpdateTicket}
                          onChange={(event) => onChargeUpdate(index, event, "quantity", true, 'left')}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: "right", m: 1, minWidth: "100px" }}>
                        {"$ " + toCurrencyNumber(row.rate * row.quantity)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <div className="ticket-table-split ticket-table-split-right">
              <Table>
                <TableHead>
                  <TableRow>
                    {chargesColumn.map((column) => (
                      <TableCell
                        key={column.header}
                        align={column.isTotal ? "right" : ""}
                      >
                        {column.header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chargesDataRight.map((row, index) => (
                    <TableRow key={row.id} onBlur={() => onChargeRowFocusOut(index, 'right')} onFocus={() => onChargeRowFocusIn(index, 'right')} >
                      <TableCell >{row.charge}</TableCell>
                      <TableCell>
                        <Select
                          value={row.uoM}
                          onChange={(event) => onChargeUpdate(index, event, "uoM", false, 'right')}
                          disabled={!row.allowUoMChange || !canUpdateTicket}
                          style={{ minWidth: "100px" }}
                        >
                          <MenuItem value="EA">EA</MenuItem>
                          <MenuItem value="Hourly">Hourly</MenuItem>
                          <MenuItem value="Daily">Daily</MenuItem>
                          <MenuItem value="Gallon">Gallon</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <TextField
                          inputProps={{
                            inputMode: "text",
                            pattern: "[0-9]+(.[0-9])",
                          }}
                          value={row.rate}
                          disabled={!row.allowRateAdjustment || !canUpdateTicket}
                          onChange={(event) => onChargeUpdate(index, event, "rate", true, 'right')}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]+(.[0-9])",
                          }}
                          value={row.quantity}
                          disabled={row.isReadOnly || !canUpdateTicket}
                          onChange={(event) => onChargeUpdate(index, event, "quantity", true, 'right')}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: "right", m: 1, minWidth: "100px" }}>
                        {"$ " + toCurrencyNumber(row.rate * row.quantity)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        )}
        {(selectedTab === 1 || isYard || isCustomer) && (
          <div className="tab-no-title">
            <TableContainer component={Paper}>
              <Table aria-label="payroll table">
                <TableHead>
                  <TableRow>
                    <TableCell>Labor</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Id</TableCell>
                    {isRoustabout && (
                      <TableCell>Roustabout Hours</TableCell>
                    )}
                    {isYard && <TableCell>Yard Hours</TableCell>}
                    {(!isYard && !isRoustabout) && (
                      <TableCell>Rig Hours</TableCell>
                    )}
                    {(!isYard && !isRoustabout) && (
                      <TableCell>Travel Hours</TableCell>
                    )}
                    <TableCell style={{ textAlign: "end" }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payrollData.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.labor}</TableCell>
                      <TableCell>
                        {row.name.length === 0
                          ? row.employee?.firstName + " " + row.employee?.lastName
                          : row.name}
                      </TableCell>
                      <TableCell>{row.displayEmployeeId}</TableCell>
                      {isRoustabout && (
                        <TableCell>{row.roustaboutHours}</TableCell>
                      )}
                      {isYard && (
                        <TableCell>{row.yardHours}</TableCell>
                      )}
                      {(!isYard && !isRoustabout) && (
                        <TableCell>{row.rigHours}</TableCell>
                      )}
                      {(!isYard && !isRoustabout) && (
                        <TableCell>{row.travelHours}</TableCell>
                      )}
                      <TableCell
                        style={{ textAlign: "end", verticalAlign: "middle" }}
                      >
                        {(isRoustabout || isYard) && canUpdateTicket && (
                          <IconButton
                            onClick={() => onPayrollUpdateAction(row)}
                            aria-label="edit"
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                        {canUpdateTicket && (
                          <IconButton
                            onClick={() => onPayrollDeleteAction(row.id, row.name.length === 0
                              ? row.employee?.firstName + " " + row.employee?.lastName
                              : row.name)}
                            aria-label="delete"
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}

                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <ModalComponent
              open={deletePayrollModalOpened}
              onClose={() => setDeletePayrollModalOpened(false)}
              title="Delete Payroll Entry" 
              actions={payrollDeleteActions}
              closeOnOutsideClick={false}
            >
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <HighlightOffIcon
                  style={{ margin: "auto", fontSize: "58px", color: "red" }}
                />
                <Typography
                  style={{
                    fontSize: "24px",
                    textAlign: "center",
                    margin: "15px",
                  }}
                >
                  Are you sure?
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    textAlign: "center",
                    color: "grey",
                  }}
                >
                  Are you sure you want to delete this payroll entry for {currentPayrollName}? <br />
                  The process cannot be undone.
                </Typography>
              </Box>
            </ModalComponent>
            {canUpdateTicket && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginTop: "10px" }}
                onClick={() => setCreatePayrollModalOpened(true)}
                disabled={
                  isCustomer || (hasUserSignature && hasCustomerSignature)
                }
              >
                Add Payroll Entry
              </Button>
            )}
            <ModalComponent
              open={createPayrollModalOpened}
              onClose={onPayrollCreateModalClosed}
              title={"Add Payroll"}
              actions={payrollCreateActions}
              closeOnOutsideClick={false}
            >
              <Forms
                ref={ref}
                fields={addPayrollFields}
                onSubmit={(data) => onPayrollEditFormSubmitted(data)}
              />
            </ModalComponent>
            <ModalComponent
              open={editPayrollModalOpened}
              onClose={onPayrollEditModalClosed}
              title={"Edit Payroll Hours"}
              actions={payrollEditActions}
              closeOnOutsideClick={false}
            >
              <Forms
                ref={ref}
                fields={payrollEditFields}
                onSubmit={(data) => onPayrollEditFormSubmitted(data)}
              />
            </ModalComponent>
          </div>
        )}
      </Paper>
    </Container>
  );
};
