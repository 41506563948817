// customer-service.js

import {postData} from "./http-service";

// Get all customers
export const getCustomers = async () => {
  try {
    const response = await postData({
      method: "GET",
      path: "customers/list",
      data: {},
      showToaster: false,
    });
    return await response.json();
  } catch (e) {
    return null;
  }
};

// Create a customer
export const createCustomer = async (customerData) => {
  try {
    const response = await postData({
      path: 'customers/create',
      data: customerData,
      toasterMessage: 'Customer successfully created.'
    });
    if (response.ok) {
        return await response.json();
    }
  } catch (error) {
    return null;
  }
};

// Delete a customer
export const deleteCustomer = async (customerId) => {
  try {
    const response = await postData({
      path: "customers/delete?customerId=",
      options: customerId,
      toasterMessage: "Customer successfully removed.",
    });
    if(response.ok){
      return response;
    }
  } catch (error) {
    return null;
  }

};


export const getCustomerById = async (id) => {
    try {
      const response = await postData({
        method: 'GET',
        path: 'customers/',
        data: id,
        options: id,
        showToaster: false,
      });
      if (response.ok) {
          return await response.json();
      }
    } catch (error) {
      return null;
    }
  };

export const updateCustomer = async (customerData) => {
    try {
        const response = await postData({
            path: 'customers/update',
            data: customerData,
            toasterMessage: "Customer updated.",
        });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
};

//Create location of a customer
export const createLocation = async (locationData) => {
  try {
    const response = await postData({
      path: 'customers/add-location',
      data: locationData,
      toasterMessage: "Location created.",
      });
      if (response.ok) {
        return await response.json();
      }
  } catch (error) {
    return null;
  }

};

//Update location of a customer
export const updateLocation = async (locationData) => {
  try {
    const response = await postData({
      path: 'customers/update-location',
      data: locationData,
      toasterMessage: "Location updated.",
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null
  }
};

//Delete location of a customer
export const deleteLocation = async (locationId) => {
  try {
    const response = await postData({
      path: 'customers/delete-location?customerLocationId=',
      data: locationId,
      options: locationId,
      toasterMessage: "Location removed successfully."
    });

    if(response.ok) {
      return await response.json();
    }
  } catch (error)
  {
    return null
  }
};

//Create contact
export const createContact = async (contactData) => {
  try{
    const response = await postData({
      path: 'customers/add-contact',
      data: contactData,
      toasterMessage: "Contact created.",
    });

    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null
  }
};

//Update contact
export const updateContact = async (contactData) => {
  try{
    const response = await postData({
      path: 'customers/update-contact',
      data: contactData,
      toasterMessage: "Contact updated.",
    });

    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null
  }
};

//Delete contact
export const deleteContact = async (contactId) => {
  try{
    const response = await postData({
      path: 'customers/delete-contact?contactId=',
      data: contactId,
      options: contactId,
      toasterMessage: "Contact removed successfully."
    });

    if (response.ok) {
      return await response.json()
    }
  } catch (error) {
    return null
  }

}

export const inviteContactLogin = async (customerContactId) => {
  try{
    const redirectUrl = `${location.origin}/contact-login`;
    const resetData = {customerContactId, redirectUrl};
    const response = await postData({
        path: 'customers/add-login',
        data: resetData,
        toasterMessage: `Invitation sent.`,
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
}

// Re-send an already sent invite
export const resendContactInvite = async (customerContactId) => {
  try{
    const redirectUrl = `${location.origin}/contact-login`;
    const resetData = {customerContactId, redirectUrl};
    const response = await postData({
        path: 'customers/resend-invite',
        data: resetData,
        toasterMessage: `Invitation sent.`,
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
}

//Reset contact password
export const resetContactPassword = async (passwordBody) => {
  try{
    const response = await postData({
      path: 'customers/reset-password',
      data: passwordBody,
      toastrMessage: "Password reset."
    });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
}

//Delete login
export const deleteContactLogin = async (contactId) => {
  try {
    const response = await postData({
    path: 'customers/delete-login?customerContactId=',
    data: contactId,
    options: contactId,
    toastrMessage: "Contact login removed."
    })
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    return null;
  }
}

//Get locations by customer id

export const getCustomersLocationById = async (customerId) => {
  try {
    const response = await postData({
      method: "GET",
      path: "customers/location-list/",
      options: customerId,
      showToaster: false,
    });
    return await response.json();
  } catch (e) {
    return [];
  }
};
