import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../hooks/useAuth";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import BadgeIcon from "@mui/icons-material/Badge";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ReceiptIcon from "@mui/icons-material/Receipt";
import HomeIcon from "@mui/icons-material/Home";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import {useLocation, useNavigate} from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PeopleIcon from "@mui/icons-material/People";
import { Avatar, Menu,
  MenuItem, } from "@mui/material";
  import MoreVertIcon from '@mui/icons-material/MoreVert';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  overflowX: "hidden",
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme) => ({
  overflowX: "hidden",
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const createIcon = (index) => {
  switch (index) {
    case 0:
      return <HomeIcon />;
    case 1:
      return <AccountBoxIcon />;
    case 2:
      return <BadgeIcon />;
    case 3:
      return <CalendarMonthIcon />;
    case 4:
      return <AccountBoxIcon />;
    case 5:
      return <SettingsIcon />;
    case 6:
      return <ReceiptIcon />;
    case 7:
      return <SupportAgentIcon />;
    case 8:
      return <PeopleIcon />;
    case 9:
      return <AccountBoxIcon />;
    case 10:
      return <RequestQuoteIcon />;
    default: return <HomeIcon />;
  }
};

export const AppBarSidebar = ({ pages }) => {
  const { name } = useAuth();
  const [ open, setOpen ] = useState(true);
  const [ currentPickedIndex, setCurrentPickedIndex] = useState(0);
  const location = useLocation();
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleDrawerOpen = () => {
    if (open){
      setOpen(false);
    } else{
      setOpen(true);
    }
  };

  const navigate = useNavigate();
  const { logout } = useAuth();

  const navigateTo = (path, index) => {
    setCurrentPickedIndex(index);
    if (path) {
      navigate(`/${path}`);
    }
  };

  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes('home')) {
      setCurrentPickedIndex(0);
    } else if (pathname.includes('tickets')) {
      setCurrentPickedIndex(1);
    } else if (pathname.includes('customer')) {
      setCurrentPickedIndex(2);
    } else if (pathname.includes('scheduling')) {
      setCurrentPickedIndex(3);
    } else if (pathname.includes('equipment')) {
      setCurrentPickedIndex(4);
    } else if(pathname.includes('settings')) {
      setCurrentPickedIndex(5);
    } else if (pathname.includes('invoices')) {
      setCurrentPickedIndex(6);
    } else if (pathname.includes('employees')) {
      setCurrentPickedIndex(7);
    } else if (pathname.includes('users')) {
      setCurrentPickedIndex(8);
    } else if (pathname.includes('profile')) {
      setCurrentPickedIndex(9);
    } else if (pathname.includes('payrolls')) {
      setCurrentPickedIndex(10);
    } else {
      setCurrentPickedIndex(0);
    }
  }, [location])

  return (
      <div>
         <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#ededed', color: 'black' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
            }}
          >
            <MenuIcon />
          </IconButton>
          <img alt="Logo" src="/ca_logo.png" style={{ height: '50px', width: 'auto' }} />
          <>
            <Typography variant="h6" noWrap component="div" sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <Avatar sx={{ bgcolor: '#cf1c31', marginRight: 1 }}>{name}</Avatar>
            </Typography>
            <IconButton
              color="inherit"
              onClick={handleMenuOpen}
              sx={{
                marginLeft: 2,
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </>
        </Toolbar>
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => {
            navigate(`/dashboard/profile`);
            handleMenuClose();
          }}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {
            logout();
            handleMenuClose();
          }}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>
      </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader />
          <List>
            {pages.map((page) => {
                return <>
                <ListItem
                    key={page.label}
                    disablePadding
                    sx={{ display: "block" }}
                >
                  <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => {
                        navigateTo(page.path, page.index);
                      }}
                  >
                    <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                        style={{color: currentPickedIndex === page.index ? '#cf1c31' : 'initial'}}
                    >
                      {createIcon(page.index)}
                    </ListItemIcon>
                    <ListItemText
                        primary={page.label}
                        sx={{ opacity: open ? 1 : 0 }}
                        style={{color: currentPickedIndex === page.index ? '#cf1c31' : 'initial'}}
                    />
                  </ListItemButton>
                </ListItem>
                {page.divider && (
                  <Divider />
                )}
                </>
              })}
          </List>
          {open && <footer style={{color: "gray", position: 'absolute', bottom: 0, right: 0, left: 0}}>
            <p style={{fontSize: 14, textAlign: "center", color: "darkgray", fontFamily: "Arial, sans-serif" }}>Powered by<br/><a style={{textDecoration: "none", color: "black" }} href="https://www.readydev.tech" target="_blank">ReadyDev Technologies</a></p>
          </footer>}
        </Drawer>
      </div>
  );
};
