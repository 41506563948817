import { postData } from "./http-service";

export const getAllUsers = async () => {
    try {
        const response = await postData({method: "GET", path: "users/list", showToaster: false});
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}

export const createUser = async (userData) => {
    try {
        const response = await postData({
            path: 'users/create',
            data: userData,
            toasterMessage: "User has been created"
        });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}

export const deleteUser = async (userId) => {
    try {
        const response = await postData({path: "users/delete?userId=", options: userId, toasterMessage: "User Successfully Removed!"});
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}

export const resetPassword = async (resetPasswordData) => {
    try {
        const response = await postData({
            path: 'users/reset-password',
            data: resetPasswordData,
            toasterMessage: "User password reset successfully."
        });
        if(response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}

export const updateUser = async (updateUserData) => {
    try {
        const response = await postData({
            path: 'users/update',
            data: updateUserData,
            toasterMessage: "User successfully updated."
        });
        if(response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}