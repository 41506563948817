import CustomTable from "../shared/table/Table";
import ModalComponent from "../shared/Modal";
import { useState, useRef, useEffect } from "react";
import { Forms } from "../shared/forms/forms";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Box from "@mui/material/Box";
import {
    getCustomers,
    createCustomer,
    deleteCustomer,
  } from "../services/customer-service";
import { usStates } from "../environments/environment";

  const columns = [
    { accessorKey: 'name', header: 'Name' }, {accessorKey: 'netTerm', header: 'Net Term'}, { accessorKey: 'city', header: 'City'}
  ];

const defaultCustomer = [
    {
        label:'Customer Name',
        type:'text',
        name:'name',
        required: true,
        size:6
    },
    {
        label:'Address',
        type:'text',
        name:'address',
        required: true,
        size:6
    },
    {
        label:'City',
        type:'text',
        name:'city',
        required: true,
        size:4
    },
    {
        label: 'State',
        type: 'autocomplete',
        name: 'state',
        value: "Texas",
        required: true,
        size: 4,
        selectOptions: usStates,
    },
    {
        label:'Zip code',
        type:'text',
        name:'zip',
        required: true,
        size:4
    },
    {
        label:'Net Term',
        type:'number',
        name:'netTerm',
        required: true,
        size:6
    },
    {
        label: 'Send Emails to',
        type: 'text',
        name: 'sendInvoiceTo',
        required: true,
        email: true,
        size: 6,
    },

]

export const CustomersPage = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [currentCustomerId, setCurrentCustomerId] = useState(0);
    const [data, setData] = useState([]);
    const [fields, setFields] = useState(defaultCustomer);
    const navigate = useNavigate();
    const ref = useRef();
    const [filterByName, setFilterByName] = useState(false); // Add filterByName state
    // ... other state variables and functions

    // Toggle filterByName state
    const handleFilterByName = () => {
      setFilterByName(!filterByName);
    };

    const getAllCustomers = () => {
        getCustomers().then(response => setData(response));
      };

    useEffect(() => {
        getAllCustomers();
      }, []);


    const handleCreateCustomer = (customerData) => {

        const requestBody = {
            name: customerData.name,
            address: customerData.address,
            city: customerData.city,
            zip: customerData.zip,
            state: customerData.state,
            netTerm: customerData.netTerm,
            sendInvoiceTo: customerData.sendInvoiceTo
        }

        createCustomer(requestBody).then(() => {
            handleCloseModal();
            getAllCustomers()
        })

    };

    const onFormSubmit = data => {
        handleCreateCustomer(data);
    }

    const handleAddAction = () => {
        setFields(defaultCustomer);
        setModalOpen(true);
    };

    const handleEditAction = (customerData) => {
        navigate(`/dashboard/edit-customer/${customerData.data.id}`);
    };

    const handleDeleteCustomer = (customerId) => {
        deleteCustomer(customerId).then(() => {
            getAllCustomers();
            setDeleteModalOpen(false);
        })
      };

    const handleDeleteAction = ({data}) => {
        const customerId = data.id;
        setCurrentCustomerId(customerId);
        setDeleteModalOpen(true);
    };

    const handleOnRowClick = () => {};

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleConfirmAction = () => {
        ref.current.submit();
    };

    const handleDeleteConfirmAction = () => {
        handleDeleteCustomer(currentCustomerId);
    };

    const handleDeleteCloseModal = () => {
        setDeleteModalOpen(false);
    };

    const actions = [
        {
            onClick: handleConfirmAction,
            color: 'primary',
            label: 'Confirm',
            variant: 'contained'
        },
        {
            onClick: handleCloseModal,
            color: 'default',
            label: 'Cancel',
            variant: 'outlined'
        },
    ];

    const deleteActions = [
        {
            onClick: handleDeleteConfirmAction,
            color: 'primary',
            label: 'Confirm',
            variant: 'contained'
        },
        {
            onClick: handleDeleteCloseModal,
            color: 'default',
            label: 'Cancel',
            variant: 'outlined'
        },
    ];

  return (
    <Container maxWidth>
        <CustomTable
        tableName="Customers"
        columns={columns}
        data={data}
        rowsPerPageOptions={[10]}
        showDelete={true}
        showEdit={true}
        onAdd={handleAddAction}
        onEdit={(customerData) => handleEditAction(customerData)}
        onDelete={handleDeleteAction}
        onRowClick={handleOnRowClick}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        />
        <ModalComponent
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            title="Create Customer"
            actions={actions}
            closeOnOutsideClick={false}
        >
        <Forms
            ref={ref}
            fields={fields}
            onSubmit={data => onFormSubmit(data)}
        />
        </ModalComponent>
        <ModalComponent
            open={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            title='Delete Customer'
            actions={deleteActions}
            closeOnOutsideClick={false}
        >
            <Box style={{display: "flex", flexDirection:"column"}}>
                <HighlightOffIcon style={{margin:"auto", fontSize:"58px", color:"red"}}/>
                <Typography style={{fontSize:"24px", textAlign:"center", margin:"15px"}}>Are you sure?</Typography>
                <Typography style={{fontSize:"14px", textAlign:"center", color:"grey"}}>
                    Do you really want to delete this customer? <br />The process cannot be undone.
                </Typography>
            </Box>
        </ModalComponent>
    </Container>
  );
};
