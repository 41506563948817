import {baseUrl} from "../environments/environment";
import dayjs from "dayjs";

let utc = require('dayjs/plugin/utc')
dayjs.extend(utc);

const getTokenDecrypted = (token) => {
    return atob(token.split(".")[1]);
};

const isTokenValid = (token) => {
    const tokenDecrypted = JSON.parse(getTokenDecrypted(token));
    const tokenValidationDate = dayjs.unix(tokenDecrypted.exp);
    const utcNow = dayjs.utc();
    return utcNow.isBefore(tokenValidationDate);
};

const tryRefreshToken = async(token, refreshToken) => {
    const url = `${baseUrl}/api/authentication/refresh-token`;
    const request = {
        method: "POST",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
            token: token,
            refreshToken: refreshToken
        })
    }
    const response = await fetch(url, request);
    if (response.ok) {
        const responseJson = await response.json();
        if (responseJson['isSuccess']){
            localStorage.setItem("refreshToken", JSON.stringify(responseJson.user.refreshToken));
            localStorage.setItem("token", JSON.stringify(responseJson.user.token));
            return responseJson.user.token;
        }
    }
    return null;
};

export const getToken = async () => {
    const tokenStorage = localStorage.getItem("token");
    const refreshTokenStorage = localStorage.getItem("refreshToken");

    if (!tokenStorage || !refreshTokenStorage){
        return null;
    }

    const token = JSON.parse(tokenStorage);

    if (isTokenValid(token)){
        return token;
    }

    const refreshToken = JSON.parse(refreshTokenStorage);
    return await tryRefreshToken(token, refreshToken);
};
