import * as React from "react";
import { currentMode } from "../environments/environment";
import { ForgotPasswordRemote } from "../components/forgotPasswordRemote/forgotPasswordRemote";
import { ForgotPasswordLocal } from "../components/forgotPasswordLocal/forgotPasswordLocal";

export const ForgotPasswordPage = () => {

    if (currentMode === 'remote') {
        return <ForgotPasswordRemote/>;
    } else {
        return <ForgotPasswordLocal/>
    }
};
