import { useState } from "react";
import { getAllCharges, updateCharge } from "../services/charges-service";
import CustomTable from "../shared/table/Table";
import { useEffect } from "react";
import { Checkbox } from "@mui/material";
import ModalComponent from "../shared/Modal";
import { Forms } from "../shared/forms/forms";
import { useRef } from "react";


const defaultCharge = [
    {
        label: "Name",
        type: "text",
        name: "name",
        required: true,
        email: false,
        value: "",
        size: 4
    },
    {
        label: "Unit of Measure",
        type: "text",
        name: "uoM",
        required: true,
        selectOptions: [
            { label: "EA", value: "EA" },
            { label: "Hourly", value: "Hourly" },
            { label: "Daily", value: "Daily" },
            { label: "Gallon", value: "Gallon" }
        ],
        email: false,
        value: "",
        size: 4
    },
    {
        label: "Default Rate",
        type: "number",
        name: "defaultRate",
        required: true,
        email: false,
        value: ""
    },
    {
        label: "Is Rig Specific",
        type: "checkbox",
        name: "isRigSpecific",
        value: false
    }
]

export const DefaultChargesPage = () => {

    const [chargeList, setChargeList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [fields, setFields] = useState(defaultCharge);
    const [currentChargeIndex, setCurrentChargeIndex] = useState([]);

    const ref = useRef();

    const getCharges = () => {
        getAllCharges().then((resData) => {
            setChargeList(resData);
        });
    };

    const handleConfirmAction = () => {
        ref.current.submit();
    }

    const handleCloseModal = () => {
        setModalOpen(false);
    }

    const actions = [
        {
          onClick: handleConfirmAction,
          color: "primary",
          label: "Confirm",
          variant: "contained",
        },
        {
          onClick: handleCloseModal,
          color: "default",
          label: "Cancel",
          variant: "outlined",
        },
    ];

    const handleEdit = (data) => {
        setCharge(data);
        setModalOpen(true);
    }

    const setCharge = (charge) => {
        const chargeData = charge.data;
        setCurrentChargeIndex(chargeData.id);

        const chargeDataUpdate = [
            {
                label: "Order",
                type: "number",
                name: "order",
                required: true,
                email: false,
                value: chargeData.order,
                size: 6
            },
            {
                label: "Name",
                type: "text",
                name: "name",
                required: true,
                email: false,
                value: chargeData.name,
                size: 6
            },
            {
                label: "Unit of Measure",
                type: "select",
                name: "uoM",
                required: true,
                email: false,
                selectOptions: [
                    { label: "EA", value: "EA" },
                    { label: "Hourly", value: "Hourly" },
                    { label: "Daily", value: "Daily" },
                    { label: "Gallon", value: "Gallon" }
                ],
                value: chargeData.uoM,
                size: 6
            },
            {
                label: "Default Rate",
                type: "number",
                name: "defaultRate",
                required: true,
                email: false,
                value: chargeData.defaultRate,
                size: 6
            },
            {
                label: "Is Rig Specific",
                type: "checkbox",
                name: "isRigSpecific",
                value: chargeData.isRigSpecific,
                size: 6
            },
            {
                label: "Include in Ticket Specification",
                type: "checkbox",
                name: "includeInTicketSpecs",
                value: chargeData.includeInTicketSpecs,
                size: 6
            },
            {
                label: "Allow UoM Change",
                type: "checkbox",
                name: "allowUoMChange",
                value: chargeData.allowUoMChange,
                size: 6
            },
            {
                label: "Allow Rate Adjustment",
                type: "checkbox",
                name: "allowRateAdjustment",
                value: chargeData.allowRateAdjustment,
                size: 6
            }
        ];
        setFields(chargeDataUpdate);
    }

    useEffect(() => {
        getCharges();
    }, []);

    const columns = [
        {accessorKey: 'order', header: 'Order'},
        {accessorKey: 'name', header: 'Name'},
        {accessorKey: 'uoM', header: 'UoM'},
        {accessorKey: 'defaultRate', header: 'Default Rate'},
        {
            accessorKey: 'isRigSpecific',
            header: 'Rig Specific',
            Cell: (params) => {
                return (
                    <Checkbox
                        checked = {params.row.original.isRigSpecific}
                        color = 'primary'
                        disabled
                    />
                );
            }
        },
    ]

    const onFormSubmit = (data) => {
        const requestBody = {
            id: currentChargeIndex,
            order: data.order,
            name: data.name,
            uoM: data.uoM,
            defaultRate: data.defaultRate,
            isRigSpecific: data.isRigSpecific,
            includeInTicketSpecs: data.includeInTicketSpecs,
            allowUoMChange: data.allowUoMChange,
            allowRateAdjustment: data.allowRateAdjustment
        };

        updateCharge(requestBody).then(() => {
            handleCloseModal();
            getCharges();
        });
    }

    return(
        <>
            <CustomTable
                tableName="Default Charges"
                columns={columns}
                data={chargeList}
                rowsPerPageOptions={10}
                showDelete={false}
                showEdit={true}
                showCreateButton={false}
                onEdit={handleEdit}
            />

            <ModalComponent
                    open={modalOpen}
                    onClose={handleCloseModal}
                    title="Edit Charge"
                    actions={actions}
                    closeOnOutsideClick={false}
                >

                <Forms
                    ref={ref}
                    fields={fields}
                    onSubmit={(data) => onFormSubmit(data)}
                />

            </ModalComponent>
        </>
    )
}
