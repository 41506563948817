import {
  TextField,
  Button,
  Stack,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Slider,
  Typography,
  Radio,
  RadioGroup,
  FormHelperText,
  FormLabel,
  FormControl,
  Switch,
  InputLabel,
  Autocomplete
} from "@mui/material";
import Select from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@material-ui/core/Grid";
import { Controller, useForm } from "react-hook-form";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enLocale from "date-fns/locale/en-US";
import InputMask from "react-input-mask";
import { filterSelect } from "../../utils/filterSelect";

export const Forms = forwardRef(
  ({ fields, addParams, CancelBtn, onSubmit, onInputChange }, ref) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      control,
      onChange,
    } = useForm();

    const formRef = useRef(null);
    useImperativeHandle(ref, () => ({
      submit() {
        formRef.current.click();
      },
    }));

    const SelectMenuProps = {
      PaperProps: {
        style: {
          maxHeight: 48 * 4.5 + 8,
          width: 250,
        },
      },
    };

    fields.map((item) => {
      const validation = {
        validation: {},
      };


      if (item.required) {
        validation.validation.required = `${item.label} is a required field`;
      }

      if (item.email) {
        validation.validation.pattern = {
          value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
          message: "Invalid email pattern format",
        };
      }

      if(item.lowercaseLetterRequired) {
        validation.validation.pattern = {
          value: /[a-z]/,
          message: "Password must contain at least one lowercase letter",
        };
      }

      if(item.numbersOnly) {
        validation.validation.pattern = {
          value: /^\d+(\.\d{1,2})?$/,
          message: "Invalid input",
        };
      }

      if (item.type === "number" && item.mask === "phone") {
        validation.validation.pattern = {
          value: /^\(\d{3}\) \d{3}-\d{4}$/,
          message: "Invalid phone number format (xxx) xxx-xxxx",
        };
      }

      if (item.minLength != null) {
        validation.validation.minLength = {
          value: item.minLength,
          message: `${item.label} must be at least ${item.minLength} characters`,
        };
      }

      if (item.maxLength != null) {
        validation.validation.maxLength = {
          value: item.maxLength,
          message: `${item.label} must be no larger ${item.maxLength} characters`,
        };
      }

      if (item.readOnly) {
        validation.validation.readOnly = true;
      }

      if (item.required) {
        Object.assign(item, validation);
      }
    });

    return (
      <>
        {addParams?.formName && <h1>{addParams.formName}</h1>}

        <form
          onSubmit={handleSubmit((data) => {
            onSubmit(data);
          })}
        >
          <input type="submit" style={{ display: "none" }} ref={formRef} />
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="center"
            spacing={2}
          >
            {fields.map((i, item) => {
              if (
                i.type === "text" ||
                i.type === "password" ||
                i.type === "number"
              ) {
                if (i.mask) {
                  return (
                    <Grid key={item} item xs={i.size}>
                      <Controller
                        name={i.name}
                        control={control}
                        defaultValue={i.value ?? ""}
                        rules={{ ...i.validation }}
                        render={({ field }) => (
                          <InputMask
                            mask={i.masked}
                            maskChar=" "
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            value={field.value}
                          >
                            {() => (
                              <TextField
                                disabled={i.disabled}
                                fullWidth
                                label={i.label}
                                type={i.type}
                                error={validateBoolean(
                                  errors?.[i.name]?.message
                                )}
                                helperText={existingError(
                                  errors?.[i.name]?.message
                                )}
                              />
                            )}
                          </InputMask>
                        )}
                      />
                    </Grid>
                  );
                } if (i.onChange) {
                  return (
                      <Grid key={item} item xs={i.size}>
                        <TextField
                            disabled={i.disabled}
                            fullWidth
                            label={i.label}
                            type={i.type}
                            onChange={i.onChange}
                            defaultValue={i.value ?? ""}
                            {...register(`${i.name}`, { ...i.validation })}
                            error={validateBoolean(errors?.[i.name]?.message)}
                            helperText={existingError(errors?.[i.name]?.message)}
                            onBlur={i.onBlur}
                            InputProps={{
                              readOnly: i.readOnly || false,
                            }}
                        />
                      </Grid>
                  );
                } else {
                  return (
                    <Grid key={item} item xs={i.size}>
                      <TextField
                        disabled={i.disabled}
                        fullWidth
                        label={i.label}
                        type={i.type}
                        defaultValue={i.value ?? ""}
                        {...register(`${i.name}`, { ...i.validation })}
                        error={validateBoolean(errors?.[i.name]?.message)}
                        helperText={existingError(errors?.[i.name]?.message)}
                        onBlur={i.onBlur}
                        InputProps={{
                          readOnly: i.readOnly || false,
                        }}
                      />
                    </Grid>
                  );
                }
              }

              if (i.type === "select"){
                return (
                  <Grid item xs={i.size} key={item}>
                    <Controller
                      name={i.name}
                      control={control}
                      defaultValue={i.value ?? null}
                      rules={{ ...i.validation }}
                      render={({field: { value }}) => (
                        <TextField
                          select
                          disabled={i.disabled}
                          fullWidth
                          label={i.label}
                          value={value}
                          {...register(`${i.name}`, { ...i.validation })}
                          error={validateBoolean(errors?.[i.name]?.message)}
                          helperText={existingError(errors?.[i.name]?.message)}
                          InputProps={{
                            readOnly: i.readOnly || false,
                          }}
                        >
                          {i.selectOptions.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              onClick={i.onChange}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}>
                    </Controller>
                  </Grid>
                );
              }
                if (i.type === "textarea") {
                  return (
                    <Grid item xs={i.size} key={item}>
                      <TextField
                        multiline
                        rows={4} // Set the number of rows you want
                        disabled={i.disabled}
                        fullWidth
                        label={i.label}
                        defaultValue={i.value ?? ""}
                        {...register(`${i.name}`, { ...i.validation })}
                        error={validateBoolean(errors?.[i.name]?.message)}
                        helperText={existingError(errors?.[i.name]?.message)}
                      />
                    </Grid>
                  );
                }
              //AutoComplete select
              if (i.type === "autocomplete") {
                return (
                  <Grid item xs={i.size} key={item}>
                    <Controller
                      name={i.name}
                      control={control}
                      defaultValue={i.value ?? null}
                      rules={{ ...i.validation }}
                      render={({field: { value, onChange }}) => (
                        <Autocomplete
                            onChange={(event, optionSelected) => {
                              if(optionSelected === null){
                                onChange("")}
                              else{
                                onChange(optionSelected.value);
                                if (i.onChange){
                                  i.onChange(optionSelected.value);
                                }
                              }
                            }}
                            disabled={i.disabled}
                            options={i.selectOptions}
                            isOptionEqualToValue={(opt, value) => opt.value === value || opt.value === value.value}
                            value={value ? i.selectOptions.find(
                              (option) => option.value === value
                            ) : null}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                value={value}
                                label={i.label}
                                error={validateBoolean(errors?.[i.name]?.message)}
                                helperText={existingError(errors?.[i.name]?.message)}
                              />
                            )}
                        />
                      )}>
                    </Controller>
                  </Grid>
                );
              }

              // Multiple select
              if (i.type === "multiSelect") {
                return (
                  <Grid item xs={i.size} key={item} >
                    <FormControl sx={{ width: "100%" }} error={validateBoolean(errors?.[i.name]?.message)}  >
                    <InputLabel id="label-id">{i.label}</InputLabel>
                    <Controller
                      rules={{
                        required: i.required,
                      }}
                      control={control}
                      name={i.name}
                      defaultValue={i.value ?? []}
                      render={({field: { value, onChange }}) => (
                        <Select 
                            multiple
                            disabled={i.disabled}
                            onChange={(event) => {
                              onChange(event.target.value);
                              if (i.onChange){
                                i.onChange(event.target.value);
                              }
                              filterSelect(event.target.value,i);
                            }}
                            labelId="label-id"
                            label={i.label}
                            value={value}
                            >
                            {i.selectOptions.map((option) => (
                              filterSelect(value,i),
                              <MenuItem
                                key={option.value}
                                value={option.value}
                                disabled={option.disabled}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                        </Select>
                      )}>
                    </Controller>
                    <FormHelperText>
                    &nbsp;
                    </FormHelperText>
                    </FormControl>
                  </Grid>
                );
              }

              // Checkbox element for true false inputs

              if (i.type === "checkbox")
                return (
                  <Grid item xs={i.size} key={item}>
                    {" "}
                    <FormControlLabel
                      disabled={i.disabled}
                      label={i.label}
                      control={
                        <Checkbox
                          defaultChecked={i.value ?? false}
                          {...register(`${i.name}`)}
                        />
                      }
                    />
                  </Grid>
                );

              // Slider element

              if (i.type === "slider")
                return (
                  <Grid key={item} item xs={i.size}>
                    <Stack
                      spacing={2}
                      direction="column"
                      sx={{ mb: 1 }}
                      alignItems="center"
                    >
                      <Typography id={i.label}>{i.label}</Typography>
                      <Slider
                        defaultValue={i.value ?? 0}
                        {...register(`${i.name}`)}
                        disabled={i.disabled}
                      />
                    </Stack>
                  </Grid>
                );

              // Radio element

              if (i.type === "radio")
                return (
                  <Grid key={item} xs={i.size} item>
                    <FormControl>
                      <FormLabel>{i.label}</FormLabel>
                      <Controller
                        rules={{
                          required: i.required,
                        }}
                        control={control}
                        name={i.name}
                        defaultValue={i.value}
                        render={({ field }) => {
                          return (
                            <RadioGroup {...field}>
                              {i.radioOptions.map((option, index) => (
                                <FormControlLabel
                                  key={index}
                                  value={option.value}
                                  control={<Radio />}
                                  label={option.label}
                                  disabled={i.disabled}
                                />
                              ))}
                              <FormHelperText error>
                                {requiredError(errors?.[i.name])}
                              </FormHelperText>
                            </RadioGroup>
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                );

              // On/Off Switch

              if (i.type === "switch")
                return (
                  <Grid key={item} xs={i.size} item>
                    <FormControlLabel
                      control={<Switch defaultChecked={i.value ?? false} />}
                      label={i.label}
                      {...register(`${i.name}`)}
                      disabled={i.disabled}
                    />
                  </Grid>
                );

              // Date Time Picker

              if (i.type === "datetime")
                return (
                  <Grid
                    key={item}
                    xs={i.size}
                    style={{ height: "94px", width: "100%" }}
                    item
                  >
                    <FormControl fullWidth error={errors[i.name]}>
                      <Controller
                        control={control}
                        name={i.name}
                        isInvalid={!!errors[i.name]}
                        defaultValue={i.value ? new Date(i.value) : null}
                        rules={{
                          required: i.required,
                        }}
                        render={({ field: { onChange } }) => (
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={enLocale}
                          >
                            <DatePicker
                              fullWidth
                              disabled={i.disabled}
                              label={i.label}
                              defaultValue={i.value ? new Date(i.value) : null}
                              onChange={(event) => {
                                onChange(event);
                              }}
                              renderInput={(params) => (
                                <TextField error={errors[i.name]} {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText error>
                        {requiredError(errors?.[i.name])}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                );

              if (i.type === "timepicker")
                return (
                  <Grid
                    key={item}
                    xs={i.size}
                    style={{ height: "94px", width: "100%" }}
                    item
                  >
                    <FormControl fullWidth error={errors[i.name]}>
                    <Controller
                      control={control}
                      name={i.name}
                      isInvalid={!!errors[i.name]}
                      defaultValue={
                        i.value ? new Date(i.value.replace('T', ' ')) : ""
                      }
                      rules={{
                        required: i.required,
                      }}
                      render={({ field: { value, onChange } }) => (  // Destructure value and onChange

                      <LocalizationProvider >
                        <MobileTimePicker
                          fullWidth
                          disabled={i.disabled}
                          label={i.label}
                          defaultValue={i.value ? new Date(i.value.replace('T', ' ')) : null}
                          onChange={(event) => {
                              i.checkDateTime ? i.checkDateTime() : null;
                             onChange(new Date(event));
                          }}
                          renderInput={(params) => (
                            <TextField error={errors[i.name]} {...params} />
                          )}
                          minutesStep={15}
                          skipDisabled
                          timeSteps={{minutes: 15}}
                        />
                    </LocalizationProvider>
                      )}
                    />

                      <FormHelperText error>
                        {requiredError(errors?.[i.name])}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                );
            })}
            {(addParams?.buttonName || CancelBtn?.buttonName) && (
              <Grid
                container
                xs={12}
                justifyContent="center"
                >
                {addParams?.buttonName && (
                  <Button type="submit" variant="contained" color="primary" disabled={addParams.disabled}>
                    {addParams.buttonName}
                  </Button>
                )}
                {CancelBtn?.buttonName && (
                  <Button type="submit" variant="contained" color="secondary">
                    {CancelBtn.buttonName}
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </form>
      </>
    );
  }
);

function getFormattedDate(d) {
  const date = new Date(d);
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  return month + "/" + day + "/" + year;
}

function existingError(data) {
  return data != null ? data : " ";
}

function validateBoolean(data) {
  return data != null;
}

function requiredError(data) {
  return data != null ? "This is a required field" : "";
}


