import { createBrowserRouter, createRoutesFromElements, defer, Route } from "react-router-dom";
import { LoginPage } from "./pages/login";
import { HomePage } from "./pages/home";
import { ProfilePage } from "./pages/profile";
import { SettingsPage } from "./pages/settings";
import { ProtectedLayout } from "./layouts/ProtectedLayout";
import { BaseLayout } from "./layouts/BaseLayout";
import "./styles.css";
import { AuthLayout } from "./layouts/AuthLayout";
import { ForgotPasswordPage } from "./pages/forgot-password";
import { ResetPasswordPage } from "./pages/reset-password";
import { EmployeesPage } from "./pages/employees";
import { InvoicesPage } from "./pages/invoices";
import { SchedulingPage } from "./pages/scheduling";
import { EquipmentPage } from "./pages/equipment";
import { TicketsPage } from "./pages/tickets";
import { CustomersPage } from "./pages/customers";
import { ContactLoginPage } from "./pages/contact-login";
import { UsersPage } from "./pages/users-page";
import { EditTicket } from "./components/tickets/editTicket";
import { EditCustomerPage } from "./pages/edit-customer";
import { PayrollsPage } from "./pages/payrolls";
import { getToken } from "./services/token-manager";

const getTokenPromise = () =>
    new Promise((resolve) =>
        setTimeout(async () => {
            const userToken = await getToken()
            resolve(userToken);
        }, 1000)
    );

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            element={<AuthLayout />}
            loader={() => defer({ userTokenPromise: getTokenPromise() })}
        >
            <Route element={<BaseLayout />}>
                <Route path="/" element={<LoginPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage/>} />
                <Route path="/reset-password" element={<ResetPasswordPage/>} />
                <Route path="/contact-login" element={<ContactLoginPage/>} />
            </Route>

            <Route path="/dashboard" element={<ProtectedLayout />}>
                <Route path="homepage" element={<HomePage />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="employees" element={<EmployeesPage />} />
                <Route path="settings" element={<SettingsPage />} />
                <Route path="invoices" element={<InvoicesPage/>} />
                <Route path="scheduling" element={<SchedulingPage/>} />
                <Route path="equipment" element={<EquipmentPage/>} />
                <Route path="settings" element={<SettingsPage/>} />
                <Route path="tickets" element={<TicketsPage/>} />
                <Route path="tickets/edit/:id" element={<EditTicket />} />
                <Route path="customers" element={<CustomersPage />} />
                <Route path="edit-customer/:id" element={<EditCustomerPage />} />
                <Route path="users" element={<UsersPage />}/>
                <Route path="payrolls" element={<PayrollsPage />}/>
            </Route>
        </Route>
    )
);
