export const formatDate = (dateString) => {
    if (dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US');
    }
    return null;
};

export const formatDateTime = (dateString) => {
    if (dateString) {
        const date = new Date(dateString);
        return date.toLocaleString('en-US');
    }
    return null;
};

export const toStringFormat = (dt) => {
    return `${dt.getFullYear()}-${(dt.getMonth() + 1).toString().padStart(2, '0')}-${dt.getDate().toString().padStart(2, '0')}T${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}:00`;
};

export const setLocalDate = (date = '') => {
    if (!(date instanceof Date)) {
        return "Invalid Date";
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export const toCurrencyNumber = (number) => {
    return number.toLocaleString('USD', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

