import { postData } from "./http-service";

export const forgotPassword = async (data) => {
    try {
        const response = await postData({path: 'authentication/GenerateResetPasswordLink', data, showToaster: false, tokenNeeded: false})
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        return null;
    }
}

export const forgotPasswordEmployee = async (data) => {
    try {
        const response = await postData({path: 'authentication/reset-employee-password', data, showToaster: false, tokenNeeded: false});
        if(response.ok){
            return await response.json();
        }
    } catch(error)
    {
        return null;
    }
}

//Set password for new user
export const setUserPassword = async (userPassword) => {
    try{
      return await postData({
        path: 'authentication/addPassword',
        data: userPassword,
        toasterMessage: "Password updated!",
        tokenNeeded: false
      });
    } catch (e) {
      return null;
    }
  }
