import {postData} from "./http-service";

export const getPayroll = async (startDate, endDate) => {
    const queryString = `?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`;
    const response = await postData({
        method: "GET",
        path: "payrolls/list" + queryString,
        data: { },
        showToaster: false,
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error("Failed to fetch data");
    }
}

export const getHourlyReport = async (startDate, endDate) => {
    const queryString = `?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`;
    const response = await postData({
        method: "GET",
        path: "payrolls/hourly-report" + queryString,
        data: { },
        showToaster: false,
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error("Failed to fetch data");
    }
}
