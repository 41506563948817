import { postData } from "./http-service";

export const changeOwnPassword = async (changePasswordData) => {
  const response = await postData({
    path: "authentication/account/change-password",
    data: changePasswordData,
    showToaster: true,
    toasterMessage: "Password changed successfully"
  });
  return response.ok;
};

export const updateProfile = async (updateProfileData) => {
  const response = await postData({
    path: "settings/profile/update",
    data: updateProfileData,
    showToaster: false,
  });
  return response.ok;
};

export const getProfile = async () => {
  const response = await postData({
    method: "POST",
    path: "settings/profile",
    showToaster: false,
  });
  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch data");
  }
};