import React, {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import ModalComponent from "../shared/Modal";
import CustomTable from "../shared/table/Table";
import {Forms} from "../shared/forms/forms";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {Calendar, momentLocalizer} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { Typography} from "@material-ui/core";
import { createJob, deleteJob, getJobs, updateJob } from "../services/scheduling-service";
import { getCustomerById, getCustomers } from "../services/customer-service";
import { getEquipmentByCategory } from "../services/equipment-service";
import { formatDate, setLocalDate } from "../utils/date-functions";
import {Navigate} from "react-router-dom";
import {useAuth} from "../hooks/useAuth";

export const SchedulingPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [jobFields, setJobFields] = useState(jobData);
  const [jobId, setCurrentJobId] = useState();
  const [modalTitle, setModalTitle] = useState("");
  const [scheduledJobs, setScheduledJobs] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [equipmentCategoryData, setEquipmentCategoryData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [scheduledJobId, setScheduledJobId] = useState("");
  const [isJobEdit, setIsJobEdit] = useState(false);
  const localizer = momentLocalizer(moment);
  const ref = useRef();

  const { role } = useAuth();

  if (role && role !== 'admin' && role !== 'manager') {
    return <Navigate to="/dashboard/homepage" replace/>;
  }

  const columns = [
    { accessorKey: "customerName", header: "Customer" },
    { accessorKey: "equipmentName", header: "Equipment" },
    { accessorKey: "startTime", header: "Start Time" },
    { accessorKey: "endTime", header: "End Time" },
  ];

  const getAllJobs = () => {
    getJobs().then((response) => {
      response.forEach(item => {
        item.startTime = formatDate(item.startTime);
        item.endTime = formatDate(item.endTime);
      });
      setScheduledJobs(response);
    });
  };

  const getAllCustomers = () => {
    getCustomers().then((response) => setCustomerData(response));
  };

  const handleGetEquipmentByCategory = () => {
    getEquipmentByCategory().then((response) =>
      setEquipmentCategoryData(response)
    );
  };

  useEffect(() => {
    getAllJobs();
    getAllCustomers();
    handleGetEquipmentByCategory();
    setJobFields(jobData);
  }, [locations, contacts]);

  const generateSelectOptionsFromCustomerData = (data) => {
    return data.map((customer) => {
      return {
        label: customer.name,
        value: customer.id,
      };
    });
  };

  const generateOptionsFromEquipmentCategoryData = (data) => {
    return data
        .filter((category) => category.category === "Rig")
        .map((category) => {
          return {
            label: category.name,
            value: category.id,
          };
        });
  };

  const selectLocation1 = locations.map((item) => ({
    value: item.id,
    label: item.displayName,
  }));

  const selectContacts = contacts.map((item) => ({
    value: item.id,
    label: item.firstName + " " + item.lastName,
  }));

  const selectOptions = generateSelectOptionsFromCustomerData(customerData);
  const selectCategoryOptions = generateOptionsFromEquipmentCategoryData(
    equipmentCategoryData
  );

  const handleOpenModal = (job, jobBody = null) => {
    setModalTitle(job ? "Add Job" : "Update Job");
    setJobFields(jobBody ? jobFormData(jobBody) : jobData);
    getAllCustomers();
    handleGetEquipmentByCategory();
    setModalOpen(true);
  };

  const setNewJobData = (startTime, endTime) => {
    return [
      {
        label: "Customer Name",
        type: "autocomplete",
        name: "customerId",
        value: "",
        required: true,
        selectOptions: selectOptions,
        onChange: (customerId) => {
          getCustomerById(customerId).then((res) => {
            setLocations(res.locations);
            setContacts(res.contacts);
          });
        },
        size: 6,
      },
      {
        label: "Description",
        type: "text",
        name: "description",
        required: true,
        size: 6,
      },
      {
        label: "Customer Location",
        type: "select",
        name: "customerLocationId",
        value: null,
        required: false,
        selectOptions: selectLocation1,
        size: 6,
      },
      {
        label: "Customer Contact",
        type: "select",
        name: "customerContactId",
        value: null,
        required: false,
        selectOptions: selectContacts,
        size: 6,
      },
      {
        label: "Rig",
        type: "select",
        name: "equipmentId",
        value: null,
        required: true,
        selectOptions: selectCategoryOptions,
        size: 4,
      },
      {
        label: "Start Time",
        type: "datetime",
        name: "startTime",
        required: true,
        value: startTime,
        size: 4,
      },
      {
        label: "End Time",
        type: "datetime",
        name: "endTime",
        required: true,
        value: endTime,
        size: 4,
      },
    ];
  }

  const jobData = [
    {
      label: "Customer Name",
      type: "autocomplete",
      name: "customerId",
      value: "",
      required: true,
      selectOptions: selectOptions,
      onChange: (customerId) => {
        getCustomerById(customerId).then((res) => {
          setLocations(res.locations);
          setContacts(res.contacts);
        });
      },
      size: 6,
    },
    {
      label: "Description",
      type: "text",
      name: "description",
      required: true,
      size: 6,
    },
    {
      label: "Customer Location",
      type: "select",
      name: "customerLocationId",
      value: null,
      required: false,
      selectOptions: selectLocation1,
      size: 6,
    },
    {
      label: "Customer Contact",
      type: "select",
      name: "customerContactId",
      value: null,
      required: false,
      selectOptions: selectContacts,
      size: 6,
    },
    {
      label: "Rig",
      type: "select",
      name: "equipmentId",
      value: null,
      required: true,
      selectOptions: selectCategoryOptions,
      size: 4,
    },
    {
      label: "Start Time",
      type: "datetime",
      name: "startTime",
      required: true,
      size: 4,
    },
    {
      label: "End Time",
      type: "datetime",
      name: "endTime",
      required: true,
      size: 4,
    },
  ];

  const editJob = (data) => {
    const editJobData = [
      {
        label: "Customer",
        type: "autocomplete",
        name: "customerId",
        required: true,
        value: data.data.customerId,
        selectOptions: selectOptions,
        size: 6,
        onChange: (customerId) => {
          getCustomerById(customerId).then((res) => {
            setLocations(res.locations);
            setContacts(res.contacts);
          });
        },
      },
      {
        label: "Description",
        type: "text",
        name: "description",
        required: true,
        value: data.data.description,
        size: 6,
      },

      {
        label: "Customer Location",
        type: "select",
        name: "customerLocationId",
        required: false,
        value: data.data.customerLocationId,
        selectOptions: selectLocation1,
        size: 6,
      },
      {
        label: "Customer Contact",
        type: "select",
        name: "customerContactId",
        required: false,
        value: data.data.customerContactId,
        selectOptions: selectContacts,
        size: 6,
      },
      {
        label: "Rig",
        type: "select",
        name: "equipmentId",
        required: true,
        value: data.data.equipmentId,
        selectOptions: selectCategoryOptions,
        size: 4,
      },
      {
        label: "Start Time",
        type: "datetime",
        name: "startTime",
        required: true,
        value: data.data.startTime,
        size: 4,
      },
      {
        label: "End Time",
        type: "datetime",
        name: "endTime",
        required: true,
        value: data.data.endTime,
        size: 4,
      },
    ];

    setJobFields(editJobData);
  };

  const colors = [
    "blue",
    "orange",
    "green",
    "magenta",
    "red",
    "brown",
    "black"
  ];

  const uniqueRigIds = [...new Set(scheduledJobs.map(item => item.equipmentId))];

  const getUniqueId = (equipmentId) => {
    const index = uniqueRigIds.indexOf(equipmentId);
    if (index === -1 || colors.length <= index){
      return "brown";
    }
    return colors[index];
  };

  const events = scheduledJobs.map((job) => ({
    title: job.equipmentName,
    start: new Date(job.startTime),
    end: new Date(job.endTime),
    id: job.id,
    color: getUniqueId(job.equipmentId)
  }));

  const handleConfirmAction = () => {
    ref.current.submit();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleDeleteCloseModal = () => {
    setDeleteModalOpen(false);
  };

  const handleAddJob = () => {
    setIsJobEdit(false);
    handleOpenModal(true);
  };

  const handleEditJob = (job) => {
    editJob(job);
    setIsJobEdit(true);
    setScheduledJobId(job.data.id);
    getCustomerById(job.data.customerId).then((res) => {
      setLocations(res.locations);
      setContacts(res.contacts);
      setModalOpen(true);
    });
  };

  const handleDeleteJobModal = (jobIndex) => {
    setDeleteModalOpen(true);
    const currentJobId = jobIndex.data.id;
    setCurrentJobId(currentJobId);
  };

  const handleDeleteConfirmAction = () => {
    deleteJob(jobId).then(() => {
      getAllJobs();
      handleDeleteCloseModal();
    });
  };

  const handleCreateJob = (jobData) => {
    jobData.startTime = setLocalDate(jobData.startTime);
    jobData.endTime = setLocalDate(jobData.endTime);
    if(jobData.customerContactId === ""){
      jobData.customerContactId = null;
    }
    if(jobData.customerLocationId === ""){
      jobData.customerLocationId = null;
    }
    createJob(jobData).then(() => {
      handleCloseModal();
      getAllJobs()
    });
  };

  const handleUpdateJob = (jobData) => {
    jobData.startTime = setLocalDate(jobData.startTime);
    jobData.endTime = setLocalDate(jobData.endTime);
    if(jobData.customerContactId === ""){
      jobData.customerContactId = null;
    }
    if(jobData.customerLocationId === ""){
      jobData.customerLocationId = null;
    }
    updateJob(jobData).then(() => {
      handleCloseModal();
      getAllJobs()
    });
  };

  const createPredefined = box => {
    setJobFields(setNewJobData(setLocalDate(box.start), setLocalDate(box.end)));
    setIsJobEdit(false);
    setModalTitle("Add Job");
    getAllCustomers();
    handleGetEquipmentByCategory();
    setModalOpen(true);
  }

  const handleSelectedEvent = (event) => {
    const item = scheduledJobs.find(job => job.id === event.id);
    handleEditJob({
      data: item
    });
  }

  const onFormSubmit = (data) => {
    if (isJobEdit) {
      data.id = scheduledJobId;
      handleUpdateJob(data);
    } else {
      handleCreateJob(data);
    }
  };

  const actions = [
    {
      onClick: handleConfirmAction,
      color: "primary",
      label: "Confirm",
      variant: "contained",
    },
    {
      onClick: handleCloseModal,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];

  const deleteActions = [
    {
      onClick: handleDeleteConfirmAction,
      color: "primary",
      label: "Confirm",
      variant: "contained",
    },
    {
      onClick: handleDeleteCloseModal,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];

  return (
    <div>
      <CustomTable
        tableName="Scheduled Jobs"
        columns={columns}
        data={scheduledJobs}
        rowsPerPageOptions={[10]}
        showDelete={true}
        showEdit={true}
        onAdd={handleAddJob}
        onEdit={(data) => handleEditJob(data)}
        onDelete={handleDeleteJobModal}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        selectable={true}
        onSelectSlot={res => createPredefined(res)}
        onSelectEvent={res => handleSelectedEvent(res)}
        style={{ height: 600, marginTop: "20px" }}
        eventPropGetter={(event) => ({
          style: {
            backgroundColor: event.color,
          },
        })}
      />
      <ModalComponent
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title={modalTitle}
        actions={actions}
        closeOnOutsideClick={false}
      >
        <Forms
          ref={ref}
          fields={jobFields}
          onSubmit={(data) => onFormSubmit(data)}
        />
      </ModalComponent>
      <ModalComponent
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title="Delete Job"
        actions={deleteActions}
        closeOnOutsideClick={false}
      >
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <HighlightOffIcon
            style={{ margin: "auto", fontSize: "58px", color: "red" }}
          />
          <Typography
            style={{ fontSize: "24px", textAlign: "center", margin: "15px" }}
          >
            Are you sure?
          </Typography>
          <Typography
            style={{ fontSize: "14px", textAlign: "center", color: "grey" }}
          >
            Do you really want to delete this job? <br />
            The process cannot be undone.
          </Typography>
        </Box>
      </ModalComponent>
    </div>
  );
};
