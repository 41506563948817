import {useState} from 'react';

export const useLocalStorage = (keyName, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        if (!initialValue){
            window.localStorage.removeItem(keyName);
        } else{
            window.localStorage.setItem(keyName, JSON.stringify(initialValue));
        }
        return initialValue;
    });

    const setValue = (newValue) => {
        try {
            if (!newValue){
                window.localStorage.removeItem(keyName);
            } else {
                window.localStorage.setItem(keyName, JSON.stringify(newValue));
            }
        } catch (err) {}
        setStoredValue(newValue);
    };

    return [storedValue, setValue];
};