import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useAuth } from "../hooks/useAuth";
import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import {LoadingComponent} from "../services/loading-service";

export const LoginPage = () => {
    const {login} = useAuth();
    const [usernameError, setUsernameError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [username, setUsername] = useState('');
    const [invalidInput, setInvalidInput] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [mainLoading, setMainLoading] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
    event.preventDefault();
    };

    const handleChange = event => {
        setUsername(event.target.value);
        setInvalidInput(event.target.value ? '' : 'Username is required');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let usernameValue = data.get("username").trim();
        let passwordValue = data.get("password");

        if (!usernameValue) {
            setUsernameError(true);
            setMainLoading(false);
        }
        if (!passwordValue) {
            setPasswordError(true);
        }

        if (usernameValue && passwordValue) {
              setMainLoading(true);
              await login({
                    username: usernameValue,
                    password: passwordValue
                });
              setMainLoading(false);
            }
        };

    const renderForm = (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 5 }}
             style={{
                border: "1px solid lightblue",
                borderRadius: "20px",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                padding: "30px"
             }}>
         <Typography style={{textAlign: "center", marginBottom: "20px"}}>
                   Sign in to your account
          </Typography>
        <FormControl
            style={{minHeight: "80px"}}
            margin="normal"
            fullWidth
        >
        <TextField
            error={usernameError}
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={handleChange}
            helperText={invalidInput}
        />
        </FormControl>
        <FormControl
            variant="outlined"
            error={passwordError}
            margin="normal"
            fullWidth
         >
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
           <OutlinedInput
                htmlFor="outlined-adornment-password"
                name="password"
                label="Password"
                autoComplete="current-password"
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
                }
            />
          </FormControl>
            <Link to="/forgot-password" style={{textDecoration: "none"}}>
            <Typography style={{textAlign: "center", marginBottom: "20px", marginTop: "15px", fontSize: "14px"}}>
                   Forgot password?
            </Typography>
            </Link>
        <Box
        style={{display: "flex", justifyContent: "center"}}
        >
        <Button
            style={{minWidth: "90px"}}
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
        >
            Login
        </Button>
        </Box>

    </Box>
    );
    return (
        <>
            <LoadingComponent loading={mainLoading} />
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <Avatar sx={{ m: 2, bgcolor: "primary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Welcome!
                    </Typography>
                    {renderForm}
                </Box>
            </Container>
        </>
    );
};
