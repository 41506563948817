import { Suspense } from "react";
import { useLoaderData, useOutlet, Await } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import { AuthProvider } from "../hooks/useAuth";

export const AuthLayout = () => {
    const outlet = useOutlet();

    const { userTokenPromise } = useLoaderData();

    return (
        <Suspense fallback={<LinearProgress />}>
            <Await
                resolve={userTokenPromise}
                errorElement={<Alert severity="error">Something went wrong!</Alert>}
                children={(userToken) => (
                    <AuthProvider userToken={userToken}>{outlet}</AuthProvider>
                )}
            />
        </Suspense>
    );
};
