import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Signature } from "../shared/signature/signature";
import ModalComponent from "../shared/Modal";
import { Forms } from "../shared/forms/forms";
import Button from '@mui/material/Button';
import "../styles.css";
import { changeOwnPassword, getProfile } from "../services/profile-service";
import {LoadingComponent} from "../services/loading-service";
import { useAuth } from "../hooks/useAuth";

export const ProfilePage = () => {
    const [userSignature, setUserSignature] = useState(null);

    const [showPasswordMatchError, setShowPasswordMatchError] = useState(false);
    const [showOldPasswordError, setOldPasswordError] = useState(false);
    const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
    const [mainLoading, setMainLoading] = useState(false);
    const changePasswordRef = useRef();

    const { role } = useAuth();

    const save = (newSignature) => {
        setMainLoading(true);
        setUserSignature("");
        setTimeout(() => {
            setUserSignature(newSignature);
            setMainLoading(false);
        }, 1000);
    }

    const getCurrentProfile = () => {
        getProfile().then((resData) => {
            setUserSignature(resData.signature);
        });
    };

    useEffect(() => {
        getCurrentProfile();
    }, [userSignature]);

    const errorMessageStyle = {
        textAlign: 'center',
        color: 'red',
        fontSize: '16px',
        fontWeight: 'bold'
    };

    const changePasswordFields = [
        {
            label: 'Old password',
            type: 'password',
            name: 'oldPassword',
            required: true,
            email: false,
            size: 12,
        },
        {
            label: 'New password',
            type: 'password',
            name: 'newPassword',
            required: true,
            email: false,
            size: 6,
            lowercaseLetterRequired: true
        },
        {
            label: 'Confirm new password',
            type: 'password',
            name: 'newPasswordConfirm',
            required: true,
            email: false,
            size: 6
        }
    ];

    const changePasswordActions = [
        {
            onClick: () => handleConfirmChangePassword(),
            color: "primary",
            label: "Confirm",
            variant: "contained",
        },
        {
            onClick: () => setChangePasswordModalOpen(false),
            color: "default",
            label: "Cancel",
            variant: "outlined",
        },
    ];

    const handleConfirmChangePassword = () => {
        changePasswordRef.current.submit();
    }

    const onSubmitChangePassword = async (data) => {
        setMainLoading(true);
        if (data.newPassword !== data.newPasswordConfirm) {
            setShowPasswordMatchError(true);
            setOldPasswordError(false);
            return;
        }
        const requestBody = {
            currentPassword: data.oldPassword,
            newPassword: data.newPassword
        }
        if (!await changeOwnPassword(requestBody)) {
            setMainLoading(false);
            setShowPasswordMatchError(false);
            setOldPasswordError(true);
        } else {
            setMainLoading(false);
            setChangePasswordModalOpen(false);
        }
    };

    const handleChangePasswordModalClose = () => {
        setOldPasswordError(false);
        setChangePasswordModalOpen(false);
    }

    const handleChangePasswordOpen = () => {
        setOldPasswordError(false);
        setChangePasswordModalOpen(true);
    }

    return (
        <>
            <LoadingComponent loading={mainLoading} />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '400px', marginBottom: '0px', marginTop: '20px', marginLeft: '20px' }}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleChangePasswordOpen}
                    style={{ alignSelf: 'flex-start', width: '400px' }}
                >
                    Change password
                </Button>
            </div>
            {role !== "customer" && (
                <div style={{ alignSelf: 'center', marginLeft: '20px' }}>
                    <Signature signature={userSignature} setSignature={save} />
                </div>
            )}
            <ModalComponent
                open={changePasswordModalOpen}
                onClose={handleChangePasswordModalClose}
                title={"Change password"}
                actions={changePasswordActions}
                closeOnOutsideClick={false}
            >
                <Forms
                    ref={changePasswordRef}
                    fields={changePasswordFields}
                    onSubmit={(data) => onSubmitChangePassword(data)}
                />
                {showPasswordMatchError && (
                    <div style={errorMessageStyle}>Passwords must match!</div>
                )}
                {showOldPasswordError && (
                    <div style={errorMessageStyle}>Incorrect old password!</div>
                )}
            </ModalComponent>
        </>
    );

};
