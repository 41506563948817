import { showToastr } from "../utils/toastr";
import { baseUrl } from "../environments/environment";
import { getToken } from "./token-manager";
import { toStringFormat } from "../utils/date-functions";


export const postData = async ({method = 'POST', path = '', api = 'api', data = {}, options = '', toasterMessage = 'Operation succesful', showToaster = true, tokenNeeded = true}) => {
  const url = `${baseUrl}/${api}/${path}${options}`;
  const token = await getToken();
  if (!token && tokenNeeded){
    window.location.reload();
  }
    const init = {
        method,
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer",
    }

    
    if (method === 'POST') {
        for(var element in data){
          if(Object.prototype.toString.call(data[element]) === "[object Date]"){
            data[element] = toStringFormat(data[element]);
          }
        }
        init.body = JSON.stringify(data);
    }

    if (token) {
        init.headers['Authorization'] = 'Bearer '.concat(token);
    }

    const response = await fetch(url, init);
    let errorMessage = '';
    if (!response.ok) {
        response.json().then(res => {
            errorMessage = res['ErrorMessage'];
            if (showToaster) {
                showToastr(errorMessage || 'Operation failed', 'error')
            }
            return response;
        });
    } else {
        if (showToaster) {
            showToastr(toasterMessage, 'success')
        }
        return response;
    }
}

export const uploadFile = async (file, path, reqBody, api = "api") => {
    const token = await getToken();
    if (!token){
      window.location.reload();
    }
    const url = `${baseUrl}/${api}/${path}`
    try {
      const formData = new FormData();
      formData.append(reqBody, file);

      const response = await fetch(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      showToastr(response.ok ? "File uploaded successfully!" : 'Operation failed', response.ok ? 'success': 'error')
    } catch (error) {
      console.error('Upload Error:', error);
      throw error;
    }
  };

