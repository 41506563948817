export const downloadDoc = async (response) => {
    const fileBlob = await response.blob();
    const header = response.headers.get('Content-Disposition');
    const filenameReg = header.match(/filename=(.+);/);
    var fileName = filenameReg.length > 1 ? filenameReg[1] : "document";
    fileName = fileName.replace(/[/\\?%*:|"<>]/g, '');
    const url = URL.createObjectURL(fileBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
};