import {createContext, useContext, useEffect, useMemo} from "react";
import { useNavigate } from "react-router-dom";
import { postData } from "../services/http-service";
import { showToastr } from "../utils/toastr";
import { useState } from 'react';
import { useLocalStorage } from "../hooks/useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children, userToken }) => {
    const [user, setUserToken] = useLocalStorage("token", userToken);
    const [name, setName] = useState(localStorage.getItem('initials') || 'AD');
    const [role, setRole] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            setRole(JSON.parse(atob(user.split('.')[1])).role.toLowerCase());
        } else {
            setRole("");
        }
    }, [user]);

    const login = async (data) => {
        const { username, password } = data;
        const response =  await postData({path: "authentication/login", data: {username, password}, showToaster: false, tokenNeeded: false});
            const responseData = await response.json();
            if (responseData.isSuccess) {
                setName(responseData.user.initials);
                localStorage.setItem('initials', responseData.user.initials);
                setUserToken(responseData.user.token);
                localStorage.setItem("refreshToken", JSON.stringify(responseData.user.refreshToken));
                showToastr("Login successful", "success");
            } else {
                showToastr("Login failed. Try again.", "error");
            }
        return response;
    };

    const logout = () => {
        setUserToken(null);
        window.localStorage.removeItem("refreshToken");
        navigate("/login", { replace: true });
        navigate("/", { replace: true });
        window.localStorage.removeItem("initials");
    };

    const value = useMemo(
        () => ({
            name,
            user,
            role,
            login,
            logout
        }),
        [user, logout]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
