import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { AppBarSidebar } from "./AppBar";
import Toolbar from '@mui/material/Toolbar';
import Box from "@mui/material/Box";
import { StylesProvider } from "@material-ui/core/styles";
import CssBaseline from "@mui/material/CssBaseline";

export const ProtectedLayout = () => {
  const { user, role } = useAuth();
  const outlet = useOutlet();
  
  if (!user) {
    let loginPath = "/login";
    const path = (location.pathname+location.search).substring(1);
    if (path && path !== ''){
      loginPath += `?redirectTo=${path}`;
    }
    return <Navigate to={loginPath} replace />;
  }

  const getPages = () => {
    if (!role) {
      return [];
    }

    if (role === "admin") {
      return [
        { label: "Dashboard", path: "dashboard/homepage", index: 0, divider: true },
        { label: "Equipment", path: "dashboard/equipment", index: 4 },
        { label: "Customers", path: "dashboard/customers", index: 2 },
        { label: "Scheduling", path: "dashboard/scheduling", index: 3 },
        { label: "Tickets", path: "dashboard/tickets", index: 1, divider: true },
        { label: "Employees", path: "dashboard/employees", index: 7 },
        { label: "Payrolls", path: "dashboard/payrolls", index: 10, divider: true },
        { label: "Users", path: "dashboard/users", index: 8 },
        { label: "Settings", path: "dashboard/settings", index: 5 }
      ];
    }

    if (role === "manager") {
      return [
        { label: "Equipment", path: "dashboard/equipment", index: 4 },
        { label: "Scheduling", path: "dashboard/scheduling", index: 3 },
        { label: "Tickets", path: "dashboard/tickets", index: 1, divider: true },
        { label: "Settings", path: "dashboard/settings", index: 5 }
      ];
    }

    if (role ==="customer") {
      return [
        { label: "Tickets", path: "dashboard/tickets", index: 1 },
        { label: "Settings", path: "dashboard/settings", index: 5 }
      ];
    }

    if (role === "toolpusher") {
      return [
        { label: "Dashboard", path: "dashboard/homepage", index: 0, divider: true },
        { label: "Tickets", path: "dashboard/tickets", index: 1, divider: false },
        { label: "Customers", path: "dashboard/customers", index: 2, divider: false },
        { label: "Equipment", path: "dashboard/equipment", index: 4, divider: true },
        { label: "Settings", path: "dashboard/settings", index: 5 }
      ];
    }

    return [];
  };

  const pages = getPages();

  return (
      <>
        <Box sx={{ display: "flex"}}>
          <CssBaseline />
          <AppBarSidebar pages={pages} />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Toolbar />
            <StylesProvider injectFirst>{outlet}</StylesProvider>
          </Box>
        </Box>
      </>
  );
};
