import { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Forms } from "../shared/forms/forms";
import { Button } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Box from "@mui/material/Box";

import { createContact, createLocation, deleteContact, deleteLocation, getCustomerById, updateCustomer, updateLocation, updateContact, inviteContactLogin, deleteContactLogin, resetContactPassword, resendContactInvite } from "../services/customer-service";
import CustomTable from "../shared/table/Table";
import ModalComponent from "../shared/Modal";
import { MapsComponent } from "../components/maps/maps";
import { usStates } from "../environments/environment";
import {LoadingComponent} from "../services/loading-service";
import * as React from "react";

export const EditCustomerPage = () => {

    const { id } = useParams();
    const [fields, setFields] = useState([]);
    const [contactData, setContactData] = useState([]);
    const [currentContactId, setCurrentContactId] = useState([]);
    const [editContactFields, setEditContactFields] = useState(addContactFields);

    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [deleteContactModalOpen, setDeleteContactModalOpen] = useState(false);
    const [editContactModalOpen, setEditContactModalOpen] = useState(false);
    const [currentContactData, setCurrentContactData] = useState([]);

    const [locationData, setLocationData] = useState([]);
    const [currentLocationId, setCurrentLocationId] = useState(undefined);
    const [editLocationFields, setEditLocationFields] = useState(addLocationFields);

    const [locationModalOpen, setLocationModalOpen] = useState(false);
    const [deleteLocationModalOpen, setDeleteLocationModalOpen] = useState(false);
    const [editLocationModalOpen, setEditLocationModalOpen] = useState(false);

    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

    const [selectedLocation, setSelectedLocation] = useState([]);

    const [mainLoading, setMainLoading] = useState(false);

    const primaryColor = "#192cd2";
    const updateButton = {buttonName: 'Update Customer'};
    const defaultLattitude = 32.746544520952945;
    const defaultLongitude = -97.32480868838134;

    const ref = useRef();
    const passwordRef = useRef();

    const handleMapClick = (newMapInfo) => {
        setSelectedLocation(newMapInfo);
    }

    const resetPasswordField = [
        {
            label: 'Reset Password',
            type: 'password',
            name: 'password',
            required: true,
            size: 12
        }
    ]

    const addLocationFields = [
        {
            label: 'Lease',
            type: 'text',
            name: 'lease',
            required: true,
            size: 6
        },
        {
            label: 'Field',
            type: 'text',
            name: 'field',
            size: 6
        },
        {
            label: 'Well Number',
            type: 'text',
            name: 'well',
            required: true,
            size: 4
        },
        {
            label: 'County',
            type: 'text',
            name: 'county',
            required: true,
            size: 4
        },
        {
            label: 'Well Type',
            type: 'select',
            name: 'wellType',
            required: true,
            size: 4,
            selectOptions: [
                {
                    label: 'Oil',
                    value: 'Oil'
                },
                {
                    label: 'Gas',
                    value: 'Gas'
                }
            ],
        }
    ]

    const getCustomerData = () => {
        getCustomerById(id).then((customerData) => {
            setCustomerData(customerData);
        });
    }

    const setCustomerData = (customerData) => {
        const updatedFields = [
            {
                label:'Name',
                type:'text',
                name:'name',
                required: true,
                value: customerData.name,
                size:6
            },
            {
                label:'Address',
                type:'text',
                name:'address',
                required: true,
                value: customerData.address,
                size:6
            },
            {
                label:'City',
                type:'text',
                name:'city',
                required: true,
                value: customerData.city,
                size:4
            },
            {
                label: 'State',
                type: 'autocomplete',
                name: 'state',
                required: true,
                size: 4,
                selectOptions: usStates,
                value: customerData.state,
            },
            {
                label:'Zip code',
                type:'number',
                name:'zip',
                required: true,
                value: customerData.zip,
                size:4
            },
            {
                label:'Net Term (days)',
                type:'number',
                name:'netTerm',
                required: true,
                value: customerData.netTerm,
                size:6
            },{
                label: 'Send Emails to',
                type: 'text',
                name: 'sendInvoiceTo',
                required: true,
                email: true,
                size: 6,
                value: customerData.sendInvoiceTo,
            },
        ];

        setContactData(customerData.contacts);
        setLocationData(customerData.locations);

        setFields(updatedFields);
    }

    useEffect(() => {
        getCustomerData();
    }, []);

    const addContactFields = [
        {
            label: 'First Name',
            type: 'text',
            name: 'firstName',
            required: true,
            size: 6
        },
        {
            label: 'Last Name',
            type: 'text',
            name: 'lastName',
            required: true,
            size: 6
        },
        {
            label: 'Email',
            type: 'text',
            name: 'email',
            required: true,
            email: true,
            size: 12
        },
        {
            label: 'Phone Number',
            type: 'text',
            name: 'phoneNumber',
            required: true,
            mask: true,
            masked: "(999) 999-9999",
            value: "",
            size: 6
        },
        {
            label: 'Job Title',
            type: 'text',
            name: 'jobTitle',
            required: false,
            value: contactData.jobTitle,
            size: 6
        }
    ]

    const setContactFields = (contact) => {
        const contactData = contact.data;
        setCurrentContactId(contactData.id);
        setCurrentContactData(contactData);
        const editContactFields = [
            {
                label: 'First Name',
                type: 'text',
                name: 'firstName',
                required: true,
                size: 6,
                value: contactData.firstName
            },
            {
                label: 'Last Name',
                type: 'text',
                name: 'lastName',
                required: true,
                size: 6,
                value: contactData.lastName
            },
            {
                label: 'Email',
                type: 'text',
                name: 'email',
                required: true,
                email: true,
                size: 12,
                value: contactData.email
            },
            {
                label: 'Phone Number',
                type: 'text',
                name: 'phoneNumber',
                required: true,
                mask: true,
                masked: "(999) 999-9999",
                size: 6,
                value: contactData.phoneNumber
            },
            {
                label: 'Job Title',
                type: 'text',
                name: 'jobTitle',
                required: false,
                value: contactData.jobTitle,
                size: 6
            }
        ]
        setEditContactFields(editContactFields);
    }

    const setLocationFields = (location) => {
        const locationData = location.data;
        setCurrentLocationId(locationData.id);

        const locationMapCoordinates = {
            lat: locationData?.lattitude ?? defaultLattitude,
            lng: locationData?.longitude ?? defaultLongitude
        }

        setSelectedLocation(locationMapCoordinates);

        const editLocationFields = [
            {
                label: 'Lease',
                type: 'text',
                name: 'lease',
                required: true,
                size: 6,
                value: locationData.lease
            },
            {
                label: 'Field',
                type: 'text',
                name: 'field',
                size: 6,
                value: locationData.field
            },
            {
                label: 'Well Number',
                type: 'text',
                name: 'well',
                required: true,
                size: 4,
                value: locationData.well
            },
            {
                label: 'County',
                type: 'text',
                name: 'county',
                required: true,
                size: 4,
                value: locationData.county
            },
            {
                label: 'Well Type',
                type: 'select',
                name: 'wellType',
                required: true,
                size: 4,
                value: locationData.wellType,
                selectOptions: [
                    {
                        label: 'Oil',
                        value: 'Oil'
                    },
                    {
                        label: 'Gas',
                        value: 'Gas'
                    }
                ],
            }
        ];
        setEditLocationFields(editLocationFields);
    }

    const handleEditCustomer = (data) => {
        setMainLoading(true);
        const requestBody = {
          name: data.name,
          address: data.address,
          city: data.city,
          zip: data.zip,
          state: data.state,
          netTerm: data.netTerm,
          sendInvoiceTo: data.sendInvoiceTo,
          id: id,
        }

        updateCustomer(requestBody).then(() => {
            setMainLoading(false);
            getCustomerData();
        }).catch(() => {
            setMainLoading(false);
        });
    };

    const onEditLocation = (data) => {
        const requestBody = {
            id: currentLocationId,
            customerId: id,
            displayName: data.displayName,
            lease: data.lease,
            field: data.field,
            well: data.well,
            county: data.county,
            wellType: data.wellType,
            lattitude: selectedLocation.lat,
            longitude: selectedLocation.lng,
        }

        updateLocation(requestBody).then(() => {
            getCustomerData();
        })
    }

    const onEditContact = (data) => {
        const requestBody = {
            id: currentContactId,
            customerId: id,
            customerLocationId: data.customerLocationId,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            jobTitle: data.jobTitle
        }

        updateContact(requestBody).then(() => {
            getCustomerData();
        })
    }

    const handleConfirmAction = () => {
        ref.current.submit();
    }

    const contactColumns = [
        {accessorKey: 'firstName', header: 'First Name'},
        {accessorKey: 'lastName', header: 'Last Name'},
        {accessorKey: 'email', header: 'Email'},
        {accessorKey: 'phoneNumber', header: 'Phone Number'}
    ]

    const locationColumns = [
        {accessorKey: 'displayName', header: 'Display Name'},
        {accessorKey: 'lease', header: 'Lease'},
        {accessorKey: 'field', header: 'Field'},
        {accessorKey: 'county', header: 'County'},
        {accessorKey: 'wellType', header: 'Well Type'}
    ]

    // Contact modals

    const handleAddContact = () => {
        setContactModalOpen(true);
    }

    const handleCloseContactModal = () => {
        setContactModalOpen(false);
    }

    const handleDeleteContact = (data) => {
        setCurrentContactId(data.data.id);
        setDeleteContactModalOpen(true);
    }

    const handleCloseDeleteContactModal = () => {
        setDeleteContactModalOpen(false);
    }

    const handleContactDeleteConfirm = () => {
        handleCloseDeleteContactModal();
        deleteContact(currentContactId).then(() => getCustomerData());
    }

    const handleEditContact = (data) => {
        setContactFields(data);
        setEditContactModalOpen(true);
    }

    const handleCloseEditContactModal = () => {
        setEditContactModalOpen(false);
    }

    const handleConfirmContactEdit = () => {
        ref.current.submit();
        handleCloseEditContactModal();
    }

    //

    // Location modals

    const handleAddLocation = () => {
        setLocationModalOpen(true);
    }

    const handleCloseLocationModal = () => {
        setLocationModalOpen(false);
    }

    const handleLocationDelete = (data) => {
        setCurrentLocationId(data.data.id);
        setDeleteLocationModalOpen(true);
    }

    const handleCloseLocationDeleteModal = () => {
        setDeleteLocationModalOpen(false);
    }

    const handleLocationDeleteConfirm = () => {
        handleCloseLocationDeleteModal();
        deleteLocation(currentLocationId).then(() => getCustomerData());
    }

    const handleLocationEdit = (data) => {
        setLocationFields(data);
        setEditLocationModalOpen(true);
    }

    const handleCloseLocationEditModal = () => {
        setEditLocationModalOpen(false);
    }

    const handleConfirmLocationEdit = () => {
        ref.current.submit();
        handleCloseLocationEditModal();
    }

    //

    // Add-Login functions

    const handleAddLogin = () => {
        inviteContactLogin(currentContactId).then(() => {
            getCustomerData();
            handleCloseEditContactModal();
        });
    }

    const handleRemoveLogin  = () => {
        deleteContactLogin(currentContactId).then(() => {
            getCustomerData();
            handleCloseEditContactModal();
        })
    }

    const resendInvite = () => {
        resendContactInvite(currentContactId).then(() => {
            getCustomerData();
            handleCloseEditContactModal();
        })
    }

    const handleResetPassword = () => {
        setResetPasswordModalOpen(true);
    }

    const handleCloseResetPasswordModal = () => {
        setResetPasswordModalOpen(false);
    }

    const confirmResetPassword = () => {
        passwordRef.current.submit();
        setResetPasswordModalOpen(false);
    }

    const submitResetPassword = (data) => {
        const requestBody = {
            customerContactId: currentContactId,
            password: data.password
        }

        resetContactPassword(requestBody).then(() => {
            getCustomerData();
        })
    }

    const contactActions = [
        {
            onClick: handleConfirmAction,
            color: 'primary',
            label: 'Confirm',
            variant: 'contained'
        },
        {
            onClick: handleCloseContactModal,
            color: 'default',
            label: 'Cancel',
            variant: 'outlined'
        }
    ];

    const deleteContactActions = [
        {
            onClick: handleContactDeleteConfirm,
            color: "primary",
            label: "Confirm",
            variant: "contained"
        },
        {
            onClick: handleCloseDeleteContactModal,
            color: 'default',
            label: 'Cancel',
            variant: 'outlined'
        }
    ]

    const editContactActions = [
        {
            onClick: handleConfirmContactEdit,
            color: "primary",
            label: "Confirm",
            variant: "contained"
        },
        {
            onClick: handleCloseEditContactModal,
            color: 'default',
            label: 'Cancel',
            variant: 'outlined'
        }
    ]

    const locationActions = [
        {
            onClick: handleConfirmAction,
            color: 'primary',
            label: 'Confirm',
            variant: 'contained'
        },
        {
            onClick: handleCloseLocationModal,
            color: 'default',
            label: 'Cancel',
            variant: 'outlined'
        }
    ]

    const deleteLocationActions = [
        {
            onClick: handleLocationDeleteConfirm,
            color: "primary",
            label: "Confirm",
            variant: "contained"
        },
        {
            onClick: handleCloseLocationDeleteModal,
            color: 'default',
            label: 'Cancel',
            variant: 'outlined'
        }
    ]

    const editLocationActions = [
        {
            onClick: handleConfirmLocationEdit,
            color: "primary",
            label: "Confirm",
            variant: "contained"
        },
        {
            onClick: handleCloseLocationEditModal,
            color: 'default',
            label: 'Cancel',
            variant: 'outlined'
        }
    ]

    const resetPasswordActions = [
        {
            onClick: confirmResetPassword,
            color: "primary",
            label: "Confirm",
            variant: "contained"
        },
        {
            onClick: handleCloseResetPasswordModal,
            color: 'default',
            label: 'Cancel',
            variant: 'outlined'
        }
    ]

    // Contact create submit

    // TO DO: Need to add select with all available locations to select location Id for given contact
    //     -> currently contact locationId is left null
    //     -> dropdown should have list of current locations that will be fetched when customer is fetched
    //     -> can be extracted from locationData
    const onCreateContact = (data) => {
        const requestBody = {
            customerId: id,
            customerLocationId: data.customerLocationId,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            jobTitle: data.jobTitle,
            phoneNumber: data.phoneNumber,
            inviteSent: false,
            hasLogin: false,
        }

        handleCloseContactModal();
        createContact(requestBody).then(() => getCustomerData());
    }

    // Location create submit

    // TO DO: Need to add google maps integration for lattitude and longitude

    const onCreateLocation = (data) => {
        const requestBody = {
            customerId: id,
            displayName: data.displayName,
            lease: data.lease,
            field: data.field,
            well: data.well,
            county: data.county,
            wellType: data.wellType,
            lattitude: selectedLocation?.lat ?? defaultLattitude,
            longitude: selectedLocation?.lng ?? defaultLongitude,
        }
        handleCloseLocationModal();
        createLocation(requestBody).then(() => getCustomerData());
    }

    return (
        <>
            <LoadingComponent loading={mainLoading} />
            <Container maxWidth={false}>
                <Paper elevation={3} sx={{ p: 4, mb: 4, backgroundColor: "white", borderRadius: "8px" }}>
                    <Typography variant="h5" align="center" marginBottom={3} color={primaryColor} gutterBottom>
                        Basic Information
                    </Typography>
                    <Forms fields={fields} addParams={updateButton} ref={ref} onSubmit={handleEditCustomer} />
                </Paper>
                <CustomTable
                    tableName = "Locations"
                    columns = {locationColumns}
                    data = {locationData}
                    showDelete = {true}
                    showEdit = {true}
                    onAdd = {handleAddLocation}
                    onDelete = {handleLocationDelete}
                    onEdit = {handleLocationEdit}
                />
                <ModalComponent
                    open = {locationModalOpen}
                    onClose = {() => setLocationModalOpen(false)}
                    title = "Create Customer Location"
                    actions = {locationActions}
                    closeOnOutsideClick = {false}
                    isSmall={true}
                >
                    <Forms
                        ref = {ref}
                        fields = {addLocationFields}
                        onSubmit = {data => onCreateLocation(data)}
                    />
                    <MapsComponent
                        initialLocation = {{lat: defaultLattitude, lng: defaultLongitude }}
                        onMapClick={handleMapClick}
                    />
                </ModalComponent>
                <ModalComponent
                    open = {editLocationModalOpen}
                    onClose = {() => setEditLocationModalOpen(false)}
                    title = "Update Customer Location"
                    actions = {editLocationActions}
                    closeOnOutsideClick = {false}
                    isSmall={true}
                >
                    <Forms
                        ref = {ref}
                        fields = {editLocationFields}
                        onSubmit = {data => onEditLocation(data)}
                    />
                    <MapsComponent
                        initialLocation = {selectedLocation}
                        onMapClick={handleMapClick}
                    />
                </ModalComponent>
                <ModalComponent
                    open = {deleteLocationModalOpen}
                    onClose = {handleCloseLocationDeleteModal}
                    title = "Delete Location"
                    actions = {deleteLocationActions}
                    closeOnOutsideClick = {false}
                >
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                        <HighlightOffIcon
                            style={{ margin: "auto", fontSize: "58px", color: "red" }}
                        />
                        <Typography
                            style={{ fontSize: "24px", textAlign: "center", margin: "15px" }}
                        >
                            Are you sure?
                        </Typography>
                        <Typography
                            style={{ fontSize: "14px", textAlign: "center", color: "grey" }}
                        >
                            Are you sure you want to delete this Location? <br />
                            This process cannot be undone.
                        </Typography>
                    </Box>
                </ModalComponent>

                <CustomTable
                    tableName = "Contacts"
                    columns = {contactColumns}
                    data = {contactData}
                    showDelete = {true}
                    showEdit = {true}
                    onAdd = {handleAddContact}
                    onDelete = {handleDeleteContact}
                    onEdit = {handleEditContact}
                />
                <ModalComponent
                    open = {contactModalOpen}
                    onClose = {() => setContactModalOpen(false)}
                    title = "Create Customer Contact"
                    actions = {contactActions}
                    closeOnOutsideClick = {false}
                    isSmall={true}
                >
                    <Forms
                        ref = {ref}
                        fields = {addContactFields}
                        onSubmit = {data => onCreateContact(data)}
                    />
                </ModalComponent>
                <ModalComponent
                    open = {editContactModalOpen}
                    onClose = {() => setEditContactModalOpen(false)}
                    title = "Edit Customer Contact"
                    actions = {editContactActions}
                    closeOnOutsideClick= {false}
                    isSmall={true}
                >
                    <Forms
                        ref = {ref}
                        fields = {editContactFields}
                        onSubmit = {data => onEditContact(data)}
                    />
                    {!currentContactData.hasLogin && (
                        <Button onClick={handleAddLogin} color="primary" variant="contained">
                            Add Login
                        </Button>
                    )}
                    {currentContactData.hasLogin && (
                        <Button onClick={resendInvite} color="primary" variant="contained" style={{ marginRight: "15px"}}>
                            Resend Invite
                        </Button>
                    )}
                    {currentContactData.hasLogin && (
                        <Button
                            onClick={handleRemoveLogin}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "15px" }}
                        >
                            Remove Login
                        </Button>
                    )}
                    {currentContactData.hasLogin && (
                        <Button
                            onClick={handleResetPassword}
                            color="primary"
                            variant="contained"
                        >
                            Reset password
                        </Button>
                    )}
                    <ModalComponent
                        open = {resetPasswordModalOpen}
                        onClose = {handleCloseResetPasswordModal}
                        title = "Reset Password"
                        actions = {resetPasswordActions}
                        closeOnOutsideClick = {false}
                        isSmall={true}
                    >
                        <Forms
                            ref = {passwordRef}
                            fields = {resetPasswordField}
                            onSubmit = {data => submitResetPassword(data)}
                        />
                    </ModalComponent>
                </ModalComponent>
                <ModalComponent
                    open = {deleteContactModalOpen}
                    onClose = {handleCloseDeleteContactModal}
                    title = "Delete Contact"
                    actions = {deleteContactActions}
                    closeOnOutsideClick = {false}
                >
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                        <HighlightOffIcon
                            style={{ margin: "auto", fontSize: "58px", color: "red" }}
                        />
                        <Typography
                            style={{ fontSize: "24px", textAlign: "center", margin: "15px" }}
                        >
                            Are you sure?
                        </Typography>
                        <Typography
                            style={{ fontSize: "14px", textAlign: "center", color: "grey" }}
                        >
                            Are you sure you want to delete this Contact? <br />
                            This process cannot be undone.
                        </Typography>
                    </Box>
                </ModalComponent>
            </Container>
        </>
    )
}
