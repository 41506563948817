export const filterSelect = (select,i) => {
    if (select.some((el) => el === "Yard" || el === "StandBy" || el === "Roustabout")){
        for (var count in i.selectOptions){
          var item = i.selectOptions[count]
          if(item.value !== select.at(0)) {
            item.disabled = true;
          }
        }
      return;
      } 
      if (select.some((el) => el === "PAndA")){
        for (var count in i.selectOptions){
          var item = i.selectOptions[count]
          if(item.value !== "Workover" && item.value !== "PAndA") {
            item.disabled = true;
          }
        }
      return;
      } if(select.length === 0){
        for (var count in i.selectOptions){
          var item = i.selectOptions[count]
          item.disabled = false;
        }
        return;
      }
      for (var count in i.selectOptions){
        var item = i.selectOptions[count]
        if(item.value === "Yard" || item.value === "StandBy" || item.value === "Roustabout") {
          item.disabled = true;
          continue;
        }
        if (select.some((el) => el !== "Workover") && item.value === "PAndA"){
          item.disabled = true;
          continue;
        }
        item.disabled = false;
      }
};