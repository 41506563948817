import CustomTable from "../../shared/table/Table";
import { Checkbox } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import ModalComponent from "../../shared/Modal";
import { Forms } from "../../shared/forms/forms";
import Box from "@mui/material/Box";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Switch, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { downloadDoc } from "../../utils/download-file";
import {
  createNewInvoice,
  deleteTicket,
  getAllTickets,
  getInvoiceById,
  downloadTickets
} from "../../services/tickets-service";
import { getCustomers } from "../../services/customer-service";
import { getAssignedEquipment, getEquipmentByCategory } from "../../services/equipment-service";
import { formatDate, toCurrencyNumber } from "../../utils/date-functions";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  makeStyles,
} from "@material-ui/core";
import { ViewInvoiceDetails } from "./invoiceList";
import { useAuth } from "../../hooks/useAuth";
import { CreateTicketModal } from "./createTicketModal";
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { LoadingComponent } from "../../services/loading-service";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHeaderCell: {
    backgroundColor: "#f2f2f2",
    color: "#333",
    fontWeight: "bold",
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f2f2f2",
    },
  },
});
export const FieldTicketsComponent = ({ userRole }) => {
  const [invoiceModalData, setInvoiceModalData] = useState([]);
  const [currentTicketIndex, setCurrentTicketIndex] = useState(undefined);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [ticketList, setTicketList] = useState([]);
  const [rowsSelection, setRowsSelection] = useState([]);
  const [additionalButtonDisabled, setAdditionalButtonDisabled] = useState(true);
  const [viewInvoiceModal, setViewInvoiceModal] = useState(false);
  const [viewInvoiceFields, setViewInvoiceFields] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [createModalOpened, setCreateModalOpened] = useState(false);
  const [lateFees, setLateFees] = useState([]);
  const [invoiceId, setInvoiceId] = useState("");
  const [deleteFeeModal, setDeleteFeeModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const [sorting, setSorting] = useState([{id: 'executionDate', desc: true}]);
  const [rowCount, setRowCount] = useState(10);
  const [globalFilter, setGlobalFilter] = useState('');
  const [customers, setCustomers] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [mainLoading, setMainLoading] = useState(false);
  const [assignedEquipment, setAssignedEquipment] = useState(null);

  const navigate = useNavigate();
  const classes = useStyles();

  const { role } = useAuth();

  const isCustomer = userRole && userRole === "customer";

  //get all tickets
  const getTickets = () => {
    getAllTickets(pagination.pageIndex,pagination.pageSize,sorting[0] ?? {id: 'executionDate', desc: true},globalFilter ?? '').then((tickets) => {
      setRowCount(tickets.totalCount);
      setTicketList(tickets.itemsList);
      setRowsSelection(tickets.itemsList.map((item => {
        return {
          ...item,
          isSelected: false
        }
      })));
    });
  }

  useEffect(() => {
    getTickets();
  }, [pagination.pageIndex, pagination.pageSize,sorting,globalFilter]);


  useEffect(() => {
    getEquipmentByCategory(1).then((resData) => setEquipments(resData));
    getCustomers().then((resData) => setCustomers(resData));
  },[]);

  useEffect(() => {
    getTickets();
  },[]);

  const ref = useRef();

  const selectedTicketColumns = [
    { accessorKey: "ticketId", header: "Ticket #" },
    { accessorKey: "serviceTypes", header: "Service Type" },
    { accessorKey: "executionDate", header: "Execution Date" },
    { accessorKey: "total", header: "Total" },
  ];

  const ticketColumnsViewInvoice = [
    { accessorKey: "ticketId", header: "Ticket #" },
    { accessorKey: "total", header: "Total" },
  ];

  const handleOpenInvoice = () => {
    setInvoiceModalOpen(true);
  };

  const handleTicketsDownload = () => {
    const selectedItems = rowsSelection.filter(
      (item) => item.isSelected === true
    ).map((item) => { return item.id});
    if (selectedItems.length === 0){
      return;
    }
    setMainLoading(true);
    downloadTickets(selectedItems).then(async (resData) => {
      downloadDoc(resData);
      setMainLoading(false);
    });
  }

  const handleCreateInvoice = () => {
    const idArray = invoiceModalData.map((obj) => obj.id);
    const reqBody = {
      customerId: invoiceModalData[0].customerId,
      ticketIds: idArray,
    };
    createNewInvoice(reqBody).then(() => {
      getTickets();
      setAdditionalButtonDisabled(true);
      setInvoiceModalOpen(false);
      setInvoiceModalData([]);
    });
  };

  const handleCheckboxChange = (row) => {
    const currentItem = rowsSelection.find((item) => item.id === row.original.id);
    currentItem.isSelected = !currentItem.isSelected;
    const selectedItems = rowsSelection.filter(
      (item) => item.isSelected === true
    );
    
    if (isCustomer){
      setAdditionalButtonDisabled(selectedItems.length === 0);
      return;
    }

    const resetData = () => {
      setCustomerName("");
      setAdditionalButtonDisabled(true);
      setInvoiceModalData([]);
    };

    if (selectedItems.length === 0){
      resetData();
      return;
    }

    let hasSameCustomerName = selectedItems.every(
        (item) => item.customerId === selectedItems[0].customerId
    );

    if (!hasSameCustomerName){
      resetData();
      return;
    }

    setCustomerName(selectedItems[0].customerName);
    setAdditionalButtonDisabled(false);
    setInvoiceModalData(selectedItems);
  };

  const handleCloseInvoceModal = () => {
    setInvoiceModalOpen(false);
  };
  const handleDeleteCloseModal = () => {
    setDeleteModalOpen(false);
  };

  const handleDelete = (data) => {
    setCurrentTicketIndex(data.id);
    setDeleteModalOpen(true);
  };

  const handleEdit = (data) => {
    const ticketId = data.id;
    navigate(`/dashboard/tickets/edit/${ticketId}`);
  };

  const handleDeleteConfirmAction = () => {
    deleteTicket(currentTicketIndex).then(() => {
      setDeleteModalOpen(false);
      getTickets();
    });
  };

  const showInvoice = (invoiceId) => {
    setInvoiceId(invoiceId);
    getInvoiceById(invoiceId).then((resData) => {
      setTicketData(resData.tickets);
      setLateFees(resData.lateFees);
      createViewInvoiceFields(resData);
    });
    setViewInvoiceModal(true);
  };

  const handleViewInvoiceModal = () => {
    setViewInvoiceModal(false);
  };


  const tableColumns = [
    {
      header: "",
      accessorKey: "checkbox",
      size: 60,
      enableColumnActions: false,
      Cell: ({ row }) => {
        const haveInvoice = row.original.invoice;
        const hasCustomerSignature = row.original.hasCustomerSignature;
        const hasCustomer = row.original.customerId;
        const hasEmployeeSignature = row.original.hasEmployeeSignature;
        return (
          <Checkbox
            checked={row.original.isSelected}
            onChange={() => handleCheckboxChange(row)}
            disabled={isCustomer ? !hasEmployeeSignature : haveInvoice || !hasCustomerSignature || !hasCustomer}
          />
        );
      },
      show: true,
      disableSortBy: true,
      disableFilters: true,
      enableSorting: false,
    },
    { accessorKey: "ticketId", header: "Ticket #", size: 80 },
    { 
      accessorKey: "customerName",
      header: "Customer Name",
      size: 120,
      enableSorting: true,
      Cell: ({row}) => {
        return (row.original.customerName === "None" ? "Yard" : row.original.customerName)
      }
    },
    { 
      accessorKey: "locationName",
      header: "Location",
      size: 120,
      enableSorting: true,
      Cell: ({row}) => {
        return (row.original.locationName === "None" ? "" : row.original.locationName)
      }
    },
    { 
      accessorKey: "executionDate",
      header: "Date",
      size: 80,
      Cell: ({row}) => {
        return formatDate(row.original.executionDate)
      }
    },
    {
      accessorKey: "hasCustomerSignature",
      header: "Signed",
      size: 80,
      Cell: (params) => {
        return (params.row.original.hasCustomerSignature ? "Yes" : "No")
      },
    },
    {
      accessorKey: "isInvoiced",
      header: "Invoiced",
      size: 80,
      Cell: (params) => {
        const hasInvoice = params.row.original.isInvoiced;
        return hasInvoice ? (
          <button
            onClick={() => showInvoice(params.row.original.invoice.id)}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "blue",
              background: "none",
              border: "none",
              padding: "0",
              font: "inherit",
            }}
          >
            Yes
          </button>
        ) : (
          "No"
        );
      },
    },
    { 
      accessorKey: "total", 
      size: 80, 
      header: "Total", 
      muiTableHeadCellProps: { align: 'right' },
      muiTableBodyCellProps: { align: 'right' },
      Cell: ({row}) => {
        return ("$ " + toCurrencyNumber(row.original.total))
      }
    },
  ];

  if( role && (role === 'toolpusher' || role === 'customer')){
   tableColumns.splice(6,1);
  }

  if( role && role === 'toolpusher'){
    tableColumns.splice(0,1);
   }

  const invoiceActions = [
    {
      onClick: handleCreateInvoice,
      color: "primary",
      label: "Confirm",
      variant: "contained",
    },
    {
      onClick: handleCloseInvoceModal,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];

  const deleteActions = [
    {
      onClick: handleDeleteConfirmAction,
      color: "primary",
      label: "Confirm",
      variant: "contained",
    },
    {
      onClick: handleDeleteCloseModal,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];

  const viewInvoiceActions = [
    {
      onClick: handleViewInvoiceModal,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];

  const handleAddAction = () => {
    getAssignedEquipment().then((resData) =>{
      setAssignedEquipment(resData);
      if (!resData){
        setCreateModalOpened(true);
      }
    });
  };

  useEffect(() => {
    if (assignedEquipment){
      setCreateModalOpened(true);
    }
  }, [assignedEquipment]);

  const createViewInvoiceFields = (invoiceData) => {
    const defaultTicket = [
      {
        label: "Customer Name",
        type: "text",
        name: "customer",
        required: true,
        readOnly: true,
        email: false,
        value: invoiceData.customer,
        size: 4,
      },
      {
        label: "Invoice Date",
        type: "datetime",
        name: "invoiceDate",
        disabled: true,
        required: true,
        email: false,
        value: invoiceData.invoiceDate,
        size: 4,
      },
      {
        label: "Invoice Due Date",
        type: "datetime",
        disabled: true,
        name: "invoice DueDate",
        required: true,
        email: false,
        value: invoiceData.dueDate,
        size: 4,
      },
      {
        label: "Invoice Id",
        type: "text",
        readOnly: true,
        name: "invoiceId",
        required: true,
        email: false,
        value: invoiceData.invoiceId,
        size: 3,
      },
      {
        label: "Total",
        type: "text",
        name: "total",
        readOnly: true,
        required: true,
        email: false,
        value:
          "$ " + toCurrencyNumber(invoiceData.total),
        size: 3,
      },
      {
        label: "Paid",
        type: "checkbox",
        disabled: true,
        name: "paid",
        required: true,
        email: false,
        value: invoiceData.paid,
        size: 3,
      },
      {
        label: "Sent to Customer",
        type: "checkbox",
        name: "sentToCustomer",
        disabled: true,
        required: true,
        email: false,
        value: invoiceData.sentToCustomer,
        size: 3,
      },
    ];

    setViewInvoiceFields(defaultTicket);
  };

  const createInvoiceFields = [
    {
      label: "Customer Name",
      type: "text",
      name: "customer",
      readOnly: true,
      required: true,
      email: false,
      value: customerName,
      size: 12,
    },
  ];

  const ticketActionRenderer = (row, index, cell) => {
    const canDelete = !row.original.hasEmployeeSignature && !row.original.isInvoiced;
    return (
      <>
      {userRole === "Admin" || !row.original.hasCustomerSignature ? <Tooltip title="Edit" arrow>
        <EditIcon fontSize="small" onClick={() => handleEdit(row.original)} style={{ cursor: 'pointer' }} />
      </Tooltip> : null}
      {userRole !== "Admin" && row.original.hasCustomerSignature ? <Tooltip title="View" arrow>
          <VisibilityIcon fontSize="small" onClick={() => handleEdit(row.original)} style={{ cursor: 'pointer' }} />
      </Tooltip> : null}
      {!isCustomer && canDelete ? <Tooltip title="Delete" arrow>
          <DeleteIcon fontSize="small" onClick={() => handleDelete(row.original)} style={{ marginLeft: '10px', cursor: 'pointer' }} />
      </Tooltip> : null}
      </>
    );
  };

  return (
    <>
     <LoadingComponent loading={mainLoading} />
      <CustomTable
        tableName="Tickets"
        columns={tableColumns}
        data={ticketList}
        showCreateButton={!isCustomer}
        buttonText={isCustomer ? "Download Tickets" : "Create Invoice"}
        onAdd={handleAddAction}
        onEdit={handleEdit}
        onDelete={handleDelete}
        showAdditionalButton={userRole === "admin" || userRole === "manager"}
        additionalBtn={isCustomer ? handleTicketsDownload : handleOpenInvoice}
        additionalBtnDisabled={additionalButtonDisabled}
        manualFiltering = {true}
        manualPagination = {true}
        manualSorting = {true}
        enableColumnFilters = {false}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        onGlobalFilterChange={setGlobalFilter}
        state={{ 
          globalFilter,
          sorting,
          pagination
         }}
        rowCount={rowCount}
        rowActionRendererer={ticketActionRenderer}
      />
      <CreateTicketModal
        isOpen={createModalOpened}
        setIsOpen={setCreateModalOpened}
        customers={customers}
        equipment={equipments}
        assignedEquipment = {assignedEquipment}
      />
      <ModalComponent
        open={invoiceModalOpen}
        onClose={handleCloseInvoceModal}
        title={"Create Invoice"}
        actions={invoiceActions}
        closeOnOutsideClick={false}
      >
        <Forms fields={createInvoiceFields} />
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {selectedTicketColumns.map((column) => (
                  <TableCell
                    key={column.header}
                    className={classes.tableHeaderCell}
                  >
                    {column.header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceModalData.map((row, index) => (
                <TableRow key={row.id} className={classes.tableRow}>
                  <TableCell>{row.ticketId}</TableCell>
                  <TableCell>{row.serviceTypes}</TableCell>
                  <TableCell>{row.executionDate}</TableCell>
                  <TableCell>
                    {row.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ModalComponent>
      <ModalComponent
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title="Delete Ticket"
        actions={deleteActions}
        closeOnOutsideClick={false}
      >
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <HighlightOffIcon
            style={{ margin: "auto", fontSize: "58px", color: "red" }}
          />
          <Typography
            style={{ fontSize: "24px", textAlign: "center", margin: "15px" }}
          >
            Are you sure?
          </Typography>
          <Typography
            style={{ fontSize: "14px", textAlign: "center", color: "grey" }}
          >
            Are you sure you want to delete this Ticket? <br />
            The process cannot be undone.
          </Typography>
        </Box>
      </ModalComponent>
      <ModalComponent
        open={viewInvoiceModal}
        onClose={handleViewInvoiceModal}
        title={"View Invoice"}
        actions={viewInvoiceActions}
        closeOnOutsideClick={false}
      >
        <ViewInvoiceDetails
          ref={ref}
          fields={viewInvoiceFields}
          selectedTicketColumns={ticketColumnsViewInvoice}
          ticketList={ticketData}
          hideFields={false}
          lateFees={lateFees}
          setLateFees={setLateFees}
          invoiceId={invoiceId}
          deleteFeeModal={deleteFeeModal}
          setDeleteFeeModalOpen={setDeleteFeeModalOpen}
        />
      </ModalComponent>
    </>
  );
};
