import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Forms } from "../shared/forms/forms";
import { useNavigate } from "react-router-dom";
import { setUserPassword } from "../services/auth-service";

export const ContactLoginPage = () => {
    const location = useLocation();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const navigate = useNavigate();
    const paramsEmail = new URLSearchParams(location.search);
    const username = paramsEmail.get('username');

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'password') {
          setPassword(value);
          setPasswordMismatch(false);
        } else if (name === 'confirmPassword') {
          setConfirmPassword(value);
          setPasswordMismatch(false);
        }
      };

    const addParams = {
        buttonName: 'Set password',
    }

    const fields = [
        {
            label:'Set new password',
            type:'password',
            name:'password',
            required: true,
            onChange: handleInputChange,
            size:12
        },
        {
            label:'Confirm password',
            type:'password',
            name:'confirmPassword',
            required: true,
            onChange: handleInputChange,
            size:12,
        },
    ]

    useEffect(() => {
        if (password === confirmPassword) {
          setPasswordMismatch(false);
        }
      }, [password, confirmPassword]);

    const handleSetPassword = async (event) => {
        const password = event.password;
        const confirmPassword = event.confirmPassword
        if (password === confirmPassword) {
            const paramsCode = new URLSearchParams(location.search);
            const code = decodeURIComponent(paramsCode.get('code'));
            const data = {username, code, password};
            const success = await setUserPassword(data);
            if (success) {
                navigate('/login', { replace: true });
            }
            return response;
        }
        else {
            setPasswordMismatch(true);
        }
    };

    const renderForm = (
        <Box sx={{ mt: 5 }}
            style={{
                border: "1px solid lightblue",
                borderRadius: "20px",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                padding: "30px 30px 0px 30px",
                height: "330px",
                }}>
            <Typography
                style={{
                    textAlign: "center",
                    marginBottom: "20px"
                    }}>
                Set your password for <br/> {username}
            </Typography>
            <Forms
                fields={fields}
                addParams={addParams}
                onSubmit={handleSetPassword}
            />
            {passwordMismatch && (
                <Typography style={{   color: 'red',
                    height: "40px",
                    fontSize: "small",
                    textAlign: 'center',
                    margin: "-60px", }}>
                Passwords do not match
                </Typography>
                )}

        </Box>
    );
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 5, bgcolor: "primary.main" }}>
                    {/* <LockResetIcon /> */}
                </Avatar>
                <Typography component="h1" variant="h5">
                    Set password
                </Typography>
                {renderForm}
            </Box>
        </Container>
    );
};
