import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"
import React, { useRef } from 'react';
import { useState } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

const containerStyle = {
    width: "560px",
    height: "400px",
    maxWidth: '100%'
};


export const MapsComponent = ({ onMapClick, initialLocation, showSearch = true, enableMapClick = true}) => {
    const [markerPosition, setMarkerMosition] = useState(initialLocation);
    const [address, setAddress] = useState('');
    const [currentZoom, setCurrentZoom] = useState(15);

    const mapRef = useRef(null);

    const handleChange = address => {
        setAddress(address)
    };

    const handleSelect = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                const newCenter = {
                    lat: latLng.lat,
                    lng: latLng.lng
                }
                setMarkerMosition(newCenter);
                onMapClick(newCenter);
                setCurrentZoom(15);
            })
            .catch(error => console.error('Error', error));
    };

    const handleClickedMap = (e) => {
        if (!enableMapClick) {
            return;
        }
        const newCenter = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        }
        setMarkerMosition(newCenter);
        onMapClick(newCenter);
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAekCJS_A7stwQ6WIzWXwT7krg9H3aCtUg"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(markerPosition);
        setCurrentZoom(15);
        setMap(map);
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
    }, [])

    return isLoaded ? (
        <>
            {showSearch && <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <input
                            {...getInputProps({
                                placeholder: 'Search city, state or address',
                                className: 'location-search-input',
                            })}
                        />
                        <div className={suggestions?.length ? 'autocomplete-dropdown-container' : 'autocomplete-dropdown-container hidden'}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>}
      <GoogleMap
        ref={mapRef}
        onClick = {handleClickedMap}
        mapContainerStyle={containerStyle}
        center={markerPosition}
        zoom={currentZoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker position={markerPosition}/>
        <></>
      </GoogleMap>
        </>
    ) : <></>
}
