import toastr from 'toastr'; // Import the toastr library
import 'toastr/build/toastr.min.css'

export const showToastr = (message, type) => {
    toastr.options = {
        closeButton: true, // Show close button
        newestOnTop: false, // Show newest toastr at the bottom
        progressBar: false, // Show progress bar
        positionClass: 'toast-bottom-center', // Set the position of toastr notifications
        preventDuplicates: true, // Prevent duplicate toastr notifications
        showDuration: '1300', // Duration of animation to show toastr
        hideDuration: '1000', // Duration of animation to hide toastr
        timeOut: '4000', // Time until toastr automatically hides
        extendedTimeOut: '1000', // Time to close toastr after a user hovers over it
        showMethod: 'fadeIn', // Animation method for show toastr
        hideMethod: 'fadeOut', // Animation method for hide toastr
      };
    switch(type){
      case 'success':
        toastr.success(message);
        break;
      case 'error':
        toastr.error(message);
        break;
      case 'warning':
        toastr.warning(message);
        break;
    }
};
