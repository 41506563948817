import CustomTable from "../../shared/table/Table";
import ModalComponent from "../../shared/Modal";
import { Forms } from "../../shared/forms/forms";
import React, { useState, useEffect, useRef } from "react";
import {
  getAllInvoices,
  deleteInvoice,
  markInvoiceAsPaid,
  sendInvoiceToCustomer,
  downloadInvoice,
  removeInvoiceFee,
  getInvoiceById,
} from "../../services/tickets-service";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Typography } from "@mui/material";
import { formatDate } from "../../utils/date-functions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  makeStyles,
} from "@material-ui/core";
import {LoadingComponent} from "../../services/loading-service";
import { toCurrencyNumber } from "../../utils/date-functions";
import { downloadDoc } from "../../utils/download-file";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  actionCell: {
    textAlign: "end",
    verticalAlign: "middle",
  },
  tableContainer: {
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  },
  tableHeaderCell: {
    backgroundColor: "#f2f2f2",
    color: "#333",
    fontWeight: "bold",
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fbfafa",
    },
  },
});

const columns = [
  { accessorKey: "invoiceId", header: "Invoice #", size: 80 },
  { accessorKey: "customer", header: "Customer", size: 120 },
  { 
    accessorKey: "invoiceDate",
    header: "Invoice Date",
    size: 80,
    Cell: ({row}) => {
      return (formatDate(row.original.invoiceDate))
    }
  },
  { 
    accessorKey: "dueDate",
    header: "Due Date",
    size: 80,
    Cell: ({row}) => {
      return (formatDate(row.original.dueDate))
    }
  },
  {
    accessorKey: "sentToCustomer",
    header: "Sent",
    size: 80,
    Cell: (params) => {
      return (
        params.row.original.sentToCustomer ? "Yes" : "No"
      );
    },
  },
  {
    accessorKey: "paid",
    size: 80,
    header: "Paid",
    Cell: (params) => {
      return (
        params.row.original.paid ? "Yes" : "No"
      );
    },
  },
  { 
    accessorKey: "total",
    size: 80, header: "Total",
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: { align: 'right' },
    Cell: ({row}) => {
      return ("$" + toCurrencyNumber(row.original.total))
    },
    enableSorting: false,
    disableSortBy: true,
    disableFilters: true,
  }
];

export const InvoiceListComponent = () => {
  const [invoiceList, setInvoiceList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentInvoiceIndex, setCurrentInvoiceIndex] = useState(null);
  const [fields, setFields] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [lateFees, setLateFees] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [mainLoading, setMainLoading] = useState(false);
  const [deleteFeeModal, setDeleteFeeModalOpen] = useState(false);
  const [feeId, setFeeId] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const [sorting, setSorting] = useState([{id: 'invoiceDate', desc: true}]);
  const [rowCount, setRowCount] = useState(10);
  const [globalFilter, setGlobalFilter] = useState('');

  const ref = useRef();

  const getInvoices = () => {
    getAllInvoices(pagination.pageIndex,pagination.pageSize,sorting[0] ?? {id: 'invoiceDate', desc: true},globalFilter ?? '').then((invoiceList) => {
      setRowCount(invoiceList.totalCount)
      setInvoiceList(invoiceList.itemsList);
    });
  };

  useEffect(() => { 
    getInvoices();
  }, [pagination.pageIndex, pagination.pageSize,sorting,globalFilter]);

  useEffect(() => {
    getInvoices();
  }, []);

  const handleView = (data) => {
    viewInvoice(data.data);
    setTicketList(data.data.tickets);
    setLateFees(data.data.lateFees);
    setInvoiceId(data.data.id);
    setModalOpen(true);
  };

  const handleDelete = (data) => {
    setCurrentInvoiceIndex(data.data.id);
    setDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleDeleteConfirmAction = () => {
    deleteInvoice(currentInvoiceIndex).then(() => {
      getInvoices();
      setDeleteModalOpen(false);
    });
  };

  const handleDeleteCloseModal = () => {
    setDeleteModalOpen(false);
  };

  const handleMarkAsPaid = () => {
    markInvoiceAsPaid(invoiceId).then(() => {
      getInvoices();
      setModalOpen(false);
    });
  };

  const handleSendToCustomer = () => {
    setMainLoading(true);
    sendInvoiceToCustomer(invoiceId).then(() => {
      getInvoices();
      setMainLoading(false);
      setModalOpen(false);
    });
  };

  const handleDownloadInovice = () => {
    setMainLoading(true);
    downloadInvoice(invoiceId).then(async (response) => {
      downloadDoc(response);
      setMainLoading(false);
      setModalOpen(false);
    });
  };

  const actions = [
    {
      onClick: handleMarkAsPaid,
      color: "primary",
      label: "Mark as Paid",
      variant: "contained",
    },
    {
      onClick: handleSendToCustomer,
      color: "primary",
      label: "Send to Customer",
      variant: "contained",
    },
    {
      onClick: handleDownloadInovice,
      color: "primary",
      label: "Download",
      variant: "contained",
    },
    {
      onClick: handleCloseModal,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];

  const viewInvoice = (data) => {
    const defaultTicket = [
      {
        label: "Customer Name",
        type: "text",
        name: "customer",
        required: true,
        readOnly: true,
        email: false,
        value: data.customer,
        size: 4,
      },
      {
        label: "Invoice Date",
        type: "datetime",
        name: "invoiceDate",
        disabled: true,
        required: true,
        email: false,
        value: data.invoiceDate,
        size: 4,
      },
      {
        label: "Invoice Due Date",
        type: "datetime",
        disabled: true,
        name: "invoice DueDate",
        required: true,
        email: false,
        value: data.dueDate,
        size: 4,
      },
      {
        label: "Invoice Id",
        type: "text",
        readOnly: true,
        name: "invoiceId",
        required: true,
        email: false,
        value: data.invoiceId,
        size: 3,
      },
      {
        label: "Total",
        type: "text",
        name: "total",
        readOnly: true,
        required: true,
        email: false,
        value: "$ " + toCurrencyNumber(data.total),
        size: 3,
      },
      {
        label: "Paid",
        type: "checkbox",
        disabled: true,
        name: "paid",
        required: true,
        email: false,
        value: data.paid,
        size: 3,
      },
      {
        label: "Sent to Customer",
        type: "checkbox",
        name: "sentToCustomer",
        disabled: true,
        required: true,
        email: false,
        value: data.sentToCustomer,
        size: 3,
      },
    ];

    setFields(defaultTicket);
  };

  const deleteActions = [
    {
      onClick: handleDeleteConfirmAction,
      color: "primary",
      label: "Confirm",
      variant: "contained",
    },
    {
      onClick: handleDeleteCloseModal,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];

  const selectedTicketColumns = [
    { accessorKey: "ticketId", header: "Ticket ID" },
    { accessorKey: "total", header: "Total" },
    { accessorKey: "ticket", header: "Preview Ticket" },
  ];


  return (
    <>
      <LoadingComponent loading={mainLoading} />
      <CustomTable
        tableName="Invoices"
        columns={columns}
        data={invoiceList}
        showDelete={true}
        showView={true}
        onDelete={handleDelete}
        onView={handleView}
        showEdit={false}
        showCreateButton={false}
        manualFiltering = {true}
        manualPagination = {true}
        manualSorting = {true}
        enableColumnFilters = {false}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        onGlobalFilterChange={setGlobalFilter}
        state={{ 
          globalFilter,
          sorting,
          pagination
         }}
        rowCount={rowCount}
      />
      <ModalComponent
        open={modalOpen}
        onClose={handleCloseModal}
        title={"View Invoice"}
        actions={actions}
        closeOnOutsideClick={false}
      >
        <ViewInvoiceDetails
          ref={ref}
          fields={fields}
          selectedTicketColumns={selectedTicketColumns}
          ticketList={ticketList}
          lateFees={lateFees}
          deleteFeeModal={deleteFeeModal}
          setDeleteFeeModalOpen={setDeleteFeeModalOpen}
          setFeeId={setFeeId}
          setLateFees={setLateFees}
          feeId={feeId}
          invoiceId={invoiceId}
        />
      </ModalComponent>
      <ModalComponent
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title="Delete Invoice"
        actions={deleteActions}
        closeOnOutsideClick={false}
      >
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <HighlightOffIcon
            style={{ margin: "auto", fontSize: "58px", color: "red" }}
          />
          <Typography
            style={{ fontSize: "24px", textAlign: "center", margin: "15px" }}
          >
            Are you sure?
          </Typography>
          <Typography
            style={{ fontSize: "14px", textAlign: "center", color: "grey" }}
          >
            Are you sure you want to delete this Invoice? <br />
            The process cannot be undone.
          </Typography>
        </Box>
      </ModalComponent>
    </>
  );
};

export const ViewInvoiceDetails = ({
  ref,
  fields,
  selectedTicketColumns,
  setDeleteFeeModal,
  ticketList,
  lateFees,
  hideFields = true,
  setLateFees,
  invoiceId,
}) => {
  const [feeId, setFeeId] = useState(null);
  const [deleteFeeModal, setDeleteFeeModalOpen] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();

  
  const lateFeesColumns = [
    { accessorKey: "appliedOn", header: "Applied On" },
    { accessorKey: "percentageFeeApplied", header: "Fee" },
    { accessorKey: "sentToCustomer", header: "Sent to Customer" },
    { accessorKey: "removeFee", header: "Actions" },
  ];

  const handleDeleteFeeConfirmAction = () => {
    removeInvoiceFee(feeId).then((resData) => {
      getInvoiceById(invoiceId).then((resData) => {
        setLateFees(resData.lateFees);
      });
    });
    setDeleteFeeModalOpen(false);
  };

  const handleDeleteFeeCloseModal = () => {
    setDeleteFeeModalOpen(false);
  };

  const deleteFeeActions = [
    {
      onClick: handleDeleteFeeConfirmAction,
      color: "primary",
      label: "Confirm",
      variant: "contained",
    },
    {
      onClick: handleDeleteFeeCloseModal,
      color: "default",
      label: "Cancel",
      variant: "outlined",
    },
  ];

  return (
    <>
      <Forms ref={ref} fields={fields} />
      {lateFees.length > 0 && (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {lateFeesColumns.map((column) => (
                  <TableCell
                    key={column.header}
                    align={
                      column.accessorKey === "removeFee" ? "right" : "left"
                    }
                    className={classes.tableHeaderCell}
                  >
                    {column.header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {lateFees.map((row, index) => (
                <TableRow key={row.id} className={classes.tableRow}>
                  <TableCell>{formatDate(row.appliedOn)}</TableCell>
                  <TableCell>{row.percentageFeeApplied}%</TableCell>
                  <TableCell>{row.sentToCustomer ? "Yes" : "No"}</TableCell>
                  <TableCell className={classes.actionCell}>
                    <DeleteIcon
                      onClick={() => {
                        setFeeId(row.id);
                        setDeleteFeeModalOpen(true);
                      }}
                      aria-label="edit"
                      style={{ cursor: "pointer" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <ModalComponent
        open={deleteFeeModal}
        onClose={() => setDeleteFeeModal(false)}
        title="Delete Fee"
        actions={deleteFeeActions}
        closeOnOutsideClick={false}
      >
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <HighlightOffIcon
            style={{ margin: "auto", fontSize: "58px", color: "red" }}
          />
          <Typography
            style={{ fontSize: "24px", textAlign: "center", margin: "15px" }}
          >
            Are you sure?
          </Typography>
          <Typography
            style={{ fontSize: "14px", textAlign: "center", color: "grey" }}
          >
            Are you sure you want to delete this Fee? <br />
            The process cannot be undone.
          </Typography>
        </Box>
      </ModalComponent>
      <TableContainer
        component={Paper}
        className={classes.tableContainer}
        style={{ marginTop: "20px" }}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {selectedTicketColumns.map((column) => (
                <TableCell
                  key={column.header}
                  align={column.accessorKey === "ticket" ? "right" : "left"}
                  className={classes.tableHeaderCell}
                >
                  {column.header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ticketList.map((row, index) => (
              <TableRow key={row.id} className={classes.tableRow}>
                <TableCell>{row.ticketId}</TableCell>
                <TableCell>
                  {"$ " + toCurrencyNumber(row.total)}
                </TableCell>
                {hideFields && (
                  <TableCell className={classes.actionCell}>
                    <VisibilityIcon
                      onClick={() =>
                        navigate(`/dashboard/tickets/edit/${row.id}`)
                      }
                      aria-label="edit"
                      style={{ cursor: "pointer" }}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
