import { Forms } from "../shared/forms/forms";
import { useState, useEffect, useRef } from "react";
import { getAllSettings, updateSettings} from "../services/settings-service";
import { Button } from "@material-ui/core"


export const SettingsInfo = () => {

    const [fields, setFields] = useState([]);

    const ref = useRef();

    const getSettings = () => {
        getAllSettings().then((resData) => {
            setValues(resData);
        });
    };

    const setValues = (resData) => {
        const updatedFields = [
            {
                label: "Tax Rate",
                type: "text",
                name: "taxRate",
                required: true,
                email: false,
                value: resData.taxRate,
                numbersOnly : true,
                size: 12
            },
            {
                label: "Overtime Percentage Increase",
                type: "text",
                name: "overtimePercentageIncrease",
                required: true,
                email: false,
                numbersOnly : true,
                value: resData.overtimePercentageIncrease,
                size: 12
            },
            {
                label: "Mileage Cost",
                type: "text",
                name: "mileageCost",
                required: true,
                email: false,
                numbersOnly : true,
                value: resData.mileageCost,
                size: 12
            }
        ];
        setFields(updatedFields);
    }

    useEffect(() => {
        getSettings();
    }, []);

    const onFormSubmit = (data) => {
        const requestBody = {
            taxRate: data.taxRate,
            overtimePercentageIncrease: data.overtimePercentageIncrease,
            mileageCost: data.mileageCost
        }

        updateSettings(requestBody).then(() => getSettings());
    }

    return(

    <div style={{margin: '20px'}}>
        <Forms
            ref={ref}
            fields={fields}
            onSubmit={(data) => onFormSubmit(data)}
        >
        </Forms>
        {fields?.length > 0 && <Button variant="contained" onClick={() => {ref.current.submit()}}>
            Update Settings
        </Button>}
    </div>


    )
}
