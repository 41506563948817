import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Card,
  CardContent,
  Box, CardHeader,
} from "@mui/material";
import { Cake, Work, CelebrationOutlined, DateRangeOutlined } from "@mui/icons-material";
import { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import { getUpcomingAnniversariesEmployees, getUpcomingBirthdayEmployees } from "../../services/employee-service";
import { differenceInCalendarDays, setYear } from "date-fns";

const EmployeesInfo = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [upcomingBirthdayList, setUpcomingBirthdayList] = useState([]);
  const [upcomingAnniversaryList, setUpcomingAnniversaryList] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    getUpcomingBirthdays();
    getUpcomingAnniversaries();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getUpcomingBirthdays = () => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    getUpcomingBirthdayEmployees().then((employees) => {
      employees.forEach(employee => {
        employee.doB = new Date(employee.doB).toLocaleDateString('en-US', options);
        employee.daysUntilBirthday = differenceInCalendarDays(setYear(new Date(employee.doB), new Date().getFullYear()), new Date())
      })
      employees.sort((a, b) => {
        return a.daysUntilBirthday - b.daysUntilBirthday;
      })
      setUpcomingBirthdayList(employees);
    })
  }

  const getUpcomingAnniversaries = () => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    getUpcomingAnniversariesEmployees().then((employees) => {
      employees.forEach(employee => {
        employee.hireDate = new Date(employee.hireDate).toLocaleDateString('en-US', options);
        employee.daysUntilAnniversary = differenceInCalendarDays(setYear(new Date(employee.hireDate), new Date().getFullYear()), new Date())
      })
      employees.sort((a, b) => {
        return a.daysUntilAnniversary - b.daysUntilAnniversary;
      })
      setUpcomingAnniversaryList(employees);
    })
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap"
        }}
      >
        <Card
          variant="outlined"
          sx={{
            height: 'auto',
            maxHeight: '380px',
            flex:
              "1 0 49%",
            marginBottom: 1,
            marginRight: 1,
            padding: 1,
            cursor: "pointer",
            "&:hover": {
              boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <CardHeader style={{ height: '80px', backgroundColor: '#3a34d2', color: 'white', fontWeight: 'bold' }} avatar={<Avatar sx={{ bgcolor: '#ff5622' }} aria-label="recipe">
            <CelebrationOutlined /></Avatar>} title="Employees with Birthdays in the Next 30 Days:" />
          <CardContent style={{
            height: 'auto',
            maxHeight: '380px',
            overflowY: "auto",
          }}>
            {upcomingBirthdayList.length > 0 ? (
              <List>
                {upcomingBirthdayList.map((employee) => (
                  <ListItem key={employee.id}>
                    <Cake sx={{ color: "#ff7043", marginRight: 2 }} />
                    <ListItemText
                      sx={{ fontSize: "30px" }}
                      primary={<Typography variant="body2" color="textPrimary" sx={{ fontSize: "18px" }}>
                        {employee.firstName} {employee.lastName}
                      </Typography>}
                      secondary={<Typography variant="body2" color="textSecondary" sx={{ fontSize: "14px" }}>
                        Birthday: {employee.doB}
                      </Typography>}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Box display="flex" justifyContent="center">
                <Typography variant="body2" color="textSecondary">
                  No employees found with birthdays in the next 30 days.
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>

        <Card
          variant="outlined"
          sx={{
            height: 'auto',
            maxHeight: '380px',
            flex:
            "1 0 49%",
            marginBottom: 1,
            marginRight: 1,
            padding: 1,
            cursor: "pointer",
            "&:hover": {
              boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <CardHeader style={{ height: '80px', backgroundColor: '#3a34d2', color: 'white', fontWeight: 'bold' }} avatar={<Avatar sx={{ bgcolor: '#ff5622' }} aria-label="recipe">
            <DateRangeOutlined /></Avatar>} title="Employees with Work Anniversaries in the Next 30 Days:" />
          <CardContent style={{
            height: 'auto',
            maxHeight: '380px',
            overflowY: "auto",
          }}>
            {upcomingAnniversaryList.length > 0 ? (
              <List>
                {upcomingAnniversaryList.map((employee) => (
                  <ListItem key={employee.id}>
                    <Work sx={{ color: "#3f51b5", marginRight: 2 }} />
                    <ListItemText
                      primary={<Typography variant="body2" color="textPrimary" sx={{ fontSize: "18px" }}>
                        {employee.firstName} {employee.lastName}
                      </Typography>}
                      secondary={<Typography variant="body2" color="textSecondary" sx={{ fontSize: "14px" }}>
                        Work Anniversary: {employee.hireDate}
                      </Typography>}

                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Box display="flex" justifyContent="center">
                <Typography variant="body2" color="textSecondary">
                  No employees found with work anniversaries in the next 30
                  days.
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default EmployeesInfo;